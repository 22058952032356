import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import Alert from "@/common/components/Alert";
import GoBack from "@/common/components/GoBack";
import {
    getRefunds,
    REFUND_HISTORY_STATUS_CHOICES,
} from "../../features/refundsSlice";
import { toggleShow } from "../../features/modalSlice";
import { formatDate } from "../../utils";
import TicketModal from "../../components/ticket_modal/TicketModal";
import "./RefundHistory.scss";
import Pagination from "@/common/components/Pagination/Pagination";

const RefundHistory = () => {
    const dispatch = useDispatch();
    const [statusFilter, setStatusFilter] = useState("");

    const navigate = useNavigate();

    const { refunds, status, error, pagination } = useSelector(
        (state) => state.refunds
    );

    const modalData = useSelector((state) => state.modal.data);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getRefunds({ status: "" }));
    }, []);

    const openModal = (id) => {
        dispatch(toggleShow({ id: id }));
    };

    const handleStatusChange = (e) => {
        setStatusFilter(e.target.value);
        dispatch(getRefunds({ status: e.target.value }));
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="refund-history">
                <GoBack
                    clickHandler={() => navigate("/products/refunds/tickets/")}
                />
                <div className="page-title-container">
                    <h3>Refund History</h3>
                </div>
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {isLoading ? (
                            <div className="loading-spinner-container">
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <>
                                <div className="filter-container">
                                    <label htmlFor="statusFilter">
                                        Filter by Status:
                                    </label>
                                    <select
                                        id="statusFilter"
                                        value={statusFilter}
                                        onChange={handleStatusChange}
                                    >
                                        <option value="">All</option>
                                        {REFUND_HISTORY_STATUS_CHOICES.map(
                                            (choice) => (
                                                <option
                                                    key={choice.label}
                                                    value={choice.label}
                                                >
                                                    {choice.label}
                                                </option>
                                            )
                                        )}
                                    </select>
                                </div>
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Ticket</th>
                                            <th>Amount</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {refunds.length > 0 ? (
                                            refunds.map(
                                                ({
                                                    id,
                                                    ticket,
                                                    amount,
                                                    status,
                                                    timestamp,
                                                }) => {
                                                    return (
                                                        <tr key={id}>
                                                            <td className="text-el">
                                                                {id}
                                                            </td>
                                                            <td className="text-el">
                                                                {formatDate(
                                                                    timestamp
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="btn-wrapper">
                                                                    <div className="btn outline">
                                                                        {
                                                                            status.display_text
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="ticket-id">
                                                                <div
                                                                    onClick={() =>
                                                                        openModal(
                                                                            ticket
                                                                        )
                                                                    }
                                                                >
                                                                    {ticket}
                                                                </div>
                                                            </td>
                                                            <td className="text-el">
                                                                $
                                                                {parseFloat(
                                                                    amount
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                <div className="btn-wrapper">
                                                                    {status.value ===
                                                                        1 && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn primary"
                                                                            onClick={() =>
                                                                                openModal(
                                                                                    ticket
                                                                                )
                                                                            }
                                                                        >
                                                                            Details
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan="6"
                                                    className="text-center"
                                                >
                                                    You do not have any Refund
                                                    yet.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>

                    {refunds?.length > 0 &&
                        pagination?.current_page &&
                        pagination?.total_pages &&
                        pagination?.page_size && (
                            <Pagination
                                currentPage={pagination?.current_page ?? 1}
                                total_pages={pagination?.total_pages ?? 1}
                                itemsPerPage={pagination?.page_size ?? 10}
                                // change current page
                                onPageChange={(page) => {
                                    dispatch(
                                        getRefunds({
                                            status: statusFilter,
                                            page: page,
                                            page_size:
                                                pagination?.page_size ?? 10,
                                        })
                                    );
                                }}
                                // change page size
                                onItemsPerPageChange={(value) => {
                                    dispatch(
                                        getRefunds({
                                            status: statusFilter,
                                            page_size: value,
                                            page: 1, // reset page number
                                        })
                                    );
                                }}
                            />
                        )}
                </div>
                {modalData && <TicketModal id={modalData.id} />}
            </div>
        );
    }
};

export default RefundHistory;
