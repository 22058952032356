import React from "react";
import { useNavigate, Link } from "react-router-dom";

import GoBack from "@/common/components/GoBack";
import "./Notifications.scss";

const NotificationBase = ({ isArchived, children }) => {
    const navigate = useNavigate();
    return (
        <div id="notifications-page">
            <div className="page-container">
                {isArchived && (
                    <GoBack clickHandler={() => navigate("/notifications/")} />
                )}
                <div className="page-top">
                    <div className="archived-btn-wrapper">
                        <Link
                            className="notification-title-container"
                            to={
                                isArchived
                                    ? "/notifications"
                                    : "/notifications/archived"
                            }
                        >
                            <h1>
                                {isArchived
                                    ? "New Notifications >"
                                    : "Archived Notifications >"}
                            </h1>
                        </Link>
                    </div>
                </div>
                <div className="page-middle">
                    <div className="page-content">
                        <div className="notifications-container">
                            {children}
                        </div>
                    </div>
                </div>
                <div className="page-end"></div>
            </div>
        </div>
    );
};

export default NotificationBase;
