import React from "react";
import Stack from "@mui/material/Stack";
import Link from "@mui/material/Link";

const GoBack = ({ clickHandler }) => {
    return (
        <Stack direction="row" marginBottom={2}>
            <Link component="button" variant="h6" onClick={clickHandler}>
                Go Back
            </Link>
        </Stack>
    );
};

export default GoBack;
