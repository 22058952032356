import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance, cachedAxiosInstance } from "@/common/utils/axios";

export const selectTwoFactorMethod = createAsyncThunk(
    "users/twofactor/method",
    async (type, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append("verification_type", type);
            const response = await axiosInstance.post(
                "/users/api/twofactor/method/",
                formData
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        "Failed to save 2-Factor Verification Method."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getTwoFactorCode = createAsyncThunk(
    "users/twofactor/get",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/users/api/twofactor/code/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        "Failed to get 2-Factor Verification Data."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const postTwoFactorCode = createAsyncThunk(
    "users/twofactor/post",
    async (code, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append("code", code);
            const response = await axiosInstance.post(
                "/users/api/twofactor/code/",
                formData
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        "Failed to submit 2-Factor Verification Code."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const twoFactorSlice = createSlice({
    name: "two_factor",
    initialState: {
        status: "idle",
        error: null,
        details: null,
        submission: {
            status: "idle",
            error: null,
        },
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(selectTwoFactorMethod.pending, (state) => {
                state.status = "pending";
            })
            .addCase(selectTwoFactorMethod.fulfilled, (state) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(selectTwoFactorMethod.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getTwoFactorCode.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getTwoFactorCode.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.details = action.payload;
                state.error = null;
            })
            .addCase(getTwoFactorCode.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(postTwoFactorCode.pending, (state) => {
                state.submission.status = "pending";
            })
            .addCase(postTwoFactorCode.fulfilled, (state) => {
                state.submission.status = "succeeded";
                state.submission.error = null;
            })
            .addCase(postTwoFactorCode.rejected, (state, action) => {
                state.submission.status = "failed";
                state.submission.error = action.payload;
            });
    },
});

export default twoFactorSlice.reducer;
