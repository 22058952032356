import { replaceSpecialChars } from "../../common/utils/helpers";

/**
 * Process and prepare survey data for saving
 * @param {Object} survey - The survey object containing the current state and data
 * @returns {string} JSON string of processed survey data with special characters replaced
 */
export const processSavingSurveyData = (survey) => {
    let currentData = survey.data;
    currentData = Object.keys(currentData)
        .filter((objKey) => objKey !== "pageNo" && objKey !== "Success")
        .reduce((newObj, key) => {
            newObj[key] = currentData[key];
            return newObj;
        }, {});

    const currentDataArr = Object.entries(currentData);
    currentData = Object.fromEntries(currentDataArr);
    currentData.pageNo = survey.currentPageNo;
    const pages = survey.pages.filter((obj) => obj.isVisible === true);
    let paymentAmountPageNo = pages.findIndex(
        (obj) => obj.name === "PaymentAmountPage"
    );
    currentData.paymentAmountPageNo = paymentAmountPageNo;
    return replaceSpecialChars(JSON.stringify(currentData));
};

/**
 * Processes the completion of a survey, organizing and structuring the survey data
 * @param {Object} survey - The completed survey object
 * @returns {string} Stringified and processed survey data
 */
export const processSurveyCompletion = (survey) => {
    const plainDataArr = survey.getPlainData();
    const surveyDataArr = Object.entries(survey.data).filter(
        ([key]) => key !== "pageNo"
    );

    // Extract necessary data from the survey object
    const allSurveyData = JSON.parse(survey.allData).data;
    const dataFromOverlay = allSurveyData.overlay_data.data_for_table;
    const dataFromSurvey = allSurveyData.overlay_data.data_for_survey;
    const surveyData = allSurveyData.survey_json;

    const resultData = {
        pages: [],
        additionalQuestions: [],
    };
    let currentGroupPage = 0;

    // Process each survey data entry
    for (const [key, value] of surveyDataArr) {
        const group = findGroup(dataFromOverlay.groups, key);

        if (group) {
            processGroup(
                group,
                key,
                value,
                plainDataArr,
                dataFromSurvey,
                resultData.pages,
                currentGroupPage
            );
        } else {
            processAdditionalQuestion(
                key,
                value,
                surveyData,
                resultData.additionalQuestions
            );
        }
    }

    // Calculate paymentAmountPageNo
    const pages = survey.pages.filter((obj) => obj.isVisible === true);
    const paymentAmountPageNo = pages.findIndex(
        (obj) => obj.name === "PaymentAmountPage"
    );

    // Prepare final data object
    const currentData = {
        ...survey.data,
        pageNo: resultData.pages[0].length - 1,
    };

    const data = {
        results_data: {
            ...resultData,
            paymentAmountPageNo,
        },
        progress_data: currentData,
    };

    surveyData.pageNo = survey.currentPageNo;

    // Return stringified and processed data
    return replaceSpecialChars(JSON.stringify(data));
};

/**
 * Finds a group in the survey structure based on a given key
 * This function helps in identifying which group a particular survey item belongs to
 * @param {Array} groups - Array of groups
 * @param {string} key - Key to search for
 * @returns {Object|undefined} Found group or undefined
 */
function findGroup(groups, key) {
    const uuid = key.split("-Comment")[0];
    return groups.find(
        (group) =>
            group.uuid === uuid ||
            group.elements.some((element) => element.uuid === uuid)
    );
}

/**
 * Processes a group of survey elements, creating structured data for each element
 * This function is responsible for handling different types of survey elements within a group
 * @param {Object} group - Group to process
 * @param {string} key - Key of the group
 * @param {*} value - Value of the group
 * @param {Array} plainDataArr - Plain data array
 * @param {Object} dataFromSurvey - Data from survey
 * @param {Array} pages - Pages array to update
 * @param {number} currentGroupPage - Current group page
 */
function processGroup(
    group,
    key,
    value,
    plainDataArr,
    dataFromSurvey,
    pages,
    currentGroupPage
) {
    // Find plain data for the group
    const plainData =
        plainDataArr.find((obj) => obj.name === group.name) ||
        plainDataArr.filter((obj) => obj.name.includes(group.name));

    // Determine the first element to process
    const firstElement =
        group.elements[0].type !== "question"
            ? group.elements[0]
            : group.elements[1];

    // Process the element and create an item
    const props = processElementType(
        firstElement,
        group,
        plainData,
        value,
        dataFromSurvey
    );
    const item = createItem(firstElement, plainData, group, props);

    // Update pages with the new item
    updatePages(pages, item, group.page, currentGroupPage);
}

/**
 * Processes a specific element based on its type (e.g., checkbox, radio button, text input)
 * This function delegates the processing to type-specific functions
 * @param {Object} firstElement - First element to process
 * @param {Object} group - Group object
 * @param {Object} plainData - Plain data object
 * @param {*} value - Value of the element
 * @param {Object} dataFromSurvey - Data from survey
 * @returns {Array} Processed properties
 */
function processElementType(
    firstElement,
    group,
    plainData,
    value,
    dataFromSurvey
) {
    const props = [];

    switch (firstElement.type) {
        case "checkbox":
        case "boolean":
        case "radiogroup":
            processBooleanCheckboxRadio(
                firstElement,
                group,
                plainData,
                props,
                dataFromSurvey
            );
            break;
        case "text":
        case "comment":
        case "signaturepad":
        case "imagepicker":
            processTextComment(firstElement, value, props);
            break;
        case "multipletext":
            processMultipleText(group, plainData, props);
            break;
        case "matrixdynamic":
            processMatrixDynamic(group, value, props);
            break;
        case "multipletextgroup":
            processMultipleTextGroup(group, plainData, props);
            break;
    }

    return props;
}

/**
 * Processes boolean, checkbox, or radio group elements
 * This function handles the specific logic for these types of input elements
 * @param {Object} firstElement - First element to process
 * @param {Object} group - Group object
 * @param {Object} plainData - Plain data object
 * @param {Array} props - Properties array to update
 * @param {Object} dataFromSurvey - Data from survey
 */
function processBooleanCheckboxRadio(
    firstElement,
    group,
    plainData,
    props,
    dataFromSurvey
) {
    if (firstElement.type === "boolean") {
        const answer = plainData.value;
        const option = answer ? "0" : "1";
        const boolEl = group.elements.filter((obj) => obj.type === "boolean")[
            answer ? 0 : 1
        ];
        props.push({
            fieldName: group.elements.find((obj) => obj.type === "boolean")
                .fieldName,
            option: option,
            ...boolEl.properties,
        });
    } else {
        const checkboxesOrRadioBtns = group.elements.filter(
            (obj) => obj.type === "checkbox" || obj.type === "radiogroup"
        );
        const pages = dataFromSurvey.pages;
        checkboxesOrRadioBtns.forEach((el, index) => {
            if (firstElement.type === "radiogroup") {
                processRadioGroup(firstElement, plainData, props, pages, group);
            } else {
                processCheckbox(plainData, props, el, index, pages, group);
            }
        });
    }
}

/**
 * Processes a radio group element
 * This function handles the specific logic for radio button groups
 * @param {Object} firstElement - First element to process
 * @param {Object} plainData - Plain data object
 * @param {Array} props - Properties array to update
 * @param {Array} pages - Pages array
 * @param {Object} group - Group object
 */
function processRadioGroup(firstElement, plainData, props, pages, group) {
    if (Number.isInteger(plainData.value)) {
        props.push({
            fieldName: firstElement.fieldName,
            option: plainData.value,
            ...firstElement.properties,
        });
    } else {
        const answerIdx = getAnswerIndex(-1, pages, group, plainData);
        props.push({
            fieldName: firstElement.fieldName,
            option: answerIdx.toString(),
            ...firstElement.properties,
        });
    }
}

/**
 * Processes a checkbox element
 * This function handles the specific logic for checkbox inputs
 * @param {Object} plainData - Plain data object
 * @param {Array} props - Properties array to update
 * @param {Object} el - Element object
 * @param {number} index - Index of the element
 * @param {Array} pages - Pages array
 * @param {Object} group - Group object
 */
function processCheckbox(plainData, props, el, index, pages, group) {
    for (let i = 0; i < plainData.value.length; i++) {
        if (Number.isInteger(plainData.value[i])) {
            if (plainData.value[i] === index) {
                props.push({
                    fieldName: el.fieldName,
                    ...el.properties,
                });
            }
        } else {
            const answerIdx = getAnswerIndex(
                -1,
                pages,
                group,
                plainData.value[i],
                true
            );
            if (answerIdx !== -1 && answerIdx === index) {
                props.push({
                    fieldName: el.fieldName,
                    ...el.properties,
                });
            }
        }
    }
}

/**
 * Processes text or comment elements
 * This function handles the specific logic for text and comment inputs
 * @param {Object} firstElement - First element to process
 * @param {*} value - Value of the element
 * @param {Array} props - Properties array to update
 */
function processTextComment(firstElement, value, props) {
    props.push({
        fieldName: firstElement.fieldName,
        ...firstElement.properties,
        value: value,
    });
}

/**
 * Processes multiple text elements
 * This function handles groups of text inputs
 * @param {Object} group - Group object
 * @param {Object} plainData - Plain data object
 * @param {Array} props - Properties array to update
 */
function processMultipleText(group, plainData, props) {
    const textBoxes = group.elements.filter(
        (obj) => obj.type === "multipletext"
    );
    const values = plainData.value;
    const groupTextBoxes = processMultipleTextGroup(group, values);

    textBoxes.forEach((textBox) => {
        const value = values[textBox.name];
        props.push({
            value: value,
            fieldName: textBox.fieldName,
            ...textBox.properties,
            isRemarks: group.elements[1].isRemarks,
        });
    });

    processGroupTextBoxes(groupTextBoxes, values, group, props);
}

/**
 * Processes a multiple text group
 * This function identifies and processes groups of related text inputs
 * @param {Object} group - Group object
 * @param {Object} values - Values object
 * @returns {Array} Processed group text boxes
 */
function processMultipleTextGroup(group, values) {
    if (
        group.elements.findIndex((obj) => obj.type === "multipletextgroup") !==
        -1
    ) {
        const multipleTxtGrp = group.elements.find(
            (obj) => obj.type === "multipletextgroup"
        );
        return multipleTxtGrp.elements.map((textBox) =>
            group.elements.find((obj) => obj.uuid === textBox.uuid)
        );
    }
    return [];
}

/**
 * Processes text boxes within a group
 * This function handles the specific logic for text inputs within a group
 * @param {Array} groupTextBoxes - Group text boxes array
 * @param {Object} values - Values object
 * @param {Object} group - Group object
 * @param {Array} props - Properties array to update
 */
function processGroupTextBoxes(groupTextBoxes, values, group, props) {
    if (groupTextBoxes.length) {
        let multipleTxtGrpValues = values[groupTextBoxes[0].name];
        multipleTxtGrpValues = formatDateValue(multipleTxtGrpValues);
        let prevLength = 0;
        groupTextBoxes.forEach((textBox) => {
            const newTextBoxEl = group.elements.find(
                (obj) => obj.uuid === textBox.uuid
            );
            const value = multipleTxtGrpValues.slice(
                prevLength,
                prevLength + newTextBoxEl.maxLength
            );
            prevLength += newTextBoxEl.maxLength;
            props.push({
                value: value,
                fieldName: newTextBoxEl.fieldName,
                ...newTextBoxEl.properties,
                isRemarks: group.elements[1].isRemarks,
            });
        });
    }
}

/**
 * Formats a date value to a specific string format
 * This function ensures consistent date formatting across the survey
 * @param {string} value - Date value to format
 * @returns {string} Formatted date value
 */
function formatDateValue(value) {
    const date = new Date(value);
    if (
        Object.prototype.toString.call(date) === "[object Date]" &&
        !isNaN(date)
    ) {
        const dateArr = value.split("-");
        value =
            dateArr.length > 2
                ? `${dateArr[1]}-${dateArr[2]}-${dateArr[0]}`
                : `${dateArr[1]}-${dateArr[0]}`;
        return value.replace(/[-]/g, "");
    }
    return value;
}

/**
 * Processes a matrix dynamic element
 * This function handles complex matrix-style inputs in the survey
 * @param {Object} group - Group object
 * @param {*} value - Value of the element
 * @param {Array} props - Properties array to update
 */
function processMatrixDynamic(group, value, props) {
    const cells = [];
    const tableGroup = group.elements.find(
        (obj) => obj.type === "matrixdynamic"
    );
    const tableColumns = tableGroup.columns;
    value.forEach((row, index) => {
        for (const [key, val] of Object.entries(row)) {
            const col = tableColumns.find((obj) => obj.name === key);
            const newValue = processMatrixCell(col, val, group, index);
            cells.push(newValue);
        }
    });
    props.push({
        properties: tableGroup.properties,
        cells: cells,
    });
}

/**
 * Processes an individual cell within a matrix
 * This function handles the specific logic for each cell in a matrix input
 * @param {Object} col - Column object
 * @param {*} val - Value of the cell
 * @param {Object} group - Group object
 * @param {number} index - Index of the cell
 * @returns {Object} Processed cell value
 */
function processMatrixCell(col, val, group, index) {
    const columnType = col.type;
    let newValue = {
        type: columnType,
        page: group.page,
        value: val,
    };

    if (["text", "comment", "signaturepad"].includes(columnType)) {
        const colElement = col.elements[index];
        newValue.propertiesList = {
            0: {
                value: val,
                fieldName: colElement.fieldName,
                ...colElement.properties,
            },
        };
    } else if (["checkbox", "boolean", "radiogroup"].includes(columnType)) {
        newValue.propertiesList = processMatrixCellChoice(
            columnType,
            col,
            val,
            index
        );
    } else if (columnType === "multipletextgroup") {
        newValue.propertiesList = processMatrixCellMultipleTextGroup(
            col,
            val,
            index,
            group
        );
    }

    return newValue;
}

/**
 * Processes choice elements (checkbox, boolean, radio) within a matrix cell
 * This function handles the specific logic for choice-based inputs in a matrix
 * @param {string} columnType - Type of the column
 * @param {Object} col - Column object
 * @param {*} val - Value of the cell
 * @param {number} index - Index of the cell
 * @returns {Array} Processed choice properties
 */
function processMatrixCellChoice(columnType, col, val, index) {
    if (columnType === "boolean") {
        const boolInputs = col.elements.filter((obj) => obj.type === "boolean");
        const answer = Object.values(val)[0];
        const colElement = answer ? boolInputs[0] : boolInputs[1];
        const option = answer ? 0 : 1;
        return [
            {
                value: val,
                fieldName: colElement.fieldName,
                option: option,
                ...colElement.properties,
            },
        ];
    } else {
        const checkboxesOrRadioBtns = col.elements.filter(
            (obj) => obj.type === "checkbox" || obj.type === "radiogroup"
        );
        if (columnType === "radiogroup") {
            const answerIndex = Number.isInteger(val) ? val : val[0];
            const colElement = checkboxesOrRadioBtns[answerIndex];
            return [
                {
                    value: val,
                    fieldName: colElement.fieldName,
                    option: val,
                    ...colElement.properties,
                },
            ];
        } else {
            const colElement = checkboxesOrRadioBtns[index];
            return [
                {
                    value: val,
                    fieldName: colElement.fieldName,
                    ...colElement.properties,
                },
            ];
        }
    }
}

/**
 * Processes multiple text groups within a matrix cell
 * This function handles groups of text inputs within a matrix cell
 * @param {Object} col - Column object
 * @param {*} val - Value of the cell
 * @param {number} index - Index of the cell
 * @param {Object} group - Group object
 * @returns {Array} Processed multiple text group properties
 */
function processMatrixCellMultipleTextGroup(col, val, index, group) {
    const propertiesList = [];
    const colElement = col.elements.filter(
        (obj) => obj.type === "multipletextgroup"
    )[index];
    const textBoxesArr = colElement.elements;
    let values = formatDateValue(val);
    let prevLength = 0;
    textBoxesArr.forEach((textBox) => {
        const textBoxEl = group.elements.find(
            (obj) => obj.uuid === textBox.uuid
        );
        const value = values.slice(
            prevLength,
            prevLength + textBoxEl.maxLength
        );
        prevLength += textBoxEl.maxLength;
        propertiesList.push({
            value: value,
            fieldName: textBoxEl.fieldName,
            ...textBoxEl.properties,
        });
    });
    return propertiesList;
}

/**
 * Creates a structured item object from processed survey data
 * This function standardizes the format of survey items for further processing
 * @param {Object} firstElement - First element of the group
 * @param {Object} plainData - Plain data object
 * @param {Object} group - Group object
 * @param {Array} props - Processed properties
 * @returns {Object} Created item
 */
function createItem(firstElement, plainData, group, props) {
    return {
        type:
            firstElement.type === "question" || firstElement.type === "label"
                ? group.elements[2].type
                : firstElement.type,
        name: plainData.name,
        title: plainData.title,
        value: plainData.value,
        uuid: group.uuid,
        page: group.page,
        propertiesList: props,
    };
}

/**
 * Updates the pages array with a new item
 * This function organizes survey items into their respective pages
 * @param {Array} pages - Pages array to update
 * @param {Object} item - Item to add to pages
 * @param {number} groupPage - Group page number
 * @param {number} currentGroupPage - Current group page number
 */
function updatePages(pages, item, groupPage, currentGroupPage) {
    if (currentGroupPage !== groupPage) {
        pages.push([item]);
        currentGroupPage = groupPage;
    } else {
        const page = pages.find((p) =>
            p.some((obj) => obj.page === currentGroupPage)
        );
        if (page) {
            page.push(item);
        }
    }
}

/**
 * Processes additional questions that don't fit into the standard survey structure
 * This function handles any extra or custom questions in the survey
 * @param {string} key - Key of the question
 * @param {*} value - Value of the question
 * @param {Object} surveyData - Survey data object
 * @param {Array} additionalQuestions - Additional questions array to update
 */
function processAdditionalQuestion(
    key,
    value,
    surveyData,
    additionalQuestions
) {
    for (const surveyPage of surveyData.pages) {
        if (surveyPage.elements) {
            const element = surveyPage.elements.find((el) => el.name === key);
            if (element) {
                additionalQuestions.push({ ...element, value });
                break;
            }
        }
    }
}

/**
 * Gets the index of an answer within a set of choices
 * This function helps in identifying the selected option in choice-based questions
 * @param {number} answerIdx - Initial answer index
 * @param {Array} pages - Pages array
 * @param {Object} group - Group object
 * @param {Object} plainData - Plain data object
 * @returns {number} Found answer index
 */
function getAnswerIndex(answerIdx, pages, group, plainData) {
    for (const page of pages) {
        if (page.elements) {
            const result = findAnswerIndexInElements(
                page.elements,
                group.uuid,
                plainData.value
            );
            if (result !== -1) return result;
        }
    }
    return answerIdx;
}

/**
 * Recursively searches for the answer index within nested elements
 * This function helps in handling complex, nested survey structures
 * @param {Array} elements - Elements array
 * @param {string} groupUuid - Group UUID
 * @param {*} plainDataValue - Plain data value
 * @param {number} depth - Recursion depth
 * @returns {number}
 */
function findAnswerIndexInElements(
    elements,
    groupUuid,
    plainDataValue,
    depth = 0
) {
    for (const element of elements) {
        if (element.valueName === groupUuid) {
            return getChoiceIndex(element.choices, plainDataValue);
        }

        if (element.elements && depth < 5) {
            const result = findAnswerIndexInElements(
                element.elements,
                groupUuid,
                plainDataValue,
                depth + 1
            );
            if (result !== -1) return result;
        }
    }
    return -1;
}

/**
 * Gets the index of a choice within an array of choices
 * This function helps in identifying the position of a selected option
 * @param {Array} choices - Choices array
 * @param {*} value - Value to search for
 * @returns {number} Index of the choice
 */
function getChoiceIndex(choices, value) {
    let index = choices.indexOf(value);
    if (index === -1) {
        index = choices.findIndex((choice) => choice.value === value);
    }
    return index;
}
