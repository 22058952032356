import React, { useState, useEffect, useCallback, useContext } from "react";
import ZoomContext from "../context/zoom-context";

const useChat = () => {
    const zmClient = useContext(ZoomContext);
    const chatClient = zmClient.getChatClient();
    const [messages, setMessages] = useState(chatClient.getHistory());
    const [chatInput, setChatInput] = useState("");
    const [showChat, setShowChat] = useState(false);
    const [chatNotificationCount, setChatNotificationCount] = useState(0);

    const sendMessage = useCallback(
        (text) => {
            chatClient.sendToAll(text);
        },
        [chatClient]
    );

    const onChatMessage = useCallback(
        (payload) => {
            setMessages((prevMessages) => [...prevMessages, payload]);
            if (!showChat) {
                setChatNotificationCount((prevCount) => prevCount + 1);
            }
        },
        [showChat]
    );

    useEffect(() => {
        zmClient.on("chat-on-message", onChatMessage);
        return () => {
            zmClient.off("chat-on-message", onChatMessage);
        };
    }, [zmClient, onChatMessage]);

    useEffect(() => {
        setChatNotificationCount(0);
    }, [showChat]);

    return {
        messages,
        sendMessage,
        showChat,
        setShowChat,
        chatInput,
        setChatInput,
        chatNotificationCount,
        setChatNotificationCount,
    };
};

export default useChat;
