import { axiosInstance, cachedAxiosInstance } from "@/common/utils/axios";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

const handleError = (
    error,
    customErrorMsg = "An unexpected error occurred. Please try again later."
) => {
    if (error.response && error.response.status === 500) {
        return customErrorMsg;
    } else if (error.response) {
        const errorData = error?.response?.data || {};
        return (
            errorData?.detail ||
            errorData?.message ||
            errorData?.error ||
            customErrorMsg
        );
    } else if (error.request) {
        return error?.message || customErrorMsg;
    } else {
        return error?.message || customErrorMsg;
    }
};

export const getConditionExam = createAsyncThunk(
    "condition_exam/condition_history",
    async (patient_id, { rejectWithValue, extra: cachedAxiosInstance }) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/condition_exam/api/details/${patient_id}/`
            );
 
            return await response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to get condition exam details.")
            );
        }
    }
);

export const getConditionExamDashboard = createAsyncThunk(
    "condition_exam/exam-dashboard",
    async (_, { rejectWithValue, extra: cachedAxiosInstance }) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/condition_exam/condition_exam_dashboard/`
            );
            return await response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to get condition exam dashboard.")
            );
        }
    }
);

export const saveConditionExam = createAsyncThunk(
    "condition_exam/save-condition-exam",
    async (_, { rejectWithValue, extra: axiosInstance }) => {
        try {
            const response = await axiosInstance.post(
                `/condition_exam/condition_exam_dashboard/`
            );
            return await response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to save condition exam.")
            );
        }
    }
);

export const getCurrentCondition = createAsyncThunk(
    "condition_exam/get-current-condition",
    async (_, { rejectWithValue, extra: cachedAxiosInstance }) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/condition_exam/current_conditions_api/`
            );
            return await response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to save current condition.")
            );
        }
    }
);

export const getConditionRatings = createAsyncThunk(
    "condition_exam/get-condition-ratings",
    async (patient_id, { rejectWithValue, extra: cachedAxiosInstance }) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/condition_exam/api/ratings/${patient_id}/`
            );
            return await response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to save current condition.")
            );
        }
    }
);

export const updateCurrentCondition = createAsyncThunk(
    "condition_exam/update-current-condition",
    async (data, { rejectWithValue, extra: cachedAxiosInstance }) => {
        try {
            const response = await cachedAxiosInstance.post(
                `/condition_exam/ratings/`,
                data
            );
            return await response.data;
        } catch (error) {
            return rejectWithValue(
                handleError(error, "Failed to update current condition.")
            );
        }
    }
);

export const conditionExamSlice = createSlice({
    name: "condition_history",
    initialState: {
        condition_data: null,
        status: "idle",
        error: null,
        exam_dashboard: null,
        current_condition: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getConditionExam.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getConditionExam.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.condition_data = action.payload ? action.payload : null;
                state.error = null;
            })
            .addCase(getConditionExam.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getConditionExamDashboard.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getConditionExamDashboard.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.exam_dashboard = action.payload;
                state.error = null;
            })
            .addCase(getConditionExamDashboard.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(saveConditionExam.pending, (state) => {
                state.status = "pendingSaveConditionExam";
            })
            .addCase(saveConditionExam.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.exam_dashboard = action.payload;
                state.error = null;
            })
            .addCase(saveConditionExam.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getCurrentCondition.pending, (state) => {
                state.status = "pendingGetCurrentCondition";
            })
            .addCase(getCurrentCondition.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.current_condition = action.payload;
                state.error = null;
            })
            .addCase(getCurrentCondition.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(updateCurrentCondition.pending, (state) => {
                state.status = "pendingUpdateCurrentCondition";
            })
            .addCase(updateCurrentCondition.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.current_condition = action.payload;
                state.error = null;
            })
            .addCase(updateCurrentCondition.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getConditionRatings.pending, (state) => {
                state.status = "loading";
            })
            .addCase(getConditionRatings.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.condition_data = action.payload;
                state.error = null;
            })
            .addCase(getConditionRatings.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export default conditionExamSlice.reducer;
