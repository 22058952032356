import { replaceSpecialChars } from "@/common/utils/helpers";
import { getCookie } from "@/common/utils/cookies";

export function structureConditionRatingsMatrix() {
    `Helps structure the initial survey model for condition ratings. Sets title and other properties needed throughout the Survey like the preview page
    
    Dynamic Matrix with Two Columns

    First Column is a simple text input 

    Second Column is a dropdown option with percentages from 0-100%

    Client can add rows, 1 row minimum confirmation already included when they choose to delete a row

    `;

    let surveyData = {
        name: "condition_ratings",
        elements: [
            {
                type: "matrixdynamic",
                name: "Condition Ratings",
                title: "VA Condition Ratings",
                description:
                    "You can get this information from your eBenefits or VA account under the current ratings section of your account",
                columns: [
                    {
                        name: "condition",
                        title: "Condition Name",
                        cellType: "text",
                    },
                    {
                        name: "rating",
                        title: "Decision Rating",
                        cellType: "dropdown",
                        choices: [
                            {
                                value: "0",
                                text: "0%",
                            },
                            {
                                value: "10",
                                text: "10%",
                            },
                            {
                                value: "20",
                                text: "20%",
                            },
                            {
                                value: "30",
                                text: "30%",
                            },
                            {
                                value: "40",
                                text: "40%",
                            },
                            {
                                value: "50",
                                text: "50%",
                            },
                            {
                                value: "60",
                                text: "60%",
                            },
                            {
                                value: "70",
                                text: "70%",
                            },
                            {
                                value: "80",
                                text: "80%",
                            },
                            {
                                value: "90",
                                text: "90%",
                            },
                            {
                                value: "100",
                                text: "100%",
                            },
                        ],
                        defaultValue: "0",
                    },
                ],
                choices: [1, 2, 3, 4, 5],
                placeholder: "0%",
                rowCount: 1,
                minRowCount: 1,
                confirmDelete: true,
                confirmDeleteText:
                    "Are you sure you want to remove this condition?",
                addRowText: "Add Condition",
                removeRowText: "Remove",
            },
        ],
    };

    surveyData.previewText = `Review Conditions`;
    surveyData.showPreviewBeforeComplete = "showAnsweredQuestions";
    surveyData.showPageNumbers = true;
    surveyData.widthMode = "responsive";
    surveyData.showQuestionNumbers = false;
    return surveyData;
}

export function completeSurvey(sender, selected_conditions = null) {
    `Function ran to complete survey and save results. Hits Django backend to save results
    
    Args: 
        Sender(survey): the survey itself so we can grab the data being saved and send to the back end. Null if conditions have worsened not new conditions
        Selected Condition: Updates PAQ if this condition has gotten worse. Null if new conditions not if conditions have gotten worse
    Returns: 
        Promise: resolves with the response data on success, rejects with an error on failure

    `;
    //Remove special characters from the saved data
    var dataJSON = null;
    //Sender being null means request comes from list of conditions that have worsened when clients reviews their conditions before the next appointment
    if (sender) {
        dataJSON = replaceSpecialChars(JSON.stringify(sender.data));
    } else {
        dataJSON = replaceSpecialChars(JSON.stringify(selected_conditions));
    }

    const formData = new FormData();
    formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
    formData.append("resultData", dataJSON);
    //Sends to Django backend endpoint
    fetch(`/condition_exam/condition_ratings/`, {
        method: "POST",
        body: formData,
        // fetch automatically sets 'Content-Type' to 'multipart/form-data' when using FormData
    })
        .then((response) => {
            if (!response.ok) {
                // If response is not OK, throw an error
                throw new Error("Network response was not ok");
            }
            return response.json(); // Parse JSON data from response
        })
        .then((data) => {
            //Handle errors
            if (data.error) {
                document.getElementById("result-container").innerHTML = `
              <h3>❌ Unable to add conditions properly.</h3>
              <h6 class="m-5">${data.error}</h6>
              <input class="sd-btn sd-btn--action sd-navigation__complete-btn" value="Go Back" title="Go Back" onclick="location.reload()" style="max-width: 170px" />
          `;
                //Redirect if successful to paqs each time
            } else {
                window.location.replace(`/paqs/`);
            }
        })
        .catch((error) => {
            alert("DATA NOT SAVED");
        });
}
