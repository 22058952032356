import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance, cachedAxiosInstance } from "@/common/utils/axios";

const initialMethodState = {
    name: null,
    status: "idle",
    error: null,
};

const checklistUrl = "/medical-history/api/checklist/";

export const getChecklist = createAsyncThunk(
    "control-hub/medical-history",
    async ({ page_size = null, page = null }, thunkAPI) => {
        try {
            const queryParams = new URLSearchParams();
            if (page_size) queryParams.append("page_size", page_size);
            if (page) queryParams.append("page", page);

            const response = await cachedAxiosInstance.get(
                `/medical-history/api/checklist/?${queryParams.toString()}`
            );

            return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        "Failed to get Medical History checklist."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const createChecklistItem = createAsyncThunk(
    "control-hub/medical-history/create",
    async (name, thunkAPI) => {
        try {
            const state = thunkAPI.getState();
            // check if checklist item with the given name already exist
            if (
                state.checklist.checklist.findIndex(
                    (item) => item.name === name
                ) !== -1
            ) {
                return thunkAPI.rejectWithValue(
                    "Document Name must be unique."
                );
            }
            const response = await axiosInstance.post(checklistUrl, {
                name: name,
            });
            const data = await response.data;
            return data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        errorData?.file?.error ||
                        "Failed to create Medical History checklist item."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const deleteChecklistItem = createAsyncThunk(
    "control-hub/medical-history/delete",
    async (id, thunkAPI) => {
        try {
            await cachedAxiosInstance.delete(
                `/medical-history/api/checklist/detail/${id}/`
            );
            return id;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail ||
                        "Failed to delete Medical History checklist item."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const medHistAdminSlice = createSlice({
    name: "checklist",
    initialState: {
        checklist: [],
        status: "idle",
        error: null,
        method: initialMethodState,
        pagination: null,
    },
    reducers: {
        // reset method state obj to it's initial value
        resetMethod: (state, _) => {
            state.method = initialMethodState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getChecklist.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getChecklist.fulfilled, (state, action) => {
                const { checklists, ...pagination } = action.payload;
                state.status = "succeeded";
                state.checklist = checklists;
                state.pagination = pagination;
                state.error = null;
            })
            .addCase(getChecklist.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(createChecklistItem.pending, (state) => {
                state.method.name = "create";
                state.method.status = "pending";
            })
            .addCase(createChecklistItem.fulfilled, (state, action) => {
                state.method.status = "succeeded";
                state.checklist.push(action.payload);
                state.method.error = null;
            })
            .addCase(createChecklistItem.rejected, (state, action) => {
                state.method.status = "failed";
                state.method.error = action.payload;
            })
            .addCase(deleteChecklistItem.pending, (state) => {
                state.method.name = "delete";
                state.method.status = "pending";
            })
            .addCase(deleteChecklistItem.fulfilled, (state, action) => {
                state.method.status = "succeeded";
                state.checklist = state.checklist.filter(
                    (obj) => obj.id !== action.payload
                );
                state.method.error = null;
            })
            .addCase(deleteChecklistItem.rejected, (state, action) => {
                state.method.status = "failed";
                state.method.error = action.payload;
            });
    },
});

export const { resetMethod } = medHistAdminSlice.actions;
export default medHistAdminSlice.reducer;
