// TODO: delete this file when testing is done.
import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

import Upload from "@/common/images/icons/upload.svg";
import Alert from "@/common/components/Alert";
import {
    resetMethod,
    uploadMedicalHistoryFileTest,
} from "../features/medicalHistoryTestSlice";
import { toggleShow } from "../features/modalSlice";
import Modal from "./Modal";
import CloseModalBtn from "./CloseModalBtn";
import "./upload_file_modal.scss";
import "./close_modal_btn.scss";

const validationSchema = Yup.object({
    file: Yup.mixed()
        .required("File is required.")
        .test(
            "fileExtension",
            "Invalid file type. Only PDF files are allowed.",
            (value) => {
                if (value) {
                    const supportedFormats = ["pdf"];
                    return supportedFormats.includes(
                        value.name.split(".").pop()
                    );
                }
                return true;
            }
        )
        .test(
            "fileSize",
            "File size exceeds the maximum limit of 10MB.",
            (value) => {
                if (value) {
                    return value.size <= 10485760; // 10MB in bytes
                }
                return true;
            }
        ),
});

const UploadTestFileModal = () => {
    const { status, error } = useSelector(
        (state) => state.medicalHistoryTest.method
    );
    const buttonRef = useRef(null);
    const fileNameRef = useRef(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(resetMethod());
    }, []);

    const openFileExplorer = () => {
        buttonRef.current.nextElementSibling.click();
    };

    const handleChange = (event, setFieldValue) => {
        const selectedFile = event.currentTarget.files[0];
        if (selectedFile) {
            setFieldValue("file", selectedFile);
            fileNameRef.current.innerText = selectedFile.name;
        }
    };

    const closeModal = () => {
        if (status !== "pending") {
            dispatch(toggleShow());
        }
    };

    const getUserConditions = () => {
        const conditions = [];
        let condition;
        let count = 1;

        do {
            condition = prompt(
                `Enter condition ${count} (or press Cancel to finish):`
            );
            if (
                condition !== null &&
                condition.trim() !== "" &&
                isNotNumber(condition)
            ) {
                conditions.push(condition.trim());
                count++;
            }
        } while (condition !== null && count < 6);

        return conditions;
    };

    function isNotNumber(value) {
        return isNaN(parseFloat(value));
    }

    return (
        <Modal
            renderContent={() => (
                <Formik
                    initialValues={{
                        file: "",
                    }}
                    validationSchema={validationSchema}
                    onSubmit={(values) => {
                        return new Promise((resolve, _) => {
                            const conditions = getUserConditions();
                            dispatch(
                                uploadMedicalHistoryFileTest({
                                    file: values.file,
                                    conditions: conditions,
                                })
                            ).then((res) => {
                                if (!res.error) {
                                    closeModal();
                                }
                                resolve();
                            });
                        });
                    }}
                >
                    {({ setFieldValue, isSubmitting }) => (
                        <Form id="uploadFileForm">
                            <div className="modal-content">
                                <CloseModalBtn onClose={closeModal} />
                                {status === "pending" ? (
                                    <>
                                        <div className="loading-spinner-container">
                                            <div
                                                style={{
                                                    height: "80px",
                                                    width: "80px",
                                                    borderWidth: "8px",
                                                }}
                                                className="loader"
                                            ></div>
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        {status === "failed" && (
                                            <Alert
                                                message={error}
                                                type={"danger"}
                                            />
                                        )}
                                        <div>
                                            <h6 ref={fileNameRef}>
                                                Upload your file here
                                            </h6>
                                        </div>
                                        <button
                                            type="button"
                                            onClick={() => openFileExplorer()}
                                            ref={buttonRef}
                                            className="upload-btn"
                                        >
                                            <Upload width={32} height={28} />
                                        </button>
                                        <input
                                            type="file"
                                            name="file"
                                            style={{ display: "none" }}
                                            accept=".pdf, .jpg, .jpeg, .png"
                                            onChange={(e) =>
                                                handleChange(e, setFieldValue)
                                            }
                                        />
                                        <ErrorMessage
                                            name="file"
                                            component="div"
                                            className="field-error-message"
                                        />
                                    </>
                                )}
                            </div>
                            <div className="submit-btn-container">
                                <button
                                    type="submit"
                                    className="btn primary"
                                    disabled={
                                        isSubmitting || status === "pending"
                                    }
                                >
                                    Submit
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            )}
        />
    );
};

export default UploadTestFileModal;
