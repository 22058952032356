import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cachedAxiosInstance } from "@/common/utils/axios";

const initialMethodState = {
    name: null,
    status: "idle",
    error: null,
};

export const REFUND_HISTORY_STATUS_CHOICES = [
    { value: "Complete", label: "Complete" },
    { value: "Pending", label: "Pending" },
];

export const getRefunds = createAsyncThunk(
    "products/refunds",
    async (
        { status = "complete", page_size = null, page = null },
        thunkAPI
    ) => {
        try {
            const queryParams = new URLSearchParams();
            if (page_size) queryParams.append("page_size", page_size);
            if (page) queryParams.append("page", page);
            if (status) queryParams.append("status", status);

            const response = await cachedAxiosInstance.get(
                `/products/api/refunds/?${queryParams.toString()}`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get refunds."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const refundsSlice = createSlice({
    name: "refunds",
    initialState: {
        refunds: [],
        status: "idle",
        error: null,
        method: initialMethodState,
    },
    reducers: {
        // reset method state obj to it's initial value
        resetMethod: (state, _) => {
            state.method = initialMethodState;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getRefunds.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getRefunds.fulfilled, (state, action) => {
                const { refunds, ...pagination } = action.payload;
                state.status = "succeeded";
                state.refunds = refunds;
                state.error = null;
                state.pagination = pagination;
            })
            .addCase(getRefunds.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { resetMethod } = refundsSlice.actions;
export default refundsSlice.reducer;
