import React from "react";

const Mic = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            fill={props.color ?? "#fff"}
            d="M8 5a4 4 0 1 1 8 0v7a4 4 0 0 1-8 0V5Z"
        />
        <path
            fill={props.color ?? "#fff"}
            d="M6.25 11.844V12a5.75 5.75 0 0 0 11.5 0v-.156a1 1 0 0 1 1-1h.5a1 1 0 0 1 1 1V12a8.25 8.25 0 0 1-7 8.155V22a1 1 0 0 1-1 1h-.5a1 1 0 0 1-1-1v-1.845a8.25 8.25 0 0 1-7-8.155v-.156a1 1 0 0 1 1-1h.5a1 1 0 0 1 1 1Z"
        />
    </svg>
);
export default Mic;
