import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getPatientMedicalHistory,
    resetMethod,
    deleteMedicalHistory,
} from "../features/medicalHistorySlice";
import { toggleShow } from "../features/modalSlice";
import UploadFileModal from "../components/UploadFileModal";
import ConfirmModal from "../components/confirm_modal/ConfirmModal";
import Alert from "../../common/components/Alert";
import "../common/styles/table_container.scss";
import MedHistTableRow from "../components/MedHistTableRow";
import CustomNavLink from "../components/custom_nav_link/CustomNavLink";
import "./medical_history.scss";
import Pagination from "@/common/components/Pagination/Pagination";

const MedicalHistory = () => {
    const user = useSelector((state) => state.user.user);
    const modalData = useSelector((state) => state.modal.data);
    const { checklist, medical_history, status, error, method, pagination } =
        useSelector((state) => state.medicalHistory);
    const [modalName, setModalName] = useState(null);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getPatientMedicalHistory({ patientId: user.id }));
    }, []);

    const openModal = (modalName, id, name) => {
        setModalName(modalName);
        dispatch(toggleShow({ id: id, name: name }));
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="medicalHistory">
                <div className="page-title-container">
                    <h3>Medical History</h3>
                    <CustomNavLink link={"str-guide/"} text={"STR Guide"} />
                </div>
                {method.status === "succeeded" && (
                    <Alert
                        message={`Document has been ${
                            method.name === "upload" ? "uploaded" : "deleted"
                        } successfully.`}
                        type={"success"}
                    />
                )}
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {status === "succeeded" ? (
                            checklist.length || medical_history.length ? (
                                <>
                                    <table>
                                        <thead>
                                            <tr className="table-header">
                                                <th>ID</th>
                                                <th>Document Name</th>
                                                <th>Checklist Item</th>
                                                <th>Summary</th>
                                                <th>Date Uploaded</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {checklist.map(({ id, name }) => {
                                                {
                                                    /* get related medical history document object if exists */
                                                }
                                                const medHistItem =
                                                    medical_history.find(
                                                        (obj) =>
                                                            obj.checklist_item
                                                                ?.id === id
                                                    );
                                                return (
                                                    <MedHistTableRow
                                                        key={id}
                                                        id={
                                                            medHistItem
                                                                ? medHistItem.id
                                                                : id
                                                        }
                                                        fileName={
                                                            medHistItem?.file_name
                                                        }
                                                        checklistItemName={name}
                                                        SummarizationStatus={
                                                            medHistItem?.summarization_status
                                                        }
                                                        SummaryFileUrl={
                                                            medHistItem?.summary_file_url
                                                        }
                                                        timestamp={
                                                            medHistItem?.timestamp
                                                        }
                                                        modalHandler={openModal}
                                                        isRequired={true}
                                                    />
                                                );
                                            })}
                                            {medical_history.map(
                                                ({
                                                    id,
                                                    checklist_item,
                                                    file_name,
                                                    summarization_status,
                                                    summary_file_url,
                                                    timestamp,
                                                }) => {
                                                    if (!checklist_item) {
                                                        return (
                                                            <MedHistTableRow
                                                                key={id}
                                                                id={id}
                                                                fileName={
                                                                    file_name
                                                                }
                                                                checklistItemName={
                                                                    null
                                                                }
                                                                SummarizationStatus={
                                                                    summarization_status
                                                                }
                                                                SummaryFileUrl={
                                                                    summary_file_url
                                                                }
                                                                timestamp={
                                                                    timestamp
                                                                }
                                                                modalHandler={
                                                                    openModal
                                                                }
                                                            />
                                                        );
                                                    }
                                                }
                                            )}
                                            <MedHistTableRow
                                                key={1}
                                                id={null}
                                                fileName={null}
                                                checklistItemName={null}
                                                SummarizationStatus={null}
                                                SummaryFileUrl={null}
                                                timestamp={null}
                                                modalHandler={openModal}
                                            />
                                        </tbody>
                                    </table>
                                </>
                            ) : (
                                <div className="empty-list-container">
                                    <h6>No Medical History Found.</h6>
                                </div>
                            )
                        ) : (
                            <div className="loading-spinner-container">
                                <div
                                    style={{
                                        height: "80px",
                                        width: "80px",
                                        borderWidth: "8px",
                                    }}
                                    className="loader"
                                ></div>
                            </div>
                        )}
                    </div>

                    {checklist.length > 0 &&
                        pagination?.current_page &&
                        pagination?.total_pages &&
                        pagination?.page_size && (
                            <Pagination
                                currentPage={pagination?.current_page ?? 1}
                                total_pages={pagination?.total_pages ?? 1}
                                itemsPerPage={pagination?.page_size ?? 10}
                                // change current page
                                onPageChange={(page) => {
                                    dispatch(
                                        getPatientMedicalHistory({
                                            patientId: user.id,
                                            page: page,
                                            page_size:
                                                pagination?.page_size ?? 10,
                                        })
                                    );
                                }}
                                // change page size
                                onItemsPerPageChange={(value) => {
                                    dispatch(
                                        getPatientMedicalHistory({
                                            patientId: user.id,
                                            page_size: value,
                                            page: 1, // reset page number
                                        })
                                    );
                                }}
                            />
                        )}
                </div>
                {modalData ? (
                    modalName === "upload" ? (
                        <UploadFileModal userId={user.id} data={modalData} />
                    ) : (
                        <ConfirmModal
                            slice="medicalHistory"
                            title={"Delete Document"}
                            text={`Are you sure you want to delete ${modalData.name}?`}
                            data={modalData}
                            clickHandler={deleteMedicalHistory}
                            resetMethod={resetMethod}
                            action={"Delete"}
                        />
                    )
                ) : null}
            </div>
        );
    }
};

export default MedicalHistory;
