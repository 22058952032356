import React from "react";
import Help from "../../images/help.svg";
import Arrow from "../../images/ben-arrow-right.svg";

const ConditionIncreases = ({ disabilities }) => {
    return (
        <div className="card-sm">
            <div className="card-sm-wrapper">
                <div className="card-header">Condition Increases</div>
                <div className="condition-container">
                    <ul>
                        {disabilities.map((disability, index) => (
                            <li key={index}>
                                <div className="stat-condition stat-header stat-number-header">
                                    {disability.disability_name}
                                </div>
                                <div className="condition-wrapper stat-column">
                                    <div className="stat-txt">{disability.current_rating}%</div>
                                    <div className="stat-column-end">
                                            <Arrow />
                                    </div>
                                    <div className="stat-txt stat-column-end">{disability.max_rating}%</div>
                                </div>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
            <footer>
                <div className="stat-container card-bottom">
                    <div className="stat-help">
                        <div className="stat-help-img">
                            <Help />
                        </div>

                        <a href="#">
                            <span className="stat-help-txt">How are these numbers calculated?</span>
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default ConditionIncreases;
