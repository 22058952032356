import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import Chevron from "@/common/images/icons/chevron.svg";
import "./SidebarFAQ.scss";
import { useDispatch, useSelector } from "react-redux";
import { getDashboardFAQ } from "@/vetvantage/features/dashboardSlice";

// The index needs to match the index of Articles INDEX_CHOICES to be able to fetch the correct FAQ based on the current page
const ROUTE_INDEX_CHOICES = {
    0: "/dashboard",
    1: "/iaqs",
    2: "/itf",
    3: "/condition_exam",
    4: "/medical-history",
    5: "/appointments",
    6: "/paqs",
    7: "/benefit-summary",
    8: "/downloads",
    9: "/claims",
    10: "/checkout",
};

const SidebarFAQ = () => {
    const dispatch = useDispatch();
    const location = useLocation();
    const { sidebarFAQ, status } = useSelector((state) => state.dashboard);
    const [openIndex, setOpenIndex] = useState(null);

    // Match the current page index with the articles
    const currentPageIndex = Object.entries(ROUTE_INDEX_CHOICES).find(
        ([_, value]) =>
            location.pathname.toLowerCase().includes(value.toLowerCase())
    )?.[0];

    useEffect(() => {
        if (currentPageIndex) {
            dispatch(getDashboardFAQ({ index: currentPageIndex }));
        }
        setOpenIndex(null); // Reset open index when route changes
    }, [dispatch, currentPageIndex, location.pathname]); // track route changes

    const toggleAccordion = (index) => {
        setOpenIndex(openIndex === index ? null : index);
    };

    if (status === "pending") {
        return null;
    }
    if (sidebarFAQ && sidebarFAQ.length === 0) {
        return null;
    }
    return (
        <div id="sidebar-faq">
            <div className="title-container">
                <h3>Page FAQ</h3>
            </div>
            <div className="faq-accordion">
                {sidebarFAQ && sidebarFAQ.length > 0 ? (
                    sidebarFAQ.map((item, index) => (
                        <div key={index} className="faq-accordion-item">
                            <button onClick={() => toggleAccordion(index)}>
                                {item.title}
                                <span
                                    className={`arrow ${
                                        openIndex === index ? "open" : ""
                                    }`}
                                >
                                    <Chevron height={24} width={24} />
                                </span>
                            </button>
                            {openIndex === index && (
                                <>
                                    <p className="faq-answer">
                                        {item.answer.length > 50
                                            ? `${item.answer.slice(0, 50)}...`
                                            : item.answer}
                                    </p>
                                    <a
                                        href={item.url}
                                        className="faq-more-link"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        Read More &gt;
                                    </a>
                                </>
                            )}
                        </div>
                    ))
                ) : (
                    <p className="no-faq">No FAQ found</p>
                )}
            </div>
        </div>
    );
};

export default SidebarFAQ;
