import React, { useEffect, useState } from "react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConditionRatings } from "../../features/condition_exam/conditionExamSlice";
import { getDisabilities } from "../../features/productSlice";
import { themeJson } from "../../../common/utils/survey_theme";
import GoBack from "@/common/components/GoBack";
import "./condition_ratings.scss";
import {
    structureConditionRatingsMatrix,
    completeSurvey,
} from "../../utils/condition_ratings";
import CustomNavLink from "../../components/custom_nav_link/CustomNavLink";
import MainUnlock from "../../components/MainUnlock";

StylesManager.applyTheme(themeJson);

const ConditionRatings = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const [surveyModel, setSurveyModel] = useState(null);
    const [showReviewMessage, setShowReviewMessage] = useState(false);
    const [showConditionList, setShowConditionList] = useState(false);
    const [selectedConditions, setSelectedConditions] = useState([]);
    const [conditionRatings, setConditionRatings] = useState({});
    const [selectAll, setSelectAll] = useState(false);
    const [submitLoading, setSubmitLoading] = useState(false);
    const [conditionsDone, setConditionsDone] = useState(false);
    const { condition_data, status } = useSelector(
        (state) => state.conditionExam
    );
    const [showUnlock, setShowUnlock] = useState(false);

    useEffect(() => {
        dispatch(getConditionRatings(user.id));
        dispatch(getDisabilities());
    }, [dispatch, user.id]);

    const handleSelectAll = () => {
        if (selectAll) {
            // If "All" was already selected, uncheck everything
            setSelectedConditions([]);
            setSelectAll(false);
        } else {
            // If "All" was not selected, select all conditions
            var conditionList = [];
            Object.entries(conditionRatings).map(
                ([condition, rating], index) => {
                    conditionList.push(condition);
                }
            );
            setSelectedConditions(conditionList);
            setSelectAll(true);
        }
    };

    useEffect(() => {
        const model = new Model(structureConditionRatingsMatrix());
        model.applyTheme(themeJson);
        model.css = { navigationButton: "btn primary" };
        model.completedHtml = `
                <div class="content-container">
                    <h4 class="spinner-title">Please wait... finding DBQ Suggestions</h4>
                    <div class="loading-spinner-container">
                        <div class="loader"></div>
                    </div>
                </div>
                `;
        if (condition_data) {
            if (Object.entries(condition_data.mongo_data.all_data).length !== 0) {
                setShowUnlock(false);
                model.data = condition_data.mongo_data.all_data.survey_results;
                setShowReviewMessage(!condition_data.conditions_reviewed);
                var conditions = {};
                Object.entries(condition_data.mongo_data.all_data).map(
                    ([key, value], index) => {
                        if (
                            key !== "survey_results" &&
                            key !== "condition_history"
                        ) {
                            conditions[key] = value;
                        }
                    }
                );
                setConditionRatings(conditions);
                setConditionsDone(condition_data.mongo_data.conditions_done);
            }else{
                setShowUnlock(true)
            }
        }
        setSurveyModel(model);
    }, [condition_data]);

    const handleUnlockClick = () => {
        setShowUnlock(false);
    };

    const handleReviewMessageYesClick = () => {
        setShowReviewMessage(false);
        setShowConditionList(true);
        setSelectedConditions([]); // Reset any previous selections
    };

    // Toggle individual checkboxes when a specific condition is clicked
    const handleConditionChange = (condition) => {
        if (selectedConditions.includes(condition)) {
            // Remove condition from selected list
            const updatedConditions = selectedConditions.filter(
                (item) => item !== condition
            );
            setSelectedConditions(updatedConditions);
            setSelectAll(false); // Uncheck "All" if any specific condition is unselected
        } else {
            // Add condition to selected list
            const updatedConditions = [...selectedConditions, condition];
            setSelectedConditions(updatedConditions);

            // Check if all conditions are selected now
            if (updatedConditions.length === conditionRatings.length) {
                setSelectAll(true);
            }
        }
    };

    const handleCompleteSurvey = () => {
        setSubmitLoading(true);
        completeSurvey(null, selectedConditions);
    };

    if (status === "loading" || !surveyModel) {
        return (
            <div className="loading-spinner-container">
                <div className="loader"></div>
            </div>
        );
    }

    //Show unlock page if no condition info exists
    if (showUnlock) {
        return (
            <MainUnlock
                step_title="Condition Ratings Assessment"
                step_btn_text="Add Conditions"
                onClick={handleUnlockClick}
                message="Before your first exam with a medical provider, we will need to find out which Disability Benefits Questionnaires (DBQ) you need to fill out. This information will be shared with your Doctor prior to your exam so they may better assist you with filling out the right Disability Benefits Questionnaire (DBQ) for your conditions. You should be able to find this information from your VA accounts as well."
            />
        );
    }

    if (!showUnlock) {
        return (
            <div id="condition-ratings-container">
                <h2 className="condition-ratings-title">Condition Ratings</h2>
                <div className="page-navigation-container">
                    <GoBack
                        onClick={() => navigate("/dashboard/")}
                        className="nav-left"
                    />
                    <CustomNavLink
                        link="/condition_exam/condition-history/"
                        text="History"
                        className="nav-right"
                    />
                </div>
                {showReviewMessage && (
                    <div className="review-message">
                        Have any of your conditions gotten worse since your last
                        appointment with a medical provider from VetVantage?
                        <div className="btn-container">
                            <button
                                onClick={handleReviewMessageYesClick}
                                className="btn primary"
                            >
                                Yes
                            </button>
                        </div>
                        If not, please click "Review Conditions" and then
                        "Complete" below
                    </div>
                )}

                {showConditionList && (
                    <div className="condition-selection-list">
                        <div className="condition-container">
                            <h4>Select which conditions have gotten worse:</h4>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={selectAll}
                                    onChange={handleSelectAll}
                                />
                                All
                            </label>
                            {Object.entries(conditionRatings).map(
                                ([condition, rating], index) => (
                                    <label key={condition}>
                                        <input
                                            type="checkbox"
                                            checked={selectedConditions.includes(
                                                condition
                                            )}
                                            onChange={() =>
                                                handleConditionChange(condition)
                                            }
                                        />
                                        {condition} - {rating}%
                                    </label>
                                )
                            )}
                        </div>
                        <button
                            onClick={handleCompleteSurvey}
                            className="btn primary"
                        >
                            {submitLoading ? (
                                <>
                                    <div className="loader"></div>
                                </>
                            ) : (
                                "Submit"
                            )}
                        </button>
                    </div>
                )}
                {conditionsDone ? (
                    <MainUnlock
                        step_title={"Condition Finalized!"}
                        step_btn_text={"Next"}
                        btn_url={"/dashboard/"}
                        btnSize={"medium"}
                        // hideGoBack
                    >
                        <div className="instruction-container">
                            <p className="text-half-width">
                                Conditions Reviewed! You no longer need to
                                review your conditions. Please click “Next” to
                                continue with the process.
                            </p>
                        </div>
                    </MainUnlock>
                ) : (
                    surveyModel && (
                        <Survey
                            model={surveyModel}
                            onComplete={completeSurvey}
                        />
                    )
                )}
            </div>
        );
    }
};

export default ConditionRatings;
