import React, { useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleShow } from "../features/modalSlice";
import "./modal.scss";

const Modal = ({ renderContent }) => {
    const is_open = useSelector((state) => state.modal.is_open);
    const dispatch = useDispatch();
    const modalRef = useRef(null);

    // close modal when clicking outside
    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (
                is_open &&
                modalRef.current &&
                !modalRef.current.contains(event.target)
            ) {
                dispatch(toggleShow());
            }
        };

        if (is_open) {
            document.body.style.overflow = "hidden";
            document.addEventListener("mousedown", handleOutsideClick);
        } else {
            document.body.style.overflow = "";
        }

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, [is_open, dispatch]);

    return (
        <div id="modalOverlay" className={`${is_open && "show-modal"}`}>
            {renderContent({ ref: modalRef })}
        </div>
    );
};

export default Modal;
