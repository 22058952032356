import React from "react";
import { useEffect, useContext, useState } from "react";
import ZoomContext from "./context/zoom-context";
import ZoomMediaContext from "./context/zoom-media-context";
import ZoomVideo from "@zoom/videosdk";
import VideoContainer from "./components/video";

const App = ({ zoomjwt, username, sessionName, appointment }) => {
    const [loading, setIsLoading] = useState(true);
    const [mediaStream, setMediaStream] = useState(null);
    const zmClient = useContext(ZoomContext);
    useEffect(() => {
        const init = async () => {
            await zmClient.init("en-US", "Global", {
                leaveOnPageUnload: false,
                patchJsMedia: true,
                enforceMultipleVideos: true,
            });
            try {
                await zmClient
                    .join(sessionName, zoomjwt, username)
                    .catch((e) => {
                        console.error(e);
                    });
                const stream = zmClient.getMediaStream();
                setMediaStream(stream);
                setIsLoading(false);
            } catch (err) {
                console.error("Error Joining Meeting", err);
                setIsLoading(false);
            }
        };
        init();
        return () => {
            ZoomVideo.destroyClient();
        };
    }, [zoomjwt, username, sessionName]);

    return (
        <div className="zoom-app">
            {loading && (
                <div
                    className="loader-container"
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        minHeight: "100px",
                    }}
                >
                    <div className="loader"></div>
                </div>
            )}
            <div className="video-container">
                {!loading && (
                    <ZoomMediaContext.Provider value={{ mediaStream }}>
                        <VideoContainer/>
                    </ZoomMediaContext.Provider>
                )}
            </div>
        </div>
    );
};

export default App;
