import React, { useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import GoBack from "@/common/components/GoBack";
import { getConditionExam } from "../../features/condition_exam/conditionExamSlice";
import "./condition_history.scss";

const ConditionHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const user = useSelector((state) => state.user.user);

    const { condition_data, status, error } = useSelector(
        (state) => state.conditionExam
    );

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    /* TODO: We can optionally move this into middleware */

    useEffect(() => {
        dispatch(getConditionExam(user.id));
    }, [dispatch, user.id]);

    if (isLoading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    return (
        <div className="condition-history-container">
            <GoBack clickHandler={() => navigate("/condition_exam/condition_ratings/")} />
            <h2 className="condition-history-title">Condition History</h2>
            <div className="btn-container">
                <Link to="/condition_exam/condition_ratings/" className="go-back-btn">
                    <div className="go-back-text-container">
                        <p className="go-back-text">{"< Go Back"}</p>
                    </div>
                </Link>
                <div className="add-edit-container">
                    <Link
                        to="/condition_exam/condition_ratings/"
                        className="add-edit-btn btn-primary btn"
                    >
                        <div className="add-edit-text-container">
                            <p className="add-edit-text">Edit</p>
                        </div>
                    </Link>
                </div>
            </div>
            <ul className="condition-history-list">
                {condition_data && Object.keys(condition_data.mongo_data.condition_history).length ? (
                    Object.entries(condition_data.mongo_data.condition_history).map(
                        ([timestamp, entry], index) => (
                            <li key={index} className="card condition-history-item">
                                <ul className="card-details-list">
                                    <p className="card-txt timestamp">
                                        <strong>Timestamp:</strong> {timestamp}
                                    </p>
                                    {Object.entries(entry.changes).map(
                                        ([condition, details], idx) => (
                                            <li key={idx} className="card-details">
                                                <h5 className="card-title">{condition.charAt(0).toUpperCase() + condition.slice(1)}</h5>
                                                <p className="card-txt">
                                                    <strong>Action:</strong> {details.action}
                                                </p>
                                                {details.action === "updated" && (
                                                    <>
                                                        <p className="card-txt">
                                                            <strong>Old Rating:</strong> {details.old_rating}
                                                        </p>
                                                        <p className="card-txt">
                                                            <strong>New Rating:</strong> {details.new_rating}
                                                        </p>
                                                    </>
                                                )}
                                                {details.action === "added" && (
                                                    <p className="card-txt">
                                                        <strong>New Rating:</strong> {details.new_rating}
                                                    </p>
                                                )}
                                                {details.action === "reviewed" && (
                                                    <p className="card-txt">
                                                        <strong>Same Rating:</strong> {details.old_rating}
                                                    </p>
                                                )}
                                                {details.action === "deleted" && (
                                                    <p className="card-txt">
                                                        <strong>Old Rating:</strong> {details.old_rating}
                                                    </p>
                                                )}
                                            </li>
                                        )
                                    )}
                                </ul>
                            </li>
                        )
                    )
                ) : (
                    <li>No condition history available.</li>
                )}
            </ul>
        </div>
    );
};

export default ConditionHistory;
