import React, { useMemo, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import QRCode from "react-qr-code";

import Alert from "@/common/components/Alert";
import GoBack from "@/common/components/GoBack";
import FormField from "@/common/components/form/fields/FormField";
import {
    getTwoFactorCode,
    postTwoFactorCode,
} from "../../features/twoFactorSlice";
import "./TwoFactorInput.scss";

const digitsOnlyRegex = new RegExp("^[0-9]*$");

const validationSchema = Yup.object().shape({
    code: Yup.string()
        .matches(digitsOnlyRegex, "This field must contain digits only.")
        .test(
            "len",
            "Code must be exactly 6 digits",
            (value) => value && value.toString().length === 6
        )
        .required("Required"),
});

const TwoFactorInput = () => {
    const dispatch = useDispatch();

    const { status, error, details, submission } = useSelector(
        (state) => state.twoFactor
    );

    const [isEntered, setIsEntered] = useState(false);

    const [redirectUrl, setRedirectUrl] = useState(null);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    const handleChange = (event, setFieldValue) => {
        const value = event.currentTarget.value;
        if (value.length === 6) {
            if (digitsOnlyRegex.test(value)) {
                setIsEntered(true);
            }
        } else {
            setIsEntered(false);
        }
        setFieldValue("code", value);
    };

    useEffect(() => {
        if (redirectUrl) {
            window.location.replace(redirectUrl);
        } else {
            dispatch(getTwoFactorCode());
        }
    }, [dispatch, redirectUrl]);

    if (status === "failed") {
        return (
            <div style={{ padding: "2rem" }}>
                <GoBack
                    clickHandler={() =>
                        location.replace("/users/twofactor/method/")
                    }
                    text={"Try Another Method"}
                />
                <Alert message={error} type={"error"} />
            </div>
        );
    } else {
        return (
            <div id="two-factor-input">
                {isLoading ? (
                    <div className="loading-spinner-container">
                        <div
                            style={{
                                height: "80px",
                                width: "80px",
                                borderWidth: "8px",
                            }}
                            className="loader"
                        ></div>
                    </div>
                ) : (
                    <Formik
                        initialValues={{
                            code: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setSubmitting }) => {
                            return new Promise((resolve, _) => {
                                dispatch(postTwoFactorCode(values.code)).then(
                                    (res) => {
                                        if (!res.error) {
                                            if (res.payload.redirect_url) {
                                                setRedirectUrl(
                                                    res.payload.redirect_url
                                                );
                                            } else {
                                                setRedirectUrl(
                                                    "/release-of-information/"
                                                );
                                            }
                                        } else {
                                            setSubmitting(false);
                                            resolve();
                                        }
                                    }
                                );
                            });
                        }}
                    >
                        {({ setFieldValue, isSubmitting }) => (
                            <Form id="TwoFactorInputForm" autoComplete="off">
                                {submission.error && (
                                    <Alert
                                        message={submission.error}
                                        type={"error"}
                                    />
                                )}
                                {details?.method === "authenticator" ? (
                                    <>
                                        <div className="page-title-container">
                                            <h3>Authenticator App</h3>
                                        </div>
                                        {details?.qr_code ? (
                                            <>
                                                <Alert
                                                    type="success"
                                                    message={
                                                        "Scan the QR Code with your Authenticator App and enter the obtained 6-digit Code."
                                                    }
                                                />
                                                <div className="qr-container">
                                                    <QRCode
                                                        size={256}
                                                        style={{
                                                            height: "auto",
                                                            maxWidth: "100%",
                                                            width: "100%",
                                                        }}
                                                        value={details.qr_code}
                                                        viewBox={`0 0 256 256`}
                                                    />
                                                </div>
                                            </>
                                        ) : (
                                            <Alert
                                                type="success"
                                                message={
                                                    "Enter the 6-digit Code your received from your Authenticator App."
                                                }
                                            />
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="page-title-container">
                                            <h3>SMS Verification</h3>
                                        </div>
                                        {details.sms_sent ? (
                                            <Alert
                                                type="success"
                                                message={`SMS verification code has been sent to your phone number: ${details.phone_number_slice}`}
                                            />
                                        ) : (
                                            <Alert
                                                type="error"
                                                message={`Unable to send SMS to your phone number: ${details.phone_number_slice} Please check your number or try another method.`}
                                            />
                                        )}
                                    </>
                                )}
                                <FormField
                                    name="code"
                                    type="text"
                                    maxLength={6}
                                    placeholder={"Enter Code"}
                                    onChange={(e) =>
                                        handleChange(e, setFieldValue)
                                    }
                                />
                                <div className="btns-container">
                                    <Link
                                        to={"/users/twofactor/method/"}
                                        className="btn outline"
                                    >
                                        Try Another Method
                                    </Link>

                                    <button
                                        type="submit"
                                        className="btn primary"
                                        disabled={
                                            !isEntered ||
                                            isSubmitting ||
                                            submission.status == "pending"
                                        }
                                    >
                                        {isSubmitting ||
                                        submission.status == "pending" ? (
                                            <div className="loader"></div>
                                        ) : (
                                            "Next"
                                        )}
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                )}
            </div>
        );
    }
};

export default TwoFactorInput;
