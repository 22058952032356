import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance, cachedAxiosInstance } from "@/common/utils/axios";

// Thunk to check if a user exists
export const checkUserExists = createAsyncThunk(
    "signup/checkUserExists",
    async ({ email, phoneNumber }, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                `/users/api/check-user/?email=${encodeURIComponent(
                    email
                )}&phone_number=${encodeURIComponent(phoneNumber)}`
            );
            const data = await response.data;
            // Check if data exists
            if (data.data.email_exists || data.data.phone_number_exists) {
                let errorMessage = "";
                if (data.data.email_exists) {
                    errorMessage =
                        "This email is already in use. Please use a different email.";
                } else if (data.data.phone_number_exists) {
                    errorMessage =
                        "This phone number is already in use. Please use a different phone number.";
                }
                return thunkAPI.rejectWithValue(errorMessage);
            }
            return data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details ||
                        "Failed to check user existence."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

// Thunk to create a new user
export const createUser = createAsyncThunk(
    "signup/createUser",
    async (values, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/users/signup/",
                {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                    password: values.password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to create user."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const createPassword = createAsyncThunk(
    "signup/createPassword",
    async (values, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.patch(
                "/users/signup/",
                {
                    token: values.token,
                    password: values.password,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to create user."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

// User slice
export const signupSlice = createSlice({
    name: "signup",
    initialState: {
        status: "idle",
        error: null,
    },
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkUserExists.pending, (state) => {
                state.status = "pending";
            })
            .addCase(checkUserExists.fulfilled, (state) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(checkUserExists.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(createUser.pending, (state) => {
                state.status = "pending";
            })
            .addCase(createUser.fulfilled, (state) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(createUser.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(createPassword.pending, (state) => {
                state.status = "pending";
            })
            .addCase(createPassword.fulfilled, (state) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(createPassword.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { clearError } = signupSlice.actions;

export default signupSlice.reducer;
