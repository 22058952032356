import React, { useState, useContext, useEffect, useRef } from "react";
import ZoomMediaContext from "../context/zoom-media-context";
import ZoomVideo from "@zoom/videosdk";
import "./settings.scss";
import Alert from "../../common/components/Alert";

const mountDevices = async () => {
    let allDevices = await ZoomVideo.getDevices();
    const cameraDevices = allDevices.filter((device) => {
        return device.kind === "videoinput";
    });
    const micDevices = allDevices.filter((device) => {
        return device.kind === "audioinput";
    });
    const speakerDevices = allDevices.filter((device) => {
        return device.kind === "audiooutput";
    });
    return {
        mics: micDevices.map((item) => {
            return { label: item.label, deviceId: item.deviceId };
        }),
        speakers: speakerDevices.map((item) => {
            return { label: item.label, deviceId: item.deviceId };
        }),
        cameras: cameraDevices.map((item) => {
            return { label: item.label, deviceId: item.deviceId };
        }),
    };
};

const SettingsDialog = ({ onClose }) => {
    const { mediaStream } = useContext(ZoomMediaContext);
    const [cameraList, setCameraList] = useState([]);
    const [micList, setMicList] = useState([]);
    const [speakerList, setSpeakerList] = useState([]);

    const [activeCamera, setActiveCamera] = useState(
        mediaStream.getActiveCamera()
    );
    const [activeMic, setActiveMic] = useState(null);
    const [activeSpeaker, setActiveSpeaker] = useState(null);
    const [isLoading, setLoading] = useState(true);

    //Alert State
    const [alertVisible, setAlertVisible] = useState(false);
    const [alertErrorType, setAlertErrorType] = useState("");
    const [alertErrorMessage, setAlertErrorMessage] = useState("");

    const timeoutRef = useRef(null);

    useEffect(() => {
        mountDevices().then((devices) => {
            setCameraList(devices.cameras);
            setMicList(devices.mics);
            setSpeakerList(devices.speakers);
            if (devices.speakers.length > 0) {
                setActiveSpeaker(devices.speakers[0].deviceId);
            }
            if (devices.mics.length > 0) {
                setActiveMic(devices.mics[0].deviceId);
            }
            if (devices.cameras.length > 0) {
                setActiveCamera(devices.cameras[0].deviceId);
            }
            setLoading(false);
        });

        return () => {
            if (timeoutRef.current) {
                clearTimeout(timeoutRef.current);
            }
        };
    }, []);

    const showAlert = (errorType, errorMessage, duration = 5000) => {
        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }

        setAlertErrorType(errorType);
        setAlertErrorMessage(errorMessage);
        setAlertVisible(true);

        // Hide the alert after 5 seconds
        timeoutRef.current = setTimeout(() => {
            setAlertVisible(false);
        }, duration);
    };

    const onSwitchCamera = async (deviceId) => {
        if (mediaStream) {
            if (activeCamera !== deviceId) {
                try {
                    await mediaStream.switchCamera(deviceId);
                    setActiveCamera(mediaStream.getActiveCamera());
                    showAlert("success", "Camera input changed", 2000);
                } catch (e) {
                    showAlert(
                        "error",
                        "Error: Unable to change your input device"
                    );
                }
            }
        }
    };

    const onSwitchMic = async (deviceId) => {
        if (mediaStream) {
            if (activeMic !== deviceId) {
                try {
                    await mediaStream.switchMicrophone(deviceId);
                    setActiveMic(mediaStream.getActiveMicrophone());
                    showAlert("success", "Microphone input changed", 2000);
                } catch (e) {
                    console.error(e);
                    showAlert(
                        "error",
                        "Error: Unable to change your input device"
                    );
                }
            }
        }
    };

    const onSwitchSpeaker = async (deviceId) => {
        if (mediaStream) {
            if (deviceId !== activeSpeaker) {
                try {
                    await mediaStream.switchSpeaker(deviceId);
                    setActiveSpeaker(mediaStream.getActiveSpeaker());
                    showAlert("success", "Speaker output changed", 2000);
                } catch (e) {
                    console.error(e);
                    showAlert(
                        "error",
                        "Error: Unable to change your input device"
                    );
                }
            }
        }
    };

    return (
        <div className="dialog-overlay settings-dialog-overlay">
            <div className="dialog settings-dialog">
                <h4>Settings</h4>
                {alertVisible && (
                    <Alert type={alertErrorType} message={alertErrorMessage} />
                )}
                {isLoading ? (
                    <div className="loading-spinner"></div>
                ) : (
                    <>
                        <div className="settings-menu">
                            <div className="dropdown-wrapper">
                                <label htmlFor="dropdown">Cameras</label>
                                <select
                                    id="camera-dropdown"
                                    value={activeCamera}
                                    onChange={(event) => {
                                        onSwitchCamera(event.target.value);
                                    }}
                                    className="select-dropdown"
                                >
                                    <option value="" disabled>
                                        Select...
                                    </option>
                                    {cameraList.map((camera, i) => (
                                        <option key={i} value={camera.deviceId}>
                                            {camera.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="dropdown-wrapper">
                                <label htmlFor="dropdown">Microphones</label>
                                <select
                                    id="mic-dropdown"
                                    value={activeMic}
                                    onChange={(event) => {
                                        onSwitchMic(event.target.value);
                                    }}
                                    className="select-dropdown"
                                >
                                    <option value="" disabled>
                                        Select...
                                    </option>
                                    {micList.map((mic, i) => (
                                        <option key={i} value={mic.deviceId}>
                                            {mic.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                            <div className="dropdown-wrapper">
                                <label htmlFor="dropdown">Speakers</label>
                                <select
                                    id="speaker-dropdown"
                                    value={activeSpeaker}
                                    onChange={(event) => {
                                        onSwitchSpeaker(event.target.value);
                                    }}
                                    className="select-dropdown"
                                >
                                    <option value="" disabled>
                                        Select...
                                    </option>
                                    {speakerList.map((speaker, i) => (
                                        <option
                                            key={i}
                                            value={speaker.deviceId}
                                        >
                                            {speaker.label}
                                        </option>
                                    ))}
                                </select>
                            </div>
                        </div>
                        <button
                            className="btn-primary btn primary"
                            onClick={onClose}
                        >
                            <span className="btn-text">Close</span>
                        </button>
                    </>
                )}
            </div>
        </div>
    );
};

export default SettingsDialog;
