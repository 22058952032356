import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance, cachedAxiosInstance } from "../../common/utils/axios";

export const getDashboardSummary = createAsyncThunk(
    "dashboard/summary",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.get("/api/dashboard-summary/");
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details ||
                        "Failed to get dashboard summary."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getDashboardFAQ = createAsyncThunk(
    "dashboard/sidebar-faq",
    async ({ index = "" }, thunkAPI) => {
        try {
            let url = "/api/dashboard-sidebar-faq/";
            if (index) {
                const queryParams = new URLSearchParams({
                    index: index,
                });
                url += `?${queryParams.toString()}`;
            }
            const response = await cachedAxiosInstance.get(url);
            return response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details ||
                        "Failed to get dashboard summary."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        steps: null,
        nextSteps: null,
        estimatedFilingDate: null,
        apptDetails: null,
        status: "idle",
        error: null,
        sidebarFAQ: null,
        sidebarFAQStatus: "idle",
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getDashboardSummary.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getDashboardSummary.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.steps = action.payload.data?.steps;
                state.nextSteps = action.payload.data?.next_steps;
                state.estimatedFilingDate =
                    action.payload.data?.estimated_filing_date;
                state.apptDetails = action.payload.data?.appointment_details;
                state.error = null;
            })
            .addCase(getDashboardSummary.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getDashboardFAQ.pending, (state) => {
                state.sidebarFAQStatus = "pending";
            })
            .addCase(getDashboardFAQ.fulfilled, (state, action) => {
                state.sidebarFAQStatus = "succeeded";
                state.sidebarFAQ = action.payload.faqs;
                state.error = null;
            })
            .addCase(getDashboardFAQ.rejected, (state, action) => {
                state.sidebarFAQStatus = "failed";
                state.error = action.payload;
            });
    },
});

export default dashboardSlice.reducer;
