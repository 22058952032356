import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
    getClaimsDocumentData,
    getClaimsForm,
    handleError,
} from "../../features/claimsSlice";
import Alert from "../../../common/components/Alert";
import GoBack from "@/common/components/GoBack";
import { replaceSpecialChars } from "../../../common/utils/helpers";
import useAlert from "../../hooks/useAlert";
import Spinner from "../../components/Spinner";
import { processClaims, uploadPdfClaims } from "../../utils/generate_itf";
import "./Claims.scss";
import MainUnlock from "../../components/MainUnlock";

const SubmitClaims = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { currentData, status, redirect } = useSelector(
        (state) => state.claims
    );
    const [step, setStep] = useState(1);
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();
    const [downloadLoading, setDownloadLoading] = useState(false);
    const [uploadLoading, setUploadLoading] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [docIdArray, setDocIdArray] = useState([]);

    useEffect(() => {
        dispatch(getClaimsForm());
    }, []);

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
        }
    }, [redirect]);

    const handleDownload = () => {
        setDownloadLoading(true);
        dispatch(getClaimsDocumentData(currentData.claim_id))
            .then(async (res) => {
                const data = res.payload;
                const success = await processClaims({
                    pdfUrl: data.fileUrl,
                    data:
                        data.results_data &&
                        replaceSpecialChars(JSON.stringify(data.results_data)),
                    type: "download",
                });
                if (success) {
                    showAlert({
                        alertType: "info",
                        alertMessage:
                            "Claim Document has been downloaded successfully",
                    });
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: handleError(error, "Error downloading Claim"),
                });
            })
            .finally(() => {
                setDownloadLoading(false);
            });
    };
    const handleUpload = () => {
        setUploadLoading(true);
        uploadPdfClaims(docIdArray)
            .then(async (res) => {
                if (res.error) {
                    throw new Error(res.error);
                } else {
                    showAlert({
                        alertType: "info",
                        alertMessage:
                            "Claim Document has been uploaded successfully",
                    });
                    setShowSuccess(true);
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: handleError(error, "Error uploading Claim"),
                });
                setShowSuccess(false);
            })
            .finally(() => {
                setUploadLoading(false);
            });
    };

    const handleCheckboxChange = (doctorDocumentId) => {
        setDocIdArray((prevArray) => {
            if (prevArray.includes(doctorDocumentId)) {
                return prevArray.filter((id) => id !== doctorDocumentId);
            } else {
                // Only add claim_id if the array is empty and claim_id exists
                return [...new Set([...prevArray, doctorDocumentId])];
            }
        });
    };

    if (showSuccess) {
        return (
            <MainUnlock
                step_title={"Great News!"}
                step_btn_text={"Next"}
                btn_url={"/claims/"}
            >
                <div className="instruction-container">
                    <p className="text-half-width">
                        Your 526EZ Document and your DBQs, have successfully
                        been sent to the VA
                    </p>
                </div>
            </MainUnlock>
        );
    }
    if (status === "loading") {
        return <Spinner />;
    }

    return (
        <div id="claims-submit">
            {step === 2 && (
                <GoBack
                    clickHandler={() => {
                        setStep(1);
                    }}
                />
            )}
            <div className="content-container">
                {alertVisible && (
                    <Alert type={alertType} message={alertMessage} />
                )}
                {step === 1 && (
                    <>
                        <h2 className="primary-text">
                            Prepare Claim Documents
                        </h2>
                        <p className="secondary-text">
                            To ensure the accuracy of your claim submission, it
                            is crucial to thoroughly review all documents before
                            sending them to the VA. This includes uploading the
                            appropriate DBQs for the conditions you are
                            claiming. Choose the disabilities below that you
                            wish to include in your claim to the VA
                        </p>
                        <div className="switch-row-container">
                            {status === "loadingGetClaimsForm" && <Spinner />}
                            {currentData?.user_paqs?.length > 0 &&
                                currentData.user_paqs.map((paq, index) => {
                                    return (
                                        <div
                                            className="switch-row"
                                            key={paq.doctor_document_id}
                                        >
                                            <label
                                                htmlFor={`checkbox-${paq.doctor_document_id}`}
                                            >
                                                {paq.product_name}
                                            </label>
                                            <label className="switch">
                                                <input
                                                    id={`checkbox-${paq.doctor_document_id}`}
                                                    name="paq"
                                                    type="checkbox"
                                                    value={
                                                        paq.doctor_document_id
                                                    }
                                                    onChange={() =>
                                                        handleCheckboxChange(
                                                            paq.doctor_document_id
                                                        )
                                                    }
                                                    checked={docIdArray.includes(
                                                        paq.doctor_document_id
                                                    )}
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                    );
                                })}
                        </div>

                        <div className="btn-container">
                            <button
                                type="button"
                                className="btn primary"
                                onClick={() => {
                                    // Add claim_id to the beginning of the array
                                    setDocIdArray((prevArray) => {
                                        const newArray = currentData?.claim_id
                                            ? [
                                                  currentData.claim_id,
                                                  ...prevArray.filter(
                                                      (id) =>
                                                          id !==
                                                          currentData.claim_id
                                                  ),
                                              ]
                                            : prevArray;
                                        return [...new Set(newArray)];
                                    });
                                    setStep(2);
                                }}
                                disabled={docIdArray.length === 0}
                            >
                                Continue
                            </button>
                        </div>
                    </>
                )}
                {step === 2 && (
                    <>
                        <h2 className="primary-text">Review documents</h2>

                        {uploadLoading ? (
                            <div className="loader-container">
                                <p className="secondary-text">
                                    Sending a Fax to the VA with all your
                                    documents please wait...
                                </p>
                                <div className="loader"></div>
                            </div>
                        ) : (
                            <>
                                <p className="secondary-text">
                                    After this screen you will not be able to
                                    get a copy of your 526EZ Form, to download a
                                    copy, please click Download Copy below
                                </p>
                                <p className="secondary-text">
                                    If you’re ready to submit a claim, please
                                    click the “Submit Claim” button below
                                </p>
                            </>
                        )}
                        <div className="btn-container">
                            <button
                                type="button"
                                onClick={handleDownload}
                                disabled={downloadLoading || uploadLoading}
                                className="btn outline"
                            >
                                {downloadLoading ? (
                                    <>
                                        Downloading...
                                        <div className="loader"></div>
                                    </>
                                ) : (
                                    "Download Copy"
                                )}
                            </button>
                            <button
                                type="button"
                                className="btn primary"
                                onClick={handleUpload}
                                disabled={uploadLoading || downloadLoading}
                            >
                                Submit Claim
                            </button>
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default SubmitClaims;
