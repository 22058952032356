import React, { useEffect, useState } from "react";
import { Model, StylesManager } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/defaultV2.css";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { getConditionExam } from "../../features/condition_exam/conditionExamSlice";
import { getDisabilities } from "../../features/productSlice";
import { themeJson } from "../../../common/utils/survey_theme";
import GoBack from "@/common/components/GoBack";
import "./add_conditions.scss";
import {
    structureSurveyJson,
    completeSurvey,
} from "../../utils/add_conditions";
// import { GoBack } from "../../components/MainUnlock";
StylesManager.applyTheme(themeJson);

const updateButtonVisibility = (buttonId, isVisible) => {
    const button = document.getElementById(buttonId);
    if (button) {
        button.style.display = isVisible ? "inline-block" : "none";
    }
};

//Render component
const AddConditionSurvey = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const [surveyModel, setSurveyModel] = useState(null);

    const { condition_data, conditionStatus } = useSelector(
        (state) => state.conditionExam
    );

    const { disabilities, disabilitiesStatus, disabilitiesError } = useSelector(
        (state) => state.disabilities
    );

    useEffect(() => {
        dispatch(getConditionExam(user.id));
        dispatch(getDisabilities());
    }, [dispatch, user.id]);

    useEffect(() => {
        let calculatedOffSet = 1;
        if (disabilities && disabilities.length > 0 && !surveyModel) {
            //Handles offset if condition data already exists. This means for subsequent visits to add condition after the first time
            if (condition_data && "mongo_data" in condition_data) {
                calculatedOffSet = Object.entries(condition_data.mongo_data.all_data).length > 0
                    ? Object.entries(condition_data.mongo_data.all_data).length - 2
                    : 1;
            }

            const getInitiailDisabilities = () => {
                if (condition_data && "mongo_data" in condition_data) {
                    // Extract condition history from mongo_data (i.e., the already stored conditions)
                    const storedConditionNames = Object.values(condition_data.mongo_data.all_data)
                        .map(condition => condition.name); // Get the 'name' of each condition

                    // Filter out disabilities that already exist in condition history
                    return disabilities.filter(disability => {
                        const isInConditionHistory = storedConditionNames.includes(disability);

                        return !isInConditionHistory;
                    });
                }

                return disabilities;
            };

            // Instantiate the model for the survey using the calculated offset
            const model = new Model(
                structureSurveyJson(calculatedOffSet, getInitiailDisabilities())
            );
            model.applyTheme(themeJson);
            model.css = {
                navigationButton: "btn primary",
            };

            const getRemainingDisabilities = () => {
                // Extract the already stored conditions, handle null or undefined 'all_data'
                const storedConditionNames = condition_data?.mongo_data?.all_data
                    ? Object.values(condition_data.mongo_data.all_data).map(condition => condition.name)
                    : [];

                // Get disabilities selected in the current survey
                const selectedDisabilities = model.pages
                    .map(
                        (page) =>
                            page.questions.find((q) => q.name.includes("Name"))
                                ?.value
                    )
                    .filter(Boolean);

                // Filter out disabilities that are either selected in the survey or already exist in condition history
                return disabilities.filter(disability => {
                    const isSelected = selectedDisabilities.includes(disability);
                    const isInConditionHistory = storedConditionNames.includes(disability);

                    return !isSelected && !isInConditionHistory;
                });
            };

            const updateSurveyButtonsVisibility = () => {
                const remainingDisabilities = getRemainingDisabilities();
                updateButtonVisibility(
                    "sv-nav-add-more",
                    remainingDisabilities.length > 1
                );
                updateButtonVisibility(
                    "sv-nav-delete-condition",
                    model.pages.length > 1
                );
            };

            model.onCurrentPageChanged.add((sender) => {
                updateSurveyButtonsVisibility();
            });

            model.onShowingPreview.add(() => {
                updateButtonVisibility("sv-nav-add-more", false);
                updateButtonVisibility("sv-nav-delete-condition", false);
            });

            model.onComplete.add(() => {
                updateButtonVisibility("sv-nav-add-more", false);
                updateButtonVisibility("sv-nav-delete-condition", false);
            });

            //Add the "add a condition" button to the survey
            model.addNavigationItem({
                id: "sv-nav-add-more",
                title: "Add a Condition",
                //Creates a new page/ panel to the survey if the click add a condition
                action: () => {
                    if (model.currentPage.validate()) {
                        const remainingDisabilities =
                            getRemainingDisabilities();
                        if (remainingDisabilities.length > 0) {
                            const newPageNumber = model.pages.length + calculatedOffSet;

                            // Create a new page
                            const newPage = model.addNewPage(
                                `Page${newPageNumber}`
                            );

                            // Create a new panel in the new page
                            const newPanel = newPage.addNewPanel(
                                `panel${newPageNumber}`
                            );

                            // Add the dropdown question for condition name
                            const textQuestion = newPanel.addNewQuestion(
                                "dropdown",
                                `Condition #${newPageNumber} Name`
                            );
                            textQuestion.title = `Condition #${newPageNumber} Name`;
                            textQuestion.isRequired = true;
                            textQuestion.choices = remainingDisabilities;

                            // Add the rating question for pain scale
                            const ratingQuestion = newPanel.addNewQuestion(
                                "rating",
                                `Condition #${newPageNumber} Pain Scale`
                            );
                            ratingQuestion.title = `Condition #${newPageNumber} Pain Scale`;
                            ratingQuestion.rateCount = 10;
                            ratingQuestion.rateMax = 10;
                            ratingQuestion.rateType = "labels";
                            ratingQuestion.isRequired = true;
                            ratingQuestion.displayMode = "buttons";

                            // Add the comment question for impact
                            const commentQuestion = newPanel.addNewQuestion(
                                "comment",
                                `Condition #${newPageNumber} Impact`
                            );
                            commentQuestion.title = `Condition #${newPageNumber} Impact`;
                            commentQuestion.description =
                                "Please share the ways in which this condition impacts your everyday life, such as work, social situations, mental and physical health issues and etc.";
                            commentQuestion.isRequired = true;

                            //Skip to this new page once done
                            model.currentPageNo = model.pages.length - 1;
                            updateSurveyButtonsVisibility();
                        }
                    }
                },
                css: "nav-button",
                innerCss: "btn primary sd-btn nav-input",
            });

            // Add the "delete condition" button to the survey
            model.addNavigationItem({
                id: "sv-nav-delete-condition",
                title: "Delete Current Condition",
                action: () => {
                    if (model.pages.length > 1) {
                        const currentPageIndex = model.currentPageNo;
                        const currentPage = model.pages[currentPageIndex];

                        // Get the deleted condition name
                        const deletedConditionName = currentPage.questions.find(
                            (q) => q.name.includes("Name")
                        )?.value;

                        model.removePage(currentPage);
                        const updatedData = { ...model.data };
                        model.pages.forEach((page, index) => {
                            const pageNumber = index + calculatedOffSet;
                            page.name = `Page${pageNumber}`;
                            page.questions.forEach((question) => {
                                // Handle data reassignment only for pages after the deleted page
                                if (index >= currentPageIndex) {
                                    const originalQuestionName =
                                        question.name.replace(
                                            /\d+/,
                                            pageNumber + 1
                                        );
                                    const newQuestionName =
                                        question.name.replace(
                                            /\d+/,
                                            pageNumber
                                        );

                                    question.name = newQuestionName;
                                    question.title = newQuestionName;

                                    if (updatedData[originalQuestionName]) {
                                        updatedData[question.name] =
                                            updatedData[originalQuestionName];
                                        delete updatedData[
                                            originalQuestionName
                                        ];
                                    }

                                    // Update choices for condition name questions
                                    if (
                                        question.name.includes("Name") &&
                                        deletedConditionName
                                    ) {
                                        question.choices = [
                                            ...question.choices,
                                            deletedConditionName,
                                        ].sort();
                                    }
                                }
                            });
                        });

                        // Update survey data to reflect new page/question names
                        model.data = updatedData;
                        if (currentPageIndex > 0) {
                            model.currentPageNo = currentPageIndex - 1;
                        }
                        updateSurveyButtonsVisibility();

                        // Show the "Add a Condition" button after deleting a page
                        updateButtonVisibility("sv-nav-add-more", true);
                    }
                },
                css: "nav-button",
                innerCss: "btn primary sd-btn nav-input",
            });

            // Initially update button visibility
            model.onAfterRenderSurvey.add(() => {
                updateSurveyButtonsVisibility();
            });

            model.completedHtml = `
                <div class="loading-spinner-container">
                    <div 
                        style="height: 80px;width: 80px;border-width: 8px;"
                        class="loader"
                    ></div>
                </div>
      `;

            // Add a listener for the "Edit" button to show the "Add Condition" button
            const handleEdit = () => {
                const addConditionBtn =
                    document.getElementById("sv-nav-add-more");
                if (model.currentPageNo === model.pages.length - 1) {
                    addConditionBtn.style.display = "inline-block";
                } else {
                    addConditionBtn.style.display = "none";
                }
            };

            // Bind listener to Edit buttons
            const editBtn = document.getElementById("cancel-preview");
            if (editBtn) {
                editBtn.addEventListener("click", handleEdit);
            }

            setSurveyModel(model);

            // Cleanup the event listener on component unmount
            return () => {
                if (editBtn) {
                    editBtn.removeEventListener("click", handleEdit);
                }
            };
        }
    }, [disabilities, condition_data]);

    const isLoading =
        disabilitiesStatus === "pending" || disabilitiesStatus === "idle";

    //Handle loading stuff
    if (isLoading || !surveyModel) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    //Render component and pass in surveyModel info
    return (
        <div className="container">
            <GoBack clickHandler={() => navigate("/condition_exam/")} />
            {surveyModel && (
                <Survey model={surveyModel} onComplete={completeSurvey} />
            )}
        </div>
    );
};

export default AddConditionSurvey;
