import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import MainUnlock from "../../components/MainUnlock";
import "./products.scss";
import { useDispatch, useSelector } from "react-redux";
import { getOrder } from "../../features/checkoutSlice";

const CheckoutSuccess = () => {
    const dispatch = useDispatch();
    // Use useLocation to access the current URL
    const location = useLocation();

    // Parse the query parameters using URLSearchParams
    const queryParams = new URLSearchParams(location.search);
    const sessionId = queryParams.get("session_id");
    const { order, loading } = useSelector((state) => state.checkout);

    const [serverError, setServerError] = useState("");

    useEffect(() => {
        if (window.contextData?.errorMessage) {
            setServerError(window.contextData.errorMessage);
        }

        dispatch(getOrder(sessionId));
    }, [dispatch, sessionId]);

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (serverError) {
        return (
            <div id="checkout-page" style={{ justifyContent: "center" }}>
                <div className="content-container">
                    <div className="page-title-container">
                        <h3 className="page-title">Error!</h3>
                    </div>
                    <div className="instruction-container">
                        <p>{serverError}</p>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div id="checkout-page">
            <div className="progress-bar-wrapper">
                <div className="progress-bar-container">
                    <div className="progress-label" style={{ left: `100%` }}>
                        Payment - ✔
                    </div>
                    <div className="progress-bar">
                        <div
                            className="progress"
                            style={{ width: `100%` }}
                        ></div>
                    </div>
                </div>
            </div>
            <div className="content-container">
                <MainUnlock
                    step_title={"Great News!"}
                    step_btn_text={"Next"}
                    btn_url={
                        order.order_items.some(
                            (item) => item.product.type === 2
                        )
                            ? "/downloads/"
                            : "/appointments/success"
                    }
                    // hideGoBack
                >
                    <div className="instruction-container">
                        <p className="text-half-width">
                            Payment Successful! Thank you for your purchase.
                            Please click “Next” to continue with the process.
                        </p>
                    </div>
                </MainUnlock>
            </div>
        </div>
    );
};

export default CheckoutSuccess;
