import React from "react";
import { Link } from "react-router-dom";

import "./CustomNavLink.scss";

const CustomNavLink = ({ link, text }) => {
    return (
        <Link id="customNavLink" to={link} className="refund-hist-link">
            <h6>{text}</h6>
        </Link>
    );
};

export default CustomNavLink;
