export const dateOptions = {
    day: "numeric",
    month: "numeric",
    year: "numeric",
};

export const convertUTCToLocalString = (
    isoString,
    options = {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
        hour12: false,
    }
) => {
    const date = new Date(isoString);
    const localDateString = date.toLocaleString(undefined, options);
    return localDateString;
};

export const convertToUserTimezone = (utcTime, userTz) => {
    if (!utcTime) {
        console.error("Invalid time value:", utcTime);
        return "Invalid Time";
    }

    try {
        
        const date = new Date(`${utcTime}Z`); // Ensure the time is interpreted as UTC
        
        if (isNaN(date.getTime())) {
            throw new Error("Invalid date format");
        }

        const newTime = new Intl.DateTimeFormat("en-US", {
            timeZone: userTz,
            dateStyle: "full",
            timeStyle: "short",
        }).format(date);

        return newTime;
    } catch (error) {
        console.error("Error converting time:", error);
        return "Invalid Time"; // Graceful fallback
    }
};

export const removeOffset = (dateTimeWithOffset) => {
    // Use a regular expression to remove the offset part (e.g., "-08")
    return dateTimeWithOffset.replace(/([+-]\d{2}(:\d{2})?)$/, '');
};
