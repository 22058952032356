import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { cachedAxiosInstance, axiosInstance } from "@/common/utils/axios";
import { updateUser } from "./userSlice";

export const markAllPaginatedNotificationsViewed = createAsyncThunk(
    "notifications/markAllPaginatedNotificationsViewed",
    async (notificationIds, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/notifications/api/notifications/new/",
                {
                    viewed_ids: notificationIds
                }
            );

            thunkAPI.dispatch(
                updateUser({
                    notification_count: response.data.notification_count,
                })
            );

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const markAllArchived = createAsyncThunk(
    "notifications/markAllArchived",
    async (_, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/notifications/api/notifications/new/",
                { mark_all_archived: true }
            );

            thunkAPI.dispatch(
                updateUser({
                    notification_count: response.data.notification_count,
                })
            );

            return response.data;
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const archiveNotification = createAsyncThunk(
    "notifications/archiveNotification",
    async (notificationId, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/notifications/api/notifications/new/",
                { archived_id: notificationId }
            );

            thunkAPI.dispatch(
                updateUser({
                    notification_count: response.data.notification_count,
                })
            );

            return { ...response.data, notificationId };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

export const fetchNotifications = createAsyncThunk(
    "notifications/fetchNotifications",
    async ({ type = "new", page_size = null, page = null }, thunkAPI) => {
        try {
            const queryParams = new URLSearchParams();
            if (page_size) queryParams.append("page_size", page_size);
            if (page) queryParams.append("page", page);

            const response = await cachedAxiosInstance.get(
                `/notifications/api/notifications/${type}/?${queryParams.toString()}`
            );
            return { ...response.data, type };
        } catch (error) {
            return thunkAPI.rejectWithValue(error.message);
        }
    }
);

const notificationSlice = createSlice({
    name: "notifications",
    initialState: {
        newNotifications: [],
        archivedNotifications: [],
        error: null,
        loading: false,
        pagination: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchNotifications.pending, (state) => {
                state.loading = true;
            })
            .addCase(fetchNotifications.fulfilled, (state, action) => {
                const { type } = action.payload;
                if (type === "new") {
                    state.newNotifications = action.payload.new_notifications;
                } else {
                    state.archivedNotifications =
                        action.payload.archived_notifications;
                }
                state.pagination = action.payload;
                state.loading = false;
                state.error = null;
            })
            .addCase(fetchNotifications.rejected, (state, action) => {
                state.error = action.payload;
                state.loading = false;
            })
            .addCase(markAllArchived.fulfilled, (state) => {
                state.newNotifications = [];
                state.loading = false;
                state.error = null;
            })
            .addCase(markAllPaginatedNotificationsViewed.fulfilled, (state) => {
                state.loading = false;
                state.error = null;
            })
            .addCase(archiveNotification.fulfilled, (state, action) => {
                const { notificationId } = action.payload;
                state.newNotifications = state.newNotifications.filter(
                    (notification) => notification.id !== notificationId
                );
            })
    },
});

export default notificationSlice.reducer;
