import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import doctorImage from "../../images/doctor-board-blue-2.png";
import { getPAQ } from "../../features/paqSlice";
import Alert from "../../../common/components/Alert";
import "./PAQ.scss";

const PAQDashboard = () => {
    const dispatch = useDispatch();
    const { disabilities, loading, error } = useSelector((state) => state.paq);

    useEffect(() => {
        dispatch(getPAQ());
    }, [dispatch]);

    if (loading || !disabilities) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (error) {
        <div id="paq-page">
            <Alert type="error" message={error} />
        </div>;
    }

    return (
        <div id="paq-page">
            <div className="page-title-container">
                <h3 className="page-title">
                    {disabilities.length === 1
                        ? "You Have 1 Personal Assessment Questionnaire (PAQ) Form"
                        : `You Have ${disabilities.length} Personal Assessment Questionnaire (PAQ) Forms`}
                </h3>
            </div>
            <div className="paq-list-instruction-container">
                <p>
                    Below you can find a list of your current conditions and PAQ
                    completion status.
                </p>
                <p>
                    These PAQs are suggested based on the condition you
                    provided. To make edits to your conditions click{" "}
                    <Link to={"/condition_exam/condition_ratings/"}> here</Link>
                </p>
            </div>
            <div className="paq-list-container">
                <div className="paq-list-title-container">
                    <h6>Current Conditions:</h6>
                </div>
                <div className="paq-list">
                    {disabilities.map((disability, index) => (
                        <div
                            className={`paq-item-container ${
                                disability.status === "coming_soon"
                                    ? "disabled"
                                    : ""
                            }`}
                            key={index}
                        >
                            <div className="paq-item-left">
                                <div className="paq-img-container">
                                    <img
                                        src={doctorImage}
                                        alt="Doctor board"
                                        className={
                                            disability.status === "coming_soon"
                                                ? "grayscale"
                                                : ""
                                        }
                                    />
                                </div>
                                <div className="paq-column">
                                    <div className="paq-name-container">
                                        <p>{disability.product.name}</p>
                                    </div>
                                </div>
                            </div>
                            <div className="paq-item-center">
                                <div className="paq-status-container">
                                    {disability.status === "in_progress" && (
                                        <p>Incomplete</p>
                                    )}
                                    {disability.status === "completed" && (
                                        <p>Completed</p>
                                    )}
                                    {disability.status === "not_started" && (
                                        <p>Not Started</p>
                                    )}
                                    {disability.status === "incomplete" && (
                                        <p>Incomplete</p>
                                    )}
                                    {disability.status === "reviewed" && (
                                        <p>Completed</p>
                                    )}
                                    {disability.status === "coming_soon" && (
                                        <p>Not Available</p>
                                    )}
                                </div>
                            </div>
                            <div className="paq-item-end">
                                {/* Disable benefit summary button if there are more PAQs to complete */}
                                {disability.status === "completed" &&
                                disabilities.findIndex(
                                    (obj) =>
                                        obj.status !== "completed" &&
                                        obj.status !== "coming_soon"
                                ) !== -1 ? (
                                    <button className="btn primary" disabled>
                                        Complete
                                    </button>
                                ) : disability.status === "coming_soon" ? (
                                    <button className="btn primary" disabled>
                                        Coming Soon
                                    </button>
                                ) : (
                                    <Link
                                        to={
                                            disability.status === "completed" || disability.status === "reviewed"
                                                ? "/benefit-summary"
                                                : `/paqs/paq-survey/${
                                                      disability.product.name
                                                  }/${
                                                      disability.status ===
                                                      "incomplete"
                                                          ? 1
                                                          : 0
                                                  }`
                                        }
                                        className="btn primary"
                                    >
                                        {disability.status === "not_started" &&
                                            "Start Questionnaire"}
                                        {disability.status === "incomplete" &&
                                            "Edit Questionnaire"}
                                        {disability.status === "in_progress" &&
                                            "Resume Questionnaire"}
                                        {disability.status === "completed" &&
                                            "View Pre-Benefits Summary"}
                                        {disability.status === "reviewed" &&
                                            "View Pre-Benefits Summary"}
                                    </Link>
                                )}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default PAQDashboard;
