import React, { useRef, useContext, useState } from "react";
import Draggable from "react-draggable";
import ZoomContext from "../context/zoom-context";
import Cross from "../icons/cross";
import "./chat.scss";
import useChat from "../hooks/useChat";
import Send from "../icons/send";

const Chat = ({ onClose }) => {
    const nodeRef = useRef(null);
    const zmClient = useContext(ZoomContext);
    const [currUser] = useState(zmClient.getCurrentUserInfo());

    const { messages, sendMessage, chatInput, setChatInput } = useChat();

    return (
        <Draggable nodeRef={nodeRef} handle=".handle" bounds="#telehealth-root">
            <div ref={nodeRef} className="chat-draggable">
                <div className="chat-header">
                    <span className="handle">Chat</span>
                    <Cross height={24} width={24} color="#000" onClick={onClose} />
                </div>
                <div className="chat-log">
                    {messages.map((msg, i) => (
                        <div
                            className={`chat-msg ${
                                currUser.userId === msg.sender.userId ? "msg-self" : ""
                            }`}
                            key={msg.id}
                        >
                            <div className="msg-sender">{msg.sender.name}</div>
                            <div className="msg-bubble">
                                <span>{msg.message}</span>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="chat-form">
                    <textarea
                        id="chat-input"
                        value={chatInput}
                        onChange={(e) => setChatInput(e.target.value)}
                    />
                    <button
                        className="chat-send-btn"
                        disabled={chatInput === ""}
                        onClick={() => {
                            sendMessage(chatInput);
                            setChatInput("");
                        }}
                    >
                        <Send height={24} width={24} color="#fff" strokeWidth={1.5} />
                    </button>
                </div>
            </div>
        </Draggable>
    );
};

export default Chat;
