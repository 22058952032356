import React from "react";
import { ErrorMessage, useField, useFormikContext } from "formik";
import HiddenField from "./HiddenField";
import "./formfield.scss";

const FormField = ({ label, name, type = "text", className, maxLength, onChange=null, ...props }) => {
    const [field, meta] = useField(name);
    const isError = meta.error;
    const { setFieldTouched } = useFormikContext();
    const getInputClassName = () => {
        return `${className || ""} ${isError ? "error" : ""}`.trim();
    };

    let inputElement;

    switch (type) {
        case "text":
            inputElement = (
                <input
                    {...field}
                    {...props}
                    type={type}
                    maxLength={maxLength} // Directly set maxLength for input types
                    className={getInputClassName()}
                    {...(onChange && { onChange })}
                    onBlur={(e) => {
                        setFieldTouched(name, true);
                        field.onBlur(e);
                    }}
                />
            );
            break;
        case "email":
        case "number":
        case "file":
            inputElement = (
                <input
                    {...field}
                    {...props}
                    type={type}
                    className={getInputClassName()}
                    {...(onChange && { onChange })} // Conditionally add the onChange prop
                    onBlur={(e) => {
                        setFieldTouched(props.name, true);
                        field.onBlur(e);
                      }}
                />
            );
            break;
        case "password":
            inputElement = (
                <HiddenField
                    fieldProps={{
                        ...props,
                        ...field,
                        className: getInputClassName(),
                    }}
                    isError={isError}
                />
            );
            break;
        case "textarea":
            inputElement = (
                <textarea
                    {...field}
                    {...props}
                    className={getInputClassName()}
                    {...(onChange && { onChange })} // Conditionally add the onChange prop
                />
            );
            break;
        case "checkbox":
            inputElement = (
                <input
                    type="checkbox"
                    id={name}
                    {...field}
                    {...props}
                    {...(onChange && { onChange })} // Conditionally add the onChange prop
                    className={getInputClassName()}
                />
            );
            break;
        case "select":
            inputElement = (
                <select
                    {...field}
                    id={name}
                    name={name}
                    {...props}
                    className={getInputClassName()}
                    {...(onChange && { onChange })} // Conditionally add the onChange prop
                    onBlur={(e) => {
                        setFieldTouched(props.name, true);
                        field.onBlur(e);
                      }}
                />
            );
            break;
        default:
            inputElement = (
                <input
                    {...field}
                    {...props}
                    type="text"
                    className={getInputClassName()}
                    onBlur={(e) => {
                        setFieldTouched(props.name, true);
                        field.onBlur(e);
                      }}
                />
            );
            break;
    }

    return (
        <div className={`form-field ${type} ${isError ? "error" : ""}`}>
            {label && (
                <label className="form-label" htmlFor={name}>
                    {label}
                </label>
            )}
            {inputElement}
            <ErrorMessage
                name={name}
                component="div"
                className="field-error-message"
            />
        </div>
    );
};

export default FormField;
