import React, { useState, useContext, useEffect, useCallback } from "react";
import ZoomMediaContext from "../context/zoom-media-context";
import ZoomContext from "../context/zoom-context";
import useParticipantsChange from "../hooks/useParticipantsChange";
import SettingsDialog from "./settings";
import NameBadge from "./namebadge";
import Video from "../icons/video";
import VideoOff from "../icons/video-off";
import MicOff from "../icons/mic-off";
import Mic from "../icons/mic";
import Cog from "../icons/cog";
import ScreenShare from "../icons/screen-share";
import Chat from "../icons/chat";
import Cross from "../icons/cross";
import Exit from "../icons/exit";
import ChatWindow from "./chat";
import { default as ScreenShareView } from "./screen-share";
import {
    endTelehealthAppointment,
    summarizeTelehealthAppointment,
} from "../utils";
import "./video.scss";
import AppointmentContext from "../context/appointment-context";
import useChat from "../hooks/useChat";
import Alert from "../../common/components/Alert";
import { useParams } from "react-router";
import { useDispatch } from "react-redux";
import { isPatientReviewComplete } from "@/tekmed/features/patientsSlice";

const VideoContainer = () => {
    const dispatch = useDispatch();
    const { mediaStream } = useContext(ZoomMediaContext);
    const zmClient = useContext(ZoomContext);
    const liveTranscriptionClient = zmClient.getLiveTranscriptionClient();
    const chatClient = zmClient.getChatClient();
    const appointment = useContext(AppointmentContext);
    const [isLoading, setIsLoading] = useState(false);

    const [videoStarted, setVideoStarted] = useState(false);
    const [audioStarted, setAudioStarted] = useState(false);
    const [audioMuted, setAudioMuted] = useState(
        zmClient.getCurrentUserInfo()?.muted
    );
    const [screenShareStarted, setScreenShareStarted] = useState(false);
    const [showAudioDialog, setShowAudioDialog] = useState(true);
    // Only support 1-to-1 sessions, number of participants will always be 1
    const [participants, setParticipants] = useState(zmClient.getAllUser());
    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const { showChat, setShowChat, chatNotificationCount } = useChat();
    //Alert State
    const [alertErrorType, setAlertErrorType] = useState("");
    const [alertErrorMessage, setAlertErrorMessage] = useState("");
    const [alertDuration, setAlertDuration] = useState(5000);

    const [isSpeaking, setIsSpeaking] = useState(false);

    const [uplinkQuality, setUplinkQuality] = useState(null);
    const [downlinkQuality, setDownlinkQuality] = useState(null);

    const [showLegend, setShowLegend] = useState(false);
    const [qualityType, setQualityType] = useState();

    const { patient_id } = useParams();

    // Toggles the visibility of the legend
    const toggleLegend = (type) => {
        setQualityType(type);
        setShowLegend(!showLegend);
    };

    useParticipantsChange(zmClient, (payload) => {
        setParticipants(
            payload.filter(
                (user) => user.userId !== zmClient.getCurrentUserInfo()?.userId
            )
        );
    });

    const onCameraBtnClick = useCallback(async () => {
        if (videoStarted) {
            await mediaStream?.stopVideo();
            triggerAlert("error", "Camera turned off", 3000);
            setVideoStarted(false);
            setUplinkQuality(null);
        } else {
            // TODO: modify for SharedArrayBuffer support
            // Also think about adding virtual background for branding purposes
            try {
                await mediaStream?.startVideo({
                    videoElement: document.querySelector("#self-view"),
                });
                if (!mediaStream?.isSupportMultipleVideos()) {
                    const canvasElement = document.querySelector(`#self-view`);
                    mediaStream?.renderVideo(
                        canvasElement,
                        zmClient.getSessionInfo().userId,
                        canvasElement.width,
                        canvasElement.height,
                        0,
                        0,
                        3
                    );
                }
                setVideoStarted(true);
                triggerAlert("success", "Camera turned on", 3000);
            } catch (e) {
                console.error(e);
                //TODO see if we need to catch more errors if needed
                if (e.reason === "User camera is taken by other programs.") {
                    triggerAlert(
                        "error",
                        "Your camera is being used by another program",
                        5000
                    );
                }
            }
        }
    }, [videoStarted, mediaStream, zmClient]);

    const enableAudio = useCallback(async () => {
        try {
            await mediaStream?.startAudio({
                highBitrate: true,
                backgroundNoiseSuppression: true,
            });
            triggerAlert("success", "Audio Enabled Successfully");
        } catch (e) {
            if (
                e.type === "INSUFFICIENT_PRIVILEGES" &&
                e.reason === "USER_FORBIDDEN_MICROPHONE"
            ) {
                // TODO: handle missing permissions
                triggerAlert(
                    "error",
                    "Failed to Enable Audio, Insufficient Privileges"
                );
            }
            console.warn(e);
        }
        setAudioStarted(true);
        setShowAudioDialog(false);
    }, [mediaStream]);

    const onMicrophonBtnClick = useCallback(async () => {
        if (audioStarted) {
            if (audioMuted) {
                await mediaStream?.unmuteAudio();
                setAudioMuted(false);
                triggerAlert("success", "Microphone has been UNMUTED", 3000);
            } else {
                await mediaStream?.muteAudio();
                setAudioMuted(true);
                triggerAlert("error", "Microphone has been MUTED", 3000);
                setIsSpeaking(false);
            }
        } else {
            enableAudio();
        }
    }, [audioStarted, mediaStream, audioMuted, enableAudio]);

    const onChatBtnClick = useCallback(() => {
        setShowChat((prev) => !prev);
    }, [showChat]);

    const onShareScreenBtnClick = useCallback(async () => {
        if (!screenShareStarted) {
            try {
                await mediaStream.startShareScreen(
                    document.querySelector("#self-share-view")
                );
                triggerAlert(
                    "success",
                    "You are now sharing your screen",
                    3000
                );
                setScreenShareStarted(true);
            } catch (e) {
                if (e.reason === "user deny screen share") {
                    return;
                }
                triggerAlert("error", "Failed to start screen sharing", 3000);
            }
        } else {
            await mediaStream.stopShareScreen();
            setScreenShareStarted(false);
            triggerAlert(
                "success",
                "You are no longer sharing your screen",
                3000
            );
        }
    }, [mediaStream, screenShareStarted]);

    const stopUsersMedia = async () => {
        // Stop all currently active media from current user and participants
        if (videoStarted) {
            mediaStream.stopVideo();
        }
        mediaStream.stopAudio();
        if (screenShareStarted) {
            await mediaStream.stopShareScreen(
                document.querySelector("#self-share-view")
            );
        }
        await Promise.all(
            participants.map(async (participant) => {
                await mediaStream.stopRenderVideo(
                    document.querySelector(`#participant-view`),
                    participant.userId
                );
            })
        );
    };

    const endMeeting = async (host) => {
        try {
            setUplinkQuality(null);
            setDownlinkQuality(null);

            triggerAlert("success", "Ending the meeting...Please wait", 10000);
            setIsLoading(true);
            await stopUsersMedia();
            let transcript = (
                await liveTranscriptionClient.getFullTranscriptionHistory()
            ).map((t) => {
                return { ...t, isHost: host.userId === t.userId };
            });

            //TODO see if we also want to include chat history with transcripts and summaries
            const chatHistory = await chatClient.getHistory();

            await endTelehealthAppointment(
                appointment.extendedProps.id,
                transcript
            );

            await zmClient.leave(true);
        } catch (e) {
            console.error(e);
        }
    };

    const onLeaveBtnClick = useCallback(async () => {
        const delay = (ms) => new Promise((res) => setTimeout(res, ms));

        triggerAlert(
            "info",
            "Please wait..."
        );
        if(zmClient.getCurrentUserInfo().isHost){
            try {
                // Dispatch the thunk and await its resolution
                const actionResult = await dispatch(
                    isPatientReviewComplete(patient_id)
                );
    
                // Check if the action was fulfilled or rejected
                if (isPatientReviewComplete.fulfilled.match(actionResult)) {
                    const results = actionResult.payload; // Access the successful data
    
                    if (!results.is_final) {
                        triggerAlert(
                            "info",
                            "Please finish reviewing the patient's other information before ending the telehealth meeting. Opening in a new tab"
                        );
                        await delay(5000);
                        window.open(`/patients/${patient_id}`, "_blank");
                        return; // Exit if not finished reviewing
                    }
                } else {
                    // Handle the rejected action
                    console.error(
                        "Failed to get review status:",
                        actionResult.payload
                    );
                    triggerAlert(
                        "error",
                        actionResult.payload ||
                            "Unable to fetch client information."
                    );
                    return;
                }
            } catch (e) {
                console.error("Unexpected error:", e);
                triggerAlert("error", "Unable to fetch client information.");
                return;
            }
        }
        

        // Proceed with leave logic
        let currUser = zmClient.getCurrentUserInfo();
        triggerAlert("error", "Leaving the meeting...");
        if (currUser?.isHost) {
            await endMeeting(currUser);
            // TODO: use react router to control flow
            window.location.href = `/patients/telehealth/${appointment.extendedProps.id}/summary`;
        } else {
            await zmClient.leave();
            window.location.href = "/dashboard";
        }
    }, [liveTranscriptionClient, zmClient, appointment]);

    const onPeerVideoStateChange = useCallback(
        async (payload) => {
            const canvasElement = document.querySelector(`#participant-view`);
            if (payload.action === "Start") {
                mediaStream.renderVideo(
                    canvasElement,
                    payload.userId,
                    canvasElement.width,
                    canvasElement.height,
                    0,
                    0,
                    3
                );
            } else if (payload.action === "Stop") {
                mediaStream.stopRenderVideo(canvasElement, payload.userId);
                setDownlinkQuality(null);
            }
        },
        [mediaStream]
    );

    const onActiveSpeaker = useCallback(
        (payload) => {
            if (payload.length > 1) {
                setIsSpeaking(true);
            } else if (
                payload[0].userId === zmClient.getCurrentUserInfo().userId
            ) {
                setIsSpeaking(true);
            } else {
                setIsSpeaking(false);
            }
        },
        [zmClient]
    );

    const onConnectionChange = useCallback(
        (payload) => {
            // Session ended by host
            if (payload.state == "Closed") {
                if (payload.reason == "ended by host") {
                    // TODO: Improve to use React router for client side routing
                    window.location.href = `/appointments/telehealth/${appointment.extendedProps.id}/summary`;
                }
            }
        },
        [zmClient]
    );

    const onStopSharing = useCallback(
        (payload) => {
            triggerAlert(
                "success",
                "You are no longer sharing your screen",
                3000
            );
            setScreenShareStarted(false);
        },
        [zmClient]
    );

    const onNetworkChange = useCallback(
        (payload) => {
            if (payload.userId === zmClient.getCurrentUserInfo().userId) {
                if (payload.type === "uplink") {
                    setUplinkQuality(payload.level);
                } else {
                    setDownlinkQuality(payload.level);
                }
            }
        },
        [zmClient]
    );

    useEffect(() => {
        zmClient.on("peer-video-state-change", onPeerVideoStateChange);
        zmClient.on("connection-change", onConnectionChange);
        zmClient.on("active-speaker", onActiveSpeaker);
        zmClient.on("passively-stop-share", onStopSharing);
        zmClient.on("network-quality-change", onNetworkChange);
        // Initial connection quality
        // Enable transcription via host
        if (zmClient.getCurrentUserInfo()?.isHost) {
            liveTranscriptionClient.startLiveTranscription();
        }
        // Render the users already in the session
        participants.forEach((user) => {
            if (user.bVideoOn) {
                const canvasElement =
                    document.querySelector(`#participant-view`);
                mediaStream.renderVideo(
                    canvasElement,
                    user.userId,
                    canvasElement.width,
                    canvasElement.height,
                    0,
                    0,
                    3
                );
            }
            if (user.sharerOn) {
                mediaStream.startShareView(
                    document.querySelector("#share-view"),
                    user.userId
                );
            }
        });
        return () => {
            zmClient.off("peer-video-state-change", onPeerVideoStateChange);
            zmClient.off("connection-change", onConnectionChange);
            zmClient.off("active-speaker", onActiveSpeaker);
            zmClient.off("passively-stop-share", onStopSharing);
            zmClient.off("network-quality-change", onNetworkChange);
        };
    }, [zmClient, mediaStream]);

    const triggerAlert = useCallback(
        (errorType, errorMessage, duration = 5000) => {
            window.scrollTo({ top: 0, behavior: "smooth" })
            setAlertErrorType(errorType);
            setAlertErrorMessage(errorMessage);
            setAlertDuration(duration);   
        },
        []

    );

    const getConnectionQualityIcon = (qualityNumber) => {
        switch (qualityNumber) {
            case 5:
                return "🟢"; // Green circle for good connection
            case 4:
                return "🟢";
            case 3:
                return "🟡";
            case 2:
                return "🟡"; // Yellow circle for fair connection
            case 1:
                return "🔴";
            case 0:
                return "🔴"; // Red circle for poor connection
            default:
                return "⚪️"; // White circle for unknown connection quality
        }
    };
    //Handles ending the session after 2 hours from start time
    useEffect(() => {
        const startTime = new Date(appointment.start_date);
        const endTime = new Date(startTime.getTime() + 2 * 60 * 60 * 1000); // Adding 2 hours

        const checkMeetingEnd = () => {
            const now = new Date();
            if (now >= endTime) {
                endMeeting(); // This should trigger the end meeting function
            }
        };

        const intervalId = setInterval(checkMeetingEnd, 30000); // Check every 30 seconds

        return () => clearInterval(intervalId); // Clear interval when the component unmounts
    }, [appointment, endMeeting]);

    useEffect(() => {
        if (alertErrorMessage) {
            const timer = setTimeout(
                () => setAlertErrorMessage(null),
                alertDuration
            ); // Clear alert after 5 seconds
            return () => clearTimeout(timer);
        }
    }, [alertErrorMessage]);

    useEffect(() => {
        if (chatNotificationCount !== 0) {
            triggerAlert("info", "New message in the chat", 3000);
        }
    }, [chatNotificationCount]);

    if (isLoading) {
        return (
            <div className="loading-spinner" style={{ margin: "auto" }}></div>
        );
    }

    return (
        <div className="video-container">
            {showChat && (
                <ChatWindow
                    onClose={() => onChatBtnClick()}
                    triggerAlert={triggerAlert}
                />
            )}
            {showAudioDialog && (
                <div className="dialog-overlay">
                    <div className="dialog audio-dialog">
                        <h4>Action Required</h4>
                        <p>Click the button below to enable audio.</p>
                        <button
                            className="btn-primary btn primary"
                            onClick={enableAudio}
                        >
                            <span className="btn-text">Enable Audio</span>
                        </button>
                    </div>
                </div>
            )}
            {showSettingsModal && (
                <SettingsDialog onClose={() => setShowSettingsModal(false)} />
            )}
            <div className={`views ${screenShareStarted ? "sharing" : ""}`}>
                <div className="self-view">
                    <div className="self-wrapper">
                        {mediaStream?.isRenderSelfViewWithVideoElement() ? (
                            <video
                                id="self-view"
                                width={1920}
                                height={1080}
                            ></video>
                        ) : (
                            <canvas
                                id="self-view"
                                width={1920}
                                height={1080}
                            ></canvas>
                        )}
                        <NameBadge
                            displayName={
                                zmClient.getCurrentUserInfo()?.displayName
                            }
                            audioStarted={audioStarted}
                            muted={audioMuted}
                        />
                        <div
                            className="connection-quality-indicator"
                            onClick={() => toggleLegend("Upload")} // Set to 'Upload'
                        >
                            {getConnectionQualityIcon(uplinkQuality)}
                        </div>
                    </div>
                    {showLegend && (
                        <div className="quality-legend">
                            <p>{qualityType} Speeds</p>
                            <div className="quality-legend-item">
                                🟢
                                <span className="quality-label">Good</span>
                            </div>
                            <div className="quality-legend-item">
                                🟡
                                <span className="quality-label">Fair</span>
                            </div>
                            <div className="quality-legend-item">
                                🔴
                                <span className="quality-label">Poor</span>
                            </div>
                            <div className="quality-legend-item">
                                ⚪️
                                <span className="quality-label">None</span>
                            </div>
                        </div>
                    )}
                </div>

                <div className="participant-view">
                    <div className="canvas-wrapper">
                        <canvas
                            id="participant-view"
                            width={1920}
                            height={1080}
                        ></canvas>
                        <div
                            className="connection-quality-indicator"
                            onClick={() => toggleLegend("Download")}
                        >
                            {getConnectionQualityIcon(downlinkQuality)}
                        </div>
                    </div>
                    <ScreenShareView
                        isStartedShare={screenShareStarted}
                        setIsStartedShare={setScreenShareStarted}
                    />

                    <NameBadge
                        displayName={participants[0]?.displayName}
                        audioStarted={participants[0]?.audio === "computer"}
                        muted={participants[0]?.muted}
                    />
                </div>
            </div>
            {alertErrorMessage && (
                <Alert type={alertErrorType} message={alertErrorMessage} />
            )}
            <div className="video-btns">
                <button
                    className={`mic-btn ${isSpeaking ? "speaking" : ""}`}
                    onClick={onMicrophonBtnClick}
                >
                    <div className="video-btn-icon-wrapper">
                        {audioStarted && !audioMuted ? <Mic /> : <MicOff />}
                    </div>
                </button>
                <button className="camera-btn" onClick={onCameraBtnClick}>
                    <div className="video-btn-icon-wrapper">
                        {videoStarted ? <Video /> : <VideoOff />}
                    </div>
                </button>
                <button
                    className="screen-share-btn"
                    onClick={onShareScreenBtnClick}
                >
                    <div className="video-btn-icon-wrapper">
                        <ScreenShare />
                    </div>
                </button>
                <button
                    className="settings-btn"
                    onClick={() => setShowSettingsModal(true)}
                >
                    <div className="video-btn-icon-wrapper">
                        <Cog />
                    </div>
                </button>
                <button className="chat-btn" onClick={() => onChatBtnClick()}>
                    <div className="video-btn-icon-wrapper">
                        <Chat />
                    </div>
                    {chatNotificationCount > 0 && (
                        <div className="chat-badge-wrapper">
                            <span className="chat-badge">
                                {chatNotificationCount > 99
                                    ? "99+"
                                    : chatNotificationCount}
                            </span>
                        </div>
                    )}
                </button>
                <button className="leave-btn" onClick={onLeaveBtnClick}>
                    <div className="video-btn-icon-wrapper">
                        {zmClient.getCurrentUserInfo()?.isHost ? (
                            <Cross />
                        ) : (
                            <Exit />
                        )}
                    </div>
                </button>
            </div>
        </div>
    );
};

export default VideoContainer;
