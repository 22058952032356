import React, { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";

import Alert from "@/common/components/Alert";
import { getApptUnlockedPageData } from "../../features/appointmentSlice";
import SuccessStarLogo from "../../components/SuccessStarLogo";
import "./AppointmentsUnlocked.scss";

const AppointmntsUnlocked = () => {
    const dispatch = useDispatch();

    const { appointment, status, error } = useSelector(
        (state) => state.appointment
    );

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getApptUnlockedPageData());
    }, []);
    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="AppointmntsUnlocked">
                {isLoading ? (
                    <div className="loading-spinner-container">
                        <div className="loader"></div>
                    </div>
                ) : (
                    <div className="page-content">
                        <SuccessStarLogo />
                        <div className="page-title-container">
                            <h3>Schedule an Evaluation for your conditions.</h3>
                        </div>
                        <div className="page-btn-container">
                            <Link
                                to={"/appointments/select-doctor/"}
                                className="btn primary"
                            >
                                Find Doctor
                            </Link>
                        </div>
                        <div className="instruction-container">
                            {appointment.is_in_person ? (
                                <p>
                                    Now that you have attended 3 Condition Exams
                                    it is finally time to set up an Disability
                                    Benefits Questionnaire Exam (DBQ Exam) with
                                    a doctor near you. During this visit, your
                                    doctor will help complete a Disability
                                    Benefits Questionnaire that you will be able
                                    to submit to the VA either for an increase
                                    in your current rating, or a decision on a
                                    new rating.
                                </p>
                            ) : appointment.is_follow_up ? (
                                <p>
                                    Now that you have completed your first
                                    evaluation, we need to schedule a follow-up
                                    routine exam. This will help, not only track
                                    to see if your conditions have gotten worse,
                                    but it will also help build a claim for your
                                    condition with the VA, to more likely result
                                    in an increase for your disability benefits.
                                </p>
                            ) : (
                                <p>
                                    Now that you have provided us with some
                                    information about your conditions, you will
                                    need to schedule an evaluation. You can
                                    utilize our network of nearly 200 doctors
                                    across 40 states and three countries who are
                                    Veteran-focused and understand the VA
                                    disability process.
                                </p>
                            )}
                        </div>
                        {!appointment.is_follow_up && (
                            <>
                                <div className="instruction-container">
                                    <p>
                                        By utilizing a third-party medical
                                        provider for your evaluation, they can
                                        take the time to ensure your evaluation
                                        thoroughly describes your conditions, so
                                        you get the rating you deserve.
                                    </p>
                                </div>
                                <div className="instruction-container">
                                    <p>
                                        You can set up your appointment quickly,
                                        and we’ll even send the medical provider
                                        your information! It’s that easy.
                                    </p>
                                </div>
                            </>
                        )}
                    </div>
                )}
            </div>
        );
    }
};

export default AppointmntsUnlocked;
