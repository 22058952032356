import React from "react";
import FormField from "./fields/FormField";
import { useFormikContext } from "formik";

const PasswordForm = ({title, buttonText}) => {
    const { setTouched, validateForm, isValid, isSubmitting } = useFormikContext();
    return (
        <>
            <h3>{title}</h3>
            <div className="form-row">
                <div className="form-group">
                    <FormField
                        label="Password"
                        name="password"
                        type="password"
                    />
                </div>
                <div className="form-group">
                    <FormField
                        label="Confirm Password"
                        name="confirmPassword"
                        type="password"
                    />
                </div>
            </div>
            <div className="button-row">
                <button
                    type="submit"
                    className="btn primary"
                    disabled={isSubmitting || !isValid}
                    onClick={async () => {
                        setTouched({
                            password: true,
                            confirmPassword: true,
                        });
                        const errors = await validateForm();
                        if (
                            !errors.password &&
                            !errors.confirmPassword
                        ) {
                            // Proceed to submit
                        }
                    }}
                >
                    {buttonText}
                </button>
            </div>
        </>
    );
};

export default PasswordForm;