import React from "react";
import { Link } from "react-router-dom";
//Changes number like 1, 2 or 3 into 1st, 2nd or 3rd and etc
function getOrdinal(n) {
    const s = ["th", "st", "nd", "rd"];
    const v = n % 100;
    return n + (s[(v - 20) % 10] || s[v] || s[0]);
}

const PageFooter = ({
    requestDbqs,
    allPaqsReviewed,
    allPaqsCompleted,
    isExam4Appt,
    examNumber,
    medicalFilesUploaded
}) => {
    if (allPaqsCompleted) {
        if (allPaqsReviewed) {
            return (
                <>
                    <div className="page-title-container">
                        <h3 className="page-title">
                            Request DBQs With This Information?
                        </h3>
                    </div>
                    <div className="page-btn-container">
                        <Link to={"/downloads/"} className="btn primary">
                            Yes
                        </Link>
                    </div>
                </>
            );
        } else if (!medicalFilesUploaded) {
            return (
                <>
                    <div className="page-title-container">
                        <h3 className="page-title">
                            Upload your medical history files to continue
                        </h3>
                    </div>
                    <div className="page-btn-container">
                        <a
                            href="/medical-history/"
                            className="btn primary"
                        >
                            Upload
                        </a>
                    </div>
                </>
            );
        } else if (isExam4Appt) {
            return (
                <div className="page-title-container">
                    <h3 className="page-title">
                        We are currently waiting for your doctor to review your
                        Disability Benefits Questionnaire. Once complete, we
                        will send you a notification.
                    </h3>
                </div>
            );
        } else {
            return (
                <>
                    <div className="page-title-container">
                        <h3 className="page-title">
                            Schedule your {getOrdinal(examNumber)} Evaluation?
                        </h3>
                    </div>
                    <div className="page-btn-container">
                        <a
                            href="/appointments/unlocked"
                            className="btn primary"
                        >
                            Yes
                        </a>
                    </div>
                </>
            );
        }
    }

    return null;
};

export default PageFooter;
