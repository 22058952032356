import React from "react";

import ChevronLeft from "@/common/images/icons/chevron-left.svg";
import "./GoBack.scss";

const GoBack = ({ clickHandler, text = "Go Back" }) => {
    return (
        <div id="goBack">
            <button onClick={clickHandler}>
                <ChevronLeft width={24} height={24} />
                {text}
            </button>
        </div>
    );
};

export default GoBack;
