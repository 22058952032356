import { useEffect, useRef, useCallback } from "react";

const useParticipantsChange = (zmClient, fn) => {
    const fnRef = useRef(fn);
    fnRef.current = fn;
    const callback = useCallback(
        (updatedParticipants) => {
            const participants = zmClient.getAllUser();
            fnRef.current?.(participants, updatedParticipants);
        },
        [zmClient]
    );
    useEffect(() => {
        zmClient.on("user-added", callback);
        zmClient.on("user-removed", callback);
        zmClient.on("user-updated", callback);
        return () => {
            zmClient.off("user-added", callback);
            zmClient.off("user-removed", callback);
            zmClient.off("user-updated", callback);
        };
    }, [zmClient, callback]);
    useEffect(() => {
        callback();
    }, []);
};

export default useParticipantsChange;
