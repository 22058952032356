import React from "react";

import { formatDate } from "../utils";

const SummarizationTestTableRow = ({
    id,
    fileName,
    fileUrl,
    SummarizationStatus,
    SummaryFileUrl,
    timestamp,
    modalHandler,
    isRequired = false,
}) => {
    if (timestamp) {
        return (
            <tr key={id} className={isRequired ? "grey-bg" : null}>
                <td className="text-el">{id}</td>
                <td className="text-el">{fileName}</td>
                <td>
                    {fileUrl ? (
                        <div className="btn-wrapper">
                            <a
                                href={fileUrl}
                                target="_blank"
                                className="btn primary"
                            >
                                {"View"}
                            </a>
                        </div>
                    ) : (
                        "-"
                    )}
                </td>
                <td>
                    <div className="btn-wrapper">
                        {SummarizationStatus === "completed" ? (
                            <a
                                href={SummaryFileUrl}
                                target="_blank"
                                className="btn primary"
                            >
                                {"View"}
                            </a>
                        ) : (
                            <div className="btn outline">
                                {SummarizationStatus.charAt(0).toUpperCase() +
                                    SummarizationStatus.slice(1)}
                            </div>
                        )}
                    </div>
                </td>
                <td className="text-el">{formatDate(timestamp)}</td>
                <td>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="btn primary"
                            onClick={() => modalHandler("delete", id, fileName)}
                        >
                            {"Delete"}
                        </button>
                    </div>
                </td>
            </tr>
        );
    } else {
        return (
            <tr key={id} className={isRequired ? "grey-bg" : null}>
                <td className="text-el">-</td>
                <td className="text-el">-</td>
                <td className="text-el truncate-text">-</td>
                <td>-</td>
                <td className="text-el">-</td>
                <td>
                    <div className="btn-wrapper">
                        <button
                            type="button"
                            className="btn primary"
                            onClick={() => modalHandler("upload", id)}
                        >
                            Upload
                        </button>
                    </div>
                </td>
            </tr>
        );
    }
};

export default SummarizationTestTableRow;
