import React from "react";
import Carousel from "react-material-ui-carousel";
import { useNavigate } from "react-router-dom";

import Step from "../../../common/components/Step";
import GoBack from "@/common/components/GoBack";
import step1 from "../../images/str-guide/Step1.png";
import step2 from "../../images/str-guide/Step2.png";
import step3 from "../../images/str-guide/Step3.png";
import step4 from "../../images/str-guide/Step4.png";
import step5 from "../../images/str-guide/Step5.png";
import step6 from "../../images/str-guide/Step6.png";
import step7 from "../../images/str-guide/Step7.png";
import step8 from "../../images/str-guide/Step8.png";
import step9 from "../../images/str-guide/Step9.png";
import step10 from "../../images/str-guide/Step10.png";
import "./str_guide.scss";

const StrGuide = () => {
    const navigate = useNavigate();

    const steps = [
        {
            stepNum: 1,
            imageSrc: step1,
            description:
                "Log into your TriCare Patient Portal using your credentials.",
        },
        {
            stepNum: 2,
            imageSrc: step2,
            description:
                "Go through choosing your method of authentication, either text or phone call.",
        },
        {
            stepNum: 3,
            imageSrc: step3,
            description:
                "Enter your 5 digit pin and hit continue, then verify your information.",
        },
        {
            stepNum: 4,
            imageSrc: step4,
            description:
                "Once you have confirmed your information, you will see that you have successfully authenticated.",
        },
        {
            stepNum: 5,
            imageSrc: step5,
            description:
                "On this main page, press Health Record to move to the next page.",
        },
        {
            stepNum: 6,
            imageSrc: step6,
            description: 'On the left hand side press "Download My Data".',
        },
        {
            stepNum: 7,
            imageSrc: step7,
            description:
                "Adjust the dates to match with your dates of service.",
        },
        {
            stepNum: 8,
            imageSrc: step8,
            description:
                'Choose all of the options available and then press "Download PDF".',
        },
        {
            stepNum: 9,
            imageSrc: step9,
            description:
                "Then on the left-hand side, choose Encounter to get records with doctors you have seen during your service.",
        },
        {
            stepNum: 10,
            imageSrc: step10,
            description:
                'Adjust the dates at the top to match your dates of service to generate a list of encounters. Expand each encounter and click on "View Notes" to download each encounter.',
        },
    ];

    const btnStyles = {
        backgroundColor: "#762124",
    };

    const indicatorStyles = {
        color: "#b1b1b1",
    };
    const activeIndicatorStyles = {
        padding: "5px",
        color: "#314068",
    };

    return (
        <div className="str-guide">
            <GoBack clickHandler={() => navigate("/medical-history/")} />
            <h1 className="page-title">
                How to Download Your Medical Records from Tricare
            </h1>
            <Carousel
                autoPlay={false}
                navButtonsAlwaysVisible={true}
                indicators={true}
                className="carousel"
                navButtonsProps={{
                    // Change the colors and radius of the actual buttons. THIS STYLES BOTH BUTTONS
                    style: btnStyles,
                }}
                indicatorIconButtonProps={{
                    style: indicatorStyles,
                }}
                activeIndicatorIconButtonProps={{
                    style: activeIndicatorStyles,
                }}
            >
                {steps.map((step, index) => (
                    <Step
                        key={index}
                        imageSrc={step.imageSrc}
                        description={step.description}
                        number={step.stepNum}
                    />
                ))}
            </Carousel>
        </div>
    );
};

export default StrGuide;
