import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import { Link } from "@mui/material";

import GoBack from "../../../common/components/admin/GoBack";
import { getOrders } from "../../features/ordersSlice";
import { toggleShow } from "../../features/modalSlice";
import AdminLoadingSpinner from "../../components/admin/AdminLoadingSpinner";
import AdminCheckPaymentModal from "../../components/admin/AdminCheckPaymentModal";
import AdminPagination from "../../components/admin/AdminPagination";
import { formatDate } from "../../utils";

const AdminChecksHistory = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { orders, status, error, pagination } = useSelector(
        (state) => state.orders
    );

    const [orderDetails, setOrderDetails] = useState(null);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    const handleGetOrders = (filters) => {
        dispatch(getOrders(filters));
    };

    useEffect(() => {
        handleGetOrders({ status: "2", payment_type: "0" });
    }, []);

    const getOrderDetails = (orderItems, total) => {
        setOrderDetails({ order_items: orderItems, total });
        dispatch(toggleShow());
    };

    if (status === "failed") {
        return (
            <Alert severity="error" sx={{ marginBottom: 1 }}>
                {error}
            </Alert>
        );
    } else {
        return (
            <Container maxWidth="">
                <GoBack
                    clickHandler={() =>
                        navigate("/control-hub/check-payments/")
                    }
                />
                <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1, paddingTop: 2, paddingBottom: 2 }}
                >
                    Check Payments History
                </Typography>
                {isLoading ? (
                    <AdminLoadingSpinner />
                ) : orders.length ? (
                    <TableContainer component={Paper} sx={{ minHeight: 200 }}>
                        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Products</TableCell>
                                    <TableCell>Total</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {orders.map(
                                    ({
                                        id,
                                        patient,
                                        timestamp,
                                        status,
                                        order_items,
                                        amount_paid,
                                    }) => {
                                        return (
                                            <TableRow
                                                key={id}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {id}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Stack>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                        >
                                                            {patient.first_name}{" "}
                                                            {patient.last_name}
                                                        </Typography>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            color={"grey"}
                                                        >
                                                            {patient.email}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {formatDate(timestamp)}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {status.display_text}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Link
                                                        component={"button"}
                                                        onClick={() => {
                                                            getOrderDetails(
                                                                order_items,
                                                                amount_paid
                                                            );
                                                        }}
                                                    >
                                                        {order_items.length}
                                                    </Link>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    $
                                                    {parseFloat(
                                                        amount_paid
                                                    ).toFixed(2)}
                                                </TableCell>
                                                <TableCell>
                                                    <Button
                                                        variant="contained"
                                                        onClick={() => {
                                                            getOrderDetails(
                                                                order_items,
                                                                amount_paid
                                                            );
                                                        }}
                                                    >
                                                        Details
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>
                        {pagination.count > pagination.page_size && (
                            <AdminPagination
                                pageCount={pagination.total_pages}
                                currentPage={pagination.current_page}
                                onPageChange={handleGetOrders}
                                filters={{ status: "2", payment_type: "0" }}
                            />
                        )}
                    </TableContainer>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="h6" component="div">
                            No Check Payments yet.
                        </Typography>
                    </Box>
                )}

                {orderDetails && (
                    <AdminCheckPaymentModal orderDetails={orderDetails} />
                )}
            </Container>
        );
    }
};

export default AdminChecksHistory;
