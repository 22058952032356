import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { axiosInstance } from "@/common/utils/axios";

// Thunk to create a new user
export const createPatient = createAsyncThunk(
    "patients/create-patient/",
    async (values, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/patients/create-patient/",
                {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to create user."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getPatient = createAsyncThunk(
    "patients/get-patient/",
    async (patientId, thunkAPI) => {
        try {
            const response = await axiosInstance.get(
                `/patients/api/${patientId}/`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get patient information."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getPatients = createAsyncThunk(
    "patients/get-patients",
    async ({ userTimeZone, apiUrl, pageChange }, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append("user_tz", userTimeZone);
            formData.append("url", apiUrl);
            formData.append("order", "start_date");
            formData.append("page_change", pageChange);
            // Send the form data
            const response = await axiosInstance.post(
                "/patients/",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            return await response.data.data;
        } catch (error) {
            if (error.response) {
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to upload file."
                );
            } else if (error.request) {
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const uploadFile = createAsyncThunk(
    "patients/upload-file",
    async (values, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append("file", values.selectedFile);
            formData.append("docType", values.docType);
            formData.append("client", values.client);
            // Send the form data
            const response = await axiosInstance.post(
                "/patients/api/upload-doc/",
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );

            return await response.data;
        } catch (error) {
            if (error.response) {
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to upload file."
                );
            } else if (error.request) {
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const getCondition = createAsyncThunk(
    "patients/get-condition/",
    async (conditionId, thunkAPI) => {
        try {
            const response = await axiosInstance.get(
                `/patients/conditions/detail/${conditionId}/`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get patient information."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const updateCondition = createAsyncThunk(
    "patients/get-condition/",
    async (conditionId, thunkAPI) => {
        try {
            const response = await axiosInstance.patch(`/patients/conditions/detail/${conditionId}/`,{
                is_reviewed: true,
            });
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get patient information."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);



export const markConditionAsReviewed = createAsyncThunk(
    "signup/markConditionAsReviewed",
    async (conditionId, thunkAPI) => {
        try {
            const response = await axiosInstance.patch(
                `/patients/conditions/detail/${conditionId}/`,
                {
                    is_reviewed: true,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details ||
                        "Failed to mark condition as reviewed."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const resetPatientInfo = createAsyncThunk(
    "patient/resetPatientInfo",
    async (patientId, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/patients/${patientId}/reset_patient/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const markExamsReviewed = createAsyncThunk(
    "patient/markExamsReviewed",
    async (patientId, { rejectWithValue }) => {
        try {
            const response = await axiosInstance.post(`/patients/${patientId}/exams_reviewed/`);
            return response.data;
        } catch (error) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const addComment = createAsyncThunk(
    "patients/add-comment/",
    async ({ comment, patientId }, thunkAPI) => {
        try {
            const formData = new FormData();
            formData.append("comment", comment);
            const response = await axiosInstance.post(
                `/patients/add-comment/${patientId}/`,
                formData,
                {
                    headers: {
                        "Content-Type": "multipart/form-data",
                    },
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.error?.details || "Failed to add comment."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const isPatientReviewComplete = createAsyncThunk(
    "patients/get-review-status/",
    async (patientId, thunkAPI) => {
        try {
            const response = await axiosInstance.get(
                `/patients/${patientId}/exams_reviewed/`
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get patient information."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

// User slice
export const patientsSlice = createSlice({
    name: "patients",
    initialState: {
        user: null,
        status: "idle",
        error: null,
        patients: null,
        nextPageUrl: null,
        prevPageUrl: null,
        nbrPages: 1,
        condition: null,
        limit: 10
    },
    reducers: {
        clearError: (state) => {
            state.error = null;
        },
        updateLimit: (state, action) => {
            state.limit = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(createPatient.pending, (state) => {
                state.status = "pending";
            })
            .addCase(createPatient.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = action.payload;
                state.error = null;
            })
            .addCase(createPatient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getPatient.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getPatient.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.user = action.payload.data;
                state.error = null;
            })
            .addCase(getPatient.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
                state.user = null;
            })
            .addCase(uploadFile.pending, (state) => {
                state.status = "pending";
            })
            .addCase(uploadFile.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(uploadFile.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getPatients.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getPatients.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;
                state.patients = action.payload.results;
                state.nextPageUrl = action.payload.next;
                state.prevPageUrl = action.payload.previous;
                state.nbrPages = (Math.ceil(action.payload.count / state.limit));
            })
            .addCase(getPatients.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(getCondition.pending, (state) => {
                state.status = "pending";
            })
            .addCase(getCondition.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;
                state.condition = action.payload;
            })
            .addCase(getCondition.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(markConditionAsReviewed.pending, (state) => {
                state.status = "pending";
            })
            .addCase(markConditionAsReviewed.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(markConditionAsReviewed.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            })
            .addCase(addComment.pending, (state) => {
                state.status = "pending";
            })
            .addCase(addComment.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.error = null;
            })
            .addCase(addComment.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.payload;
            });
    },
});

export const { clearError, updateLimit } = patientsSlice.actions;
export default patientsSlice.reducer;
