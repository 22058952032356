import React from "react";

const VolumeOff = (props) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        {...props}
    >
        <path
            stroke={props.color ?? "#fff"}
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="m5 5 14 14"
        />
        <path
            fill={props.color ?? "#fff"}
            fillRule="evenodd"
            d="M8.003 6.917 6.649 8H4a2 2 0 0 0-2 2v4a2 2 0 0 0 2 2h2.65l3.1 2.481c1.31 1.048 3.25.115 3.25-1.562v-5.005l-2-2v7.005l-3.101-2.48A2 2 0 0 0 6.649 14H4v-4h2.65a2 2 0 0 0 1.249-.438L9.426 8.34 8.003 6.917ZM11 7.086V7.08l-.003.002L9.574 5.66l.177-.141C11.06 4.47 13 5.404 13 7.08v2.005l-2-2Zm4.319 7.147a4 4 0 0 1-.255.338 1 1 0 0 0 1.532 1.286c.053-.063.105-.127.155-.192l-1.432-1.432Zm2.402-.426L16 12.085a4 4 0 0 0-.747-2.413 1 1 0 1 1 1.627-1.165 6 6 0 0 1 .842 5.3Zm.453 3.28c-.015.02-.03.037-.046.055a1 1 0 0 0 1.462 1.362l-1.416-1.416Zm2.578-.25-1.487-1.487A7.998 7.998 0 0 0 18.2 6.944a1 1 0 1 1 1.55-1.264 10 10 0 0 1 1.002 11.158Z"
            clipRule="evenodd"
        />
    </svg>
);

export default VolumeOff;
