import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AdminCheckPaymentModal from "../components/admin/AdminCheckPaymentModal";
import { formatDate } from "../utils";
import { getUnpaidOrders, PAYMENT_TYPE, markOrderPaid, resetOrderMethod } from "../features/ordersSlice";
import { toggleShow } from "../features/modalSlice";
import AdminConfirmModal from "../components/admin/AdminConfirmModal";
import AdminLoadingSpinner from "../components/admin/AdminLoadingSpinner";
import { Link } from "@mui/material";
import AdminPagination from "../components/admin/AdminPagination";

const CheckPayments = () => {
    const dispatch = useDispatch();
    const { orders, status, error, method, pagination } = useSelector((state) => state.orders);
    const modalData = useSelector((state) => state.modal.data);
    const [modalName, setModalName] = useState(null);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    const handleGetUnpaidOrders = () => {
        dispatch(getUnpaidOrders({ paymentType: PAYMENT_TYPE.Check }));
    };

    useEffect(() => {
        handleGetUnpaidOrders();
    }, []);

    const getOrderTotal = (orderItems) => {
        return orderItems.reduce((total, item) => total + parseFloat(item.price), 0);
    };

    const openModal = (modalName, data) => {
        setModalName(modalName);
        dispatch(toggleShow(data));
    };

    if (status === "failed") {
        return <Alert severity="error">{error}</Alert>;
    }

    return (
        <Container maxWidth="">
            <Stack
                direction="row"
                justifyContent={"flex-between"}
                alignItems={"center"}
            >
                <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1, paddingTop: 2, paddingBottom: 2 }}
                >
                    Unpaid Checks
                </Typography>
                <Link href="/control-hub/check-payments/history/" variant="h6">
                    History
                </Link>
            </Stack>

            {method.status === "succeeded" && (
                <Alert severity="success" sx={{ marginBottom: 1 }}>
                    {`Order marked as complete`}
                </Alert>
            )}

            {isLoading ? (
                <AdminLoadingSpinner />
            ) : orders.length ? (
                <TableContainer component={Paper} sx={{ marginTop: 2 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Email</TableCell>
                                <TableCell>Date</TableCell>
                                <TableCell>Status</TableCell>
                                <TableCell>Details</TableCell>
                                <TableCell>Total</TableCell>
                                <TableCell>Actions</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {orders.map((order) => {
                                const { id, timestamp, status, order_items, patient } = order;
                                return (
                                    <TableRow key={id}>
                                        <TableCell>{id}</TableCell>
                                        <TableCell>{patient.email}</TableCell>
                                        <TableCell>{formatDate(timestamp)}</TableCell>
                                        <TableCell>{status.display_text}</TableCell>
                                        <TableCell>
                                            <Button
                                                variant="contained"
                                                onClick={() => openModal("details", { order_items, total: getOrderTotal(order_items) })}
                                            >
                                                Details
                                            </Button>
                                        </TableCell>
                                        <TableCell>${getOrderTotal(order_items).toFixed(2)}</TableCell>
                                        <TableCell>
                                            <Tooltip title="Mark as Payment Received">
                                                <IconButton
                                                    onClick={() => openModal("confirm", { id })}
                                                >
                                                    <CheckCircleIcon color="success" />
                                                </IconButton>
                                            </Tooltip>
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                    {pagination.count > pagination.page_size && (
                        <AdminPagination
                            pageCount={pagination.total_pages}
                            currentPage={pagination.current_page}
                            onPageChange={handleGetUnpaidOrders}
                        />
                    )}
                </TableContainer>
            ) : (
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                    }}
                >
                    <Typography variant="h6" component="div">
                        No pending unpaid checks.
                    </Typography>
                </Box>
            )}
            {modalData && (
                modalName === "confirm" ? (
                    <AdminConfirmModal
                        slice="orders"
                        title={"Process Order"}
                        text={`Are you sure you wish to mark this check as received?`}
                        data={modalData}
                        clickHandler={markOrderPaid}
                        resetMethod={resetOrderMethod}
                        action={"Confirm"}
                    />
                ) : <AdminCheckPaymentModal orderDetails={modalData} />
            )}
        </Container>
    );
};

export default CheckPayments;
