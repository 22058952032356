import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, Link } from "react-router-dom";

import Alert from "../../common/components/Alert";
import GoBack from "@/common/components/GoBack";
import { getOrders } from "../features/ordersSlice";
import "../common/styles/table_container.scss";
import "./check_information.scss";
const CheckInformation = () => {
    const navigate = useNavigate();
    const user = useSelector((state) => state.user.user);
    const { orders, status, error } = useSelector((state) => state.orders);
    const [orderTotal, setOrderTotal] = useState(0);

    const [orderDetails, setOrderDetails] = useState();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getOrders({ patientId: user.id, getUnpaid: true }));
    }, [user.id, dispatch]);

    useEffect(() => {
        let total = 0;
        let order_items = [];
        orders.forEach((order) => {
            order.order_items.forEach((order_item) => {
                order_items.push({
                    name: order_item.product.name,
                    price: order_item.price,
                });
                total += parseInt(order_item.price);
            });
        });
        const formattedTotal = new Intl.NumberFormat("en-US", {
            style: "currency",
            currency: "USD",
        }).format(total);
        setOrderDetails(order_items);
        setOrderTotal(formattedTotal);
    }, [orders]);

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="checkPaymentInfo">
                <GoBack clickHandler={() => navigate("/products/checkout/")} />
                <div className="page-title-container">
                    <h3>Send Check Information</h3>
                </div>
                <div className="info-container card">
                    <h4 className="info-title">Total Amount Payable</h4>
                    <div className="info-details-container">
                        <div className="orders-details-container">
                            <h5 className="info-title">Order Details</h5>

                            {orderDetails
                                ? orderDetails.map((order, index) => (
                                      <React.Fragment key={index}>
                                          <div className="single-order card">
                                              <h6 className="order-title">
                                                  Order Item {index + 1}
                                              </h6>
                                              <p className="order-name">
                                                  {order.name}
                                              </p>
                                              <p className="order-price">
                                                  ${order.price}
                                              </p>
                                          </div>
                                      </React.Fragment>
                                  ))
                                : null}
                        </div>
                        <div className="total-container">
                            <div className="total-seperate">
                                <p className="total-label">Total:</p>
                                <p className="total-text">{orderTotal}</p>
                            </div>
                        </div>
                    </div>
                    <div className="info-company-container">
                        <h4 className="info-title">
                            Please Write your check addressed to:{" "}
                        </h4>
                        <div className="info-text-container">
                            <p className="info-text">
                                Global Tek Med LLC Holdings
                            </p>
                        </div>
                    </div>
                    <div className="info-address-container">
                        <h4 className="info-title">Company Address: </h4>
                        <div className="info-text-container">
                            <p className="info-text">
                                5900 Balcones Drive, Suite 100, Austin, TX 78731
                            </p>
                        </div>
                    </div>

                    <div className="info-next-steps-container">
                        <h4 className="info-title">Next Steps: </h4>
                        <div className="info-text-container">
                            <p className="info-text">
                                From here, we will wait to receive your Check in
                                the mail (about 2-3 weeks). Once it is received
                                successfully, we will notify you, through your
                                account, when you can move forward with the
                                process. For now please wait for a response,
                                click the buttons below to either go back to
                                your dashboard, or back to your cart to pay by
                                card
                            </p>
                        </div>
                    </div>
                </div>
                <div className="btn-container">
                    <Link to="/dashboard/" className="btn">
                        Go To Dashboard
                    </Link>
                    <Link to="/products/checkout/" className="btn">
                        Pay By Card
                    </Link>
                </div>
            </div>
        );
    }
};

export default CheckInformation;
