import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@mui/material/Alert";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import { toggleShow } from "../../features/modalSlice";
import {
    getRefundTickets,
    resetMethod,
    createRefund,
} from "../../features/refundTicketsSlice";
import { formatDate } from "../../utils";
import AdminLoadingSpinner from "../../components/admin/AdminLoadingSpinner";
import AdminConfirmModal from "../../components/admin/AdminConfirmModal";
import AdminPagination from "../../components/admin/AdminPagination";

const AdminRefundTickets = () => {
    const dispatch = useDispatch();
    const modalData = useSelector((state) => state.modal.data);
    const { tickets, status, error, method, pagination } = useSelector(
        (state) => state.refundTickets
    );
    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    const handleGetRefundTickets = (filters) => {
        dispatch(getRefundTickets(filters));
    };

    useEffect(() => {
        // TODO: Get all refund tickets and add filters if needed
        handleGetRefundTickets({ status: "requested" });
    }, []);

    const openModal = (id, name) => {
        dispatch(toggleShow({ id: id, name: name }));
    };

    if (status === "failed") {
        return (
            <Alert severity="error" sx={{ marginBottom: 1 }}>
                {error}
            </Alert>
        );
    } else {
        return (
            <Container maxWidth="">
                <Stack
                    direction="row"
                    justifyContent={"flex-between"}
                    alignItems={"center"}
                >
                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ flexGrow: 1, paddingTop: 2, paddingBottom: 2 }}
                    >
                        Refund Tickets
                    </Typography>
                    <Link href="/control-hub/refunds/history/" variant="h6">
                        History
                    </Link>
                </Stack>

                {method.status === "succeeded" && (
                    <Alert severity="success" sx={{ marginBottom: 1 }}>
                        The product amount has been Marked as Refunded.
                    </Alert>
                )}
                {isLoading ? (
                    <AdminLoadingSpinner />
                ) : tickets.length ? (
                    <TableContainer component={Paper} sx={{ minHeight: 200 }}>
                        <Table aria-label="simple table" sx={{ minWidth: 650 }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell>ID</TableCell>
                                    <TableCell>Patient</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Product</TableCell>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {tickets.map(
                                    ({
                                        id,
                                        patient,
                                        order_item,
                                        amount,
                                        status,
                                        timestamp,
                                    }) => {
                                        return (
                                            <TableRow
                                                key={id}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {id}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    <Stack>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                        >
                                                            {patient.first_name}{" "}
                                                            {patient.last_name}
                                                        </Typography>
                                                        <Typography
                                                            variant="p"
                                                            component="div"
                                                            color={"grey"}
                                                        >
                                                            {patient.email}
                                                        </Typography>
                                                    </Stack>
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {formatDate(timestamp)}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {status.display_text}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {order_item.product.name}
                                                </TableCell>
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    $
                                                    {parseFloat(amount).toFixed(
                                                        2
                                                    )}
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Mark as Refunded">
                                                        <IconButton
                                                            onClick={() =>
                                                                openModal(
                                                                    id,
                                                                    order_item
                                                                        .product
                                                                        .name
                                                                )
                                                            }
                                                        >
                                                            <CheckCircleIcon color="success" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    }
                                )}
                            </TableBody>
                        </Table>
                        {pagination.count > pagination.page_size && (
                            <AdminPagination
                                pageCount={pagination.total_pages}
                                currentPage={pagination.current_page}
                                onPageChange={handleGetRefundTickets}
                                filters={{ status: "requested" }}
                            />
                        )}
                    </TableContainer>
                ) : (
                    <Box
                        sx={{
                            display: "flex",
                            justifyContent: "center",
                        }}
                    >
                        <Typography variant="h6" component="div">
                            No Refund Ticket yet.
                        </Typography>
                    </Box>
                )}
                {modalData && (
                    <AdminConfirmModal
                        slice="refundTickets"
                        title={"Refund Product Amount"}
                        text={`Are you sure you want to Mark "${modalData.name}" product amount as Refunded?`}
                        data={modalData}
                        clickHandler={createRefund}
                        resetMethod={resetMethod}
                        action={"Refund Product Amount"}
                    />
                )}
            </Container>
        );
    }
};

export default AdminRefundTickets;
