import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Calendar from "react-calendar";

import ChevronRight from "@/common/images/icons/chevron-right.svg";
import ChevronLeft from "@/common/images/icons/chevron-left.svg";
import Alert from "@/common/components/Alert";
import GoBack from "@/common/components/GoBack";
import {
    getDoctorTime,
    selectApptTime,
    resetSubmission,
} from "../../features/doctorTimeSlice";
import { monthNames, dayNames } from "./constants";
import "./DoctorTime.scss";
import "./calendar.scss";

const DoctorTime = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { data, status, error, submission } = useSelector(
        (state) => state.doctorTime
    );

    const isLoading = useMemo(
        () => status === "pending" || status === "idle",
        [status]
    );

    const [minDate, setMinDate] = useState(null);

    const [date, setDate] = useState(null);

    const [activeIndex, setActiveIndex] = useState(null);

    const [selectedTime, setSelectedTime] = useState(null);

    const [searchParams] = useSearchParams();

    const [isDisabled, setIsDisabled] = useState(false);

    const formatMonth = (locale, date) =>
        date.toLocaleString(locale, { month: "short" });

    const formatDate = (value, type = 1) => {
        const month = value.getMonth();
        const date = value.getDate();
        if (type === 1)
            return `${value.getFullYear()}-${String(month + 1).padStart(
                2,
                "0"
            )}-${String(date).padStart(2, "0")}`;
        return `${dayNames[value.getDay(date)]}, ${monthNames[month]} ${date}`;
    };

    const handleTimeClick = (e, index) => {
        setActiveIndex(index);
        setSelectedTime(e.target.value);
    };

    const submitApptTime = () => {
        setIsDisabled(true);
        dispatch(resetSubmission());
        const datetime = `${formatDate(date)}T${selectedTime}`;
        return new Promise((resolve, _) => {
            dispatch(selectApptTime({ datetime: datetime })).then((res) => {
                if (!res.error) {
                    navigate("/appointments/summary/");
                } else {
                    setIsDisabled(false);
                    resolve();
                }
            });
        });
    };

    useEffect(() => {
        if (activeIndex || selectedTime) {
            setActiveIndex(null);
            setSelectedTime(null);
        }
        new Promise((resolve, _) => {
            dispatch(
                getDoctorTime({
                    isReschedule: searchParams.get("is_reschedule"),
                    date: date && formatDate(date),
                })
            ).then((res) => {
                if (!res.error && !date) {
                    const dateParts = res.payload.min_date.split("-");
                    const year = parseInt(dateParts[0], 10);
                    const month = parseInt(dateParts[1], 10) - 1; // Months are 0-indexed
                    const day = parseInt(dateParts[2], 10);
                    const selectedDate = new Date(year, month, day);
                    setDate(selectedDate);
                    if (!minDate) {
                        setMinDate(selectedDate);
                    }
                } else {
                    resolve();
                }
            });
        });
    }, [date && date.getDate(), dispatch]);

    return (
        <div id="doctorTime">
            <div className="page-container">
                <GoBack
                    clickHandler={() =>
                        navigate("/appointments/select-doctor/")
                    }
                />
                <div className="page-title-container">
                    {date && <h3>Schedule with Dr. {data.last_name}</h3>}
                </div>
                {status === "failed" || submission.status === "failed" ? (
                    <Alert
                        message={error ? error : submission.error}
                        type={"error"}
                    />
                ) : (
                    ""
                )}
                {status !== "failed" && (
                    <>
                        <div
                            className={
                                isDisabled
                                    ? "datetime-picker-container disabled"
                                    : "datetime-picker-container"
                            }
                        >
                            <div className="instruction-wrapper">
                                {date && <h6>Select a date and time.</h6>}
                            </div>
                            <div
                                className={
                                    isDisabled
                                        ? "datetime-picker-wrapper no-pointer-events"
                                        : "datetime-picker-wrapper"
                                }
                            >
                                {date && (
                                    <>
                                        <div className="date-picker-container">
                                            <Calendar
                                                onChange={setDate}
                                                value={date}
                                                calendarType={"gregory"}
                                                minDetail="year"
                                                minDate={minDate}
                                                showWeekNumbers={false}
                                                formatMonth={formatMonth}
                                                locale={"en-US"}
                                                prevLabel={
                                                    <ChevronLeft
                                                        width={24}
                                                        height={24}
                                                    />
                                                }
                                                nextLabel={
                                                    <ChevronRight
                                                        width={24}
                                                        height={24}
                                                    />
                                                }
                                                onClickDay={""}
                                            />
                                        </div>
                                        <div className="devider"></div>
                                    </>
                                )}
                                {isLoading ? (
                                    <div className="loading-spinner-container">
                                        <div className="loader"></div>
                                    </div>
                                ) : (
                                    <div className="time-picker-container">
                                        {date && (
                                            <div className="date-wrapper">
                                                <p>{formatDate(date, 2)}</p>
                                            </div>
                                        )}
                                        <div className="horizontal-line"></div>
                                        {data?.available_time?.time.length ? (
                                            <div className="time-btns-wrapper">
                                                {data?.available_time?.time.map(
                                                    (value, index) => {
                                                        return (
                                                            <button
                                                                key={index}
                                                                type="button"
                                                                value={value}
                                                                className={
                                                                    activeIndex ===
                                                                    index
                                                                        ? "selected-hour"
                                                                        : ""
                                                                }
                                                                onClick={(e) =>
                                                                    handleTimeClick(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                            >
                                                                {
                                                                    data
                                                                        ?.available_time
                                                                        ?.formatted_time_tz[
                                                                        index
                                                                    ]
                                                                }
                                                            </button>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        ) : (
                                            <div className="instruction-container">
                                                <p>No available hours found.</p>
                                            </div>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className="page-btn-container">
                            {date && (
                                <button
                                    type="button"
                                    className="btn primary"
                                    disabled={
                                        !selectedTime ||
                                        submission.status === "pending"
                                    }
                                    onClick={submitApptTime}
                                >
                                    {submission.status === "pending" ? (
                                        <div className="loader"></div>
                                    ) : (
                                        "Continue"
                                    )}
                                </button>
                            )}
                        </div>
                    </>
                )}
            </div>
        </div>
    );
};

export default DoctorTime;
