import React from "react";

const Chat = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <path
            fill={props.color ?? "#fff"}
            d="M22 3a1 1 0 0 0-1-1H3a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h5.5l2.7 3.6a1 1 0 0 0 1.6 0l2.7-3.6H21a1 1 0 0 0 1-1Zm-2 13h-5a1 1 0 0 0-.8.4L12 19.333 9.8 16.4A1 1 0 0 0 9 16H4V4h16ZM7 8a1 1 0 0 1 1-1h8a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1Zm0 5a1 1 0 0 1 1-1h4a1 1 0 0 1 0 2H8a1 1 0 0 1-1-1Z"
        />
    </svg>
);

export default Chat;
