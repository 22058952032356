import React, { useContext } from "react";
import ZoomVideo from "@zoom/videosdk";
import ZoomContext from "../context/zoom-context";
import AppointmentContext from "../context/appointment-context";
import App from "../App";
import Summary from "./summary";

const   TelehealthContent = ({
    zoomjwt,
    username,
    is_summary,
    platform,
    itfComplete,
    tekmedlink,
}) => {
    const appointment = useContext(AppointmentContext);

    if (is_summary) {
        return <Summary platform={platform} itfComplete={itfComplete} />;
    } else {
        let zmClient = ZoomVideo.createClient();
        return (
            <ZoomContext.Provider value={zmClient}>
                <App
                    sessionName={appointment.extendedProps.zoom_session_name}
                    zoomjwt={zoomjwt}
                    username={username}
                    tekmedlink={tekmedlink}
                />
            </ZoomContext.Provider>
        );
    }
};

export default TelehealthContent;
