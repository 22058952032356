import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import Search from "@/common/images/icons/search.svg";
import ChevronRight from "@/common/images/icons/chevron-right.svg";
import ChevronLeft from "@/common/images/icons/chevron-left.svg";
import Alert from "@/common/components/Alert";
import FormField from "@/common/components/form/fields/FormField";
import GoBack from "@/common/components/GoBack";
import { getUserPosition } from "@/common/utils/position";
import {
    getDoctors,
    selectDoctor,
    resetMethod,
} from "../../features/doctorsSlice";
import { toggleShow } from "../../features/modalSlice";
import EvaluationTypeModal from "../../components/evaluation_type_modal/EvaluationTypeModal";
import Location from "../../images/location.svg";
import User from "../../images/user.png";
import "./DoctorList.scss";

const digitsOnlyRegex = new RegExp("^[0-9]*$");

const validationSchema = Yup.object({
    limit: Yup.string().oneOf(["6", "9", "12"]),
    zipcode: Yup.string().matches(
        digitsOnlyRegex,
        "This field must contain digits only."
    ),
    evaluation: Yup.string().oneOf(["in_person", "telehealth", "both"]),
    gender: Yup.string().oneOf(["male", "female"]),
    distance: Yup.string().oneOf(["100", "500", "1000"]),
});

const DoctorList = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { data, status, error, method } = useSelector(
        (state) => state.doctors
    );

    const [filters, setFilters] = useState(null);

    const [position, setPosition] = useState(null);

    const isLoading = useMemo(
        () =>
            status === "pending" ||
            status === "idle" ||
            method.status === "pending",
        [status, method.status]
    );

    const [doctor, setDoctor] = useState();

    const [searchEnabled, setSearchEnabled] = useState(true);

    useEffect(() => {
        dispatch(getDoctors(filters));
    }, [filters]);

    const handleChange = (event, values, setFieldValue, submitForm) => {
        const field = event.currentTarget;
        const name = field.name;
        const value = field.value;
        if (name === "offset") {
            const currOffset = values.offset;
            setFieldValue(
                name,
                value === "prev" ? currOffset - 1 : currOffset + 1
            );
            submitForm();
        } else {
            setFieldValue(name, value);
            if (name === "zipcode") {
                if (value) {
                    setSearchEnabled(digitsOnlyRegex.test(value));
                } else {
                    setSearchEnabled(true);
                }
            } else {
                if (name === "limit") {
                    setFieldValue("offset", "");
                }
                submitForm();
            }
        }
    };

    const handleSelectDoctor = (data) => {
        const { preferred_eval_type, ...newData } = data;
        const { type, ...doctor } = newData;
        dispatch(resetMethod());
        return new Promise((resolve, _) => {
            dispatch(selectDoctor({ doctor: doctor, type: type })).then(
                (res) => {
                    if (!res.error) {
                        navigate("/appointments/select-datetime/");
                    } else {
                        resolve();
                    }
                }
            );
        });
    };

    const handleClick = (doctorData) => {
        if (
            doctorData.preferred_eval_type.value === "both" &&
            !data.is_exam_four &&
            !data.in_person_required
        ) {
            setDoctor(doctorData);
            dispatch(toggleShow());
        } else {
            handleSelectDoctor(doctorData);
        }
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="doctorList">
                <div className="page-container">
                    <GoBack
                        clickHandler={() => navigate("/appointments/unlocked/")}
                    />
                    <div className="page-title-container">
                        <h3>Find a Doctor in Your Area</h3>
                    </div>
                    {data?.is_exam_four || data?.in_person_required ? (
                        <Alert
                            message={`Note: ${
                                data.is_exam_four
                                    ? "The last evaluation must be in person"
                                    : "Some of your condition requires an In-Person Evaluation"
                            }. Your appointment request will be sent for an In-Person Evaluation regardless the doctor's preferred type of evaluation.`}
                            type={"info"}
                        ></Alert>
                    ) : (
                        ""
                    )}

                    {method.status === "failed" && (
                        <Alert message={method.error} type={"error"} />
                    )}
                    <Formik
                        initialValues={{
                            limit: "6",
                            offset: "",
                            zipcode: "",
                            evaluation: "",
                            gender: "",
                            distance: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { setFieldValue }) => {
                            if (values.distance && !position) {
                                getUserPosition()
                                    .then((position) => {
                                        const coordinates = position.coords;
                                        const pos = `${coordinates.latitude},${coordinates.longitude}`;
                                        // save position so we don't ask the user again.
                                        setPosition(pos);
                                        setFilters({
                                            ...values,
                                            position: pos,
                                        });
                                    })
                                    .catch((error) => {
                                        // reset miles from location field value.
                                        setFieldValue("distance", "");
                                        console.error(
                                            "Error getting position:",
                                            error
                                        );
                                    });
                            } else {
                                setFilters({ ...values, position: position });
                            }
                        }}
                    >
                        {({ values, setFieldValue, submitForm }) => (
                            <Form
                                id="doctorListForm"
                                autoComplete="off"
                                onSubmit={() => {
                                    setFieldValue("position", position);
                                }}
                            >
                                <div className="filters-container">
                                    <div className="filters-left-wrapper">
                                        <div className="sort-by-title">
                                            <h6>Sort by:</h6>
                                        </div>
                                        <div className="zipcode-wrapper">
                                            <div className="location-icon-container">
                                                <Location />
                                            </div>
                                            <div className="form-field text">
                                                <input
                                                    name="zipcode"
                                                    type="text"
                                                    maxLength={6}
                                                    placeholder={"ZIP Code"}
                                                    className="zip-code-filter"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e,
                                                            values,
                                                            setFieldValue,
                                                            submitForm
                                                        )
                                                    }
                                                />
                                            </div>
                                            <button
                                                className="btn primary"
                                                type="button"
                                                disabled={!searchEnabled}
                                                onClick={submitForm}
                                            >
                                                <Search
                                                    color={"#ffffff"}
                                                    width={24}
                                                    height={24}
                                                />
                                            </button>
                                        </div>
                                    </div>
                                    <div className="filters-right-wrapper">
                                        <div className="filter-top">
                                            <div className="filter-by-title">
                                                <div className="filter-by-title-wrapper">
                                                    <h6>Filter by:</h6>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="filter-bottom">
                                            <div className="evaluation-wrapper">
                                                <p className="filter-title">
                                                    Evaluation:
                                                </p>
                                                <FormField
                                                    name="evaluation"
                                                    type="select"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e,
                                                            values,
                                                            setFieldValue,
                                                            submitForm
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Select One
                                                    </option>
                                                    <option value="in_person">
                                                        In-Person
                                                    </option>
                                                    <option value="telehealth">
                                                        Telehealth
                                                    </option>
                                                    <option value="both">
                                                        In-Person and Telehealth
                                                    </option>
                                                </FormField>
                                            </div>
                                            <div className="gender-wrapper">
                                                <p className="filter-title">
                                                    Gender:
                                                </p>
                                                <FormField
                                                    name="gender"
                                                    type="select"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e,
                                                            values,
                                                            setFieldValue,
                                                            submitForm
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Select One
                                                    </option>
                                                    <option value="male">
                                                        Male
                                                    </option>
                                                    <option value="female">
                                                        Female
                                                    </option>
                                                </FormField>
                                            </div>
                                            <div className="miles-wrapper">
                                                <p className="filter-title">
                                                    Miles from location:
                                                </p>
                                                <FormField
                                                    name="distance"
                                                    type="select"
                                                    onChange={(e) =>
                                                        handleChange(
                                                            e,
                                                            values,
                                                            setFieldValue,
                                                            submitForm
                                                        )
                                                    }
                                                >
                                                    <option value="">
                                                        Select One
                                                    </option>
                                                    <option value="100">
                                                        100
                                                    </option>
                                                    <option value="500">
                                                        500
                                                    </option>
                                                    <option value="1000">
                                                        1000
                                                    </option>
                                                </FormField>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="list-container">
                                    {isLoading ? (
                                        <div className="loading-spinner-container">
                                            <div className="loader"></div>
                                        </div>
                                    ) : data?.results.length ? (
                                        <>
                                            <div className="page-controls">
                                                <div className="nbr-items-container">
                                                    <div className="text-container">
                                                        <p>Show</p>
                                                    </div>
                                                    <FormField
                                                        name="limit"
                                                        type="select"
                                                        onChange={(e) =>
                                                            handleChange(
                                                                e,
                                                                values,
                                                                setFieldValue,
                                                                submitForm
                                                            )
                                                        }
                                                    >
                                                        <option value="6">
                                                            6
                                                        </option>
                                                        <option
                                                            value="9"
                                                            disabled={
                                                                data.count < 9
                                                            }
                                                        >
                                                            9
                                                        </option>
                                                        <option
                                                            value="12"
                                                            disabled={
                                                                data.count < 12
                                                            }
                                                        >
                                                            12
                                                        </option>
                                                    </FormField>
                                                    <div className="text-container lrg-view-text">
                                                        <p>per page</p>
                                                    </div>
                                                </div>
                                                <div className="page-nbr-container">
                                                    <div className="text-container">
                                                        <p>page</p>
                                                    </div>
                                                    <FormField
                                                        name="page"
                                                        type="text"
                                                        maxLength={4}
                                                        value={data.page_num}
                                                        disabled
                                                    />
                                                    <div className="text-container lrg-view-text">
                                                        <p id="nbr-pages-el">
                                                            of {data.page_count}
                                                        </p>
                                                    </div>
                                                </div>
                                                <div className="next-prev-btns-container">
                                                    <button
                                                        type="button"
                                                        style={{
                                                            marginRight:
                                                                ".5rem",
                                                        }}
                                                        name="offset"
                                                        value={"prev"}
                                                        onClick={(e) =>
                                                            handleChange(
                                                                e,
                                                                values,
                                                                setFieldValue,
                                                                submitForm
                                                            )
                                                        }
                                                        disabled={
                                                            !data.previous
                                                        }
                                                    >
                                                        <ChevronLeft
                                                            width={24}
                                                            height={24}
                                                            color={"#3c6ca4"}
                                                        />
                                                    </button>
                                                    <button
                                                        type="button"
                                                        name="offset"
                                                        value={"next"}
                                                        onClick={(e) =>
                                                            handleChange(
                                                                e,
                                                                values,
                                                                setFieldValue,
                                                                submitForm
                                                            )
                                                        }
                                                        disabled={!data.next}
                                                    >
                                                        <ChevronRight
                                                            width={24}
                                                            height={24}
                                                            color={"#3c6ca4"}
                                                        />
                                                    </button>
                                                </div>
                                            </div>

                                            <div className="cards-container">
                                                {data.results.map(
                                                    ({
                                                        id,
                                                        first_name,
                                                        last_name,
                                                        address,
                                                        city,
                                                        state,
                                                        country,
                                                        zip_code,
                                                        image_url,
                                                        npi,
                                                        gender,
                                                        preferred_eval_type,
                                                        clinic,
                                                    }) => {
                                                        return (
                                                            <div
                                                                key={id}
                                                                className="doctor-card"
                                                            >
                                                                <div className="doc-picture-wrapper">
                                                                    <img
                                                                        src={
                                                                            image_url
                                                                                ? image_url
                                                                                : User
                                                                        }
                                                                        alt="Doctor"
                                                                    />
                                                                </div>
                                                                <div className="doctor-name-wrapper">
                                                                    <h6
                                                                        title={`${first_name} ${last_name}`}
                                                                    >
                                                                        {
                                                                            first_name
                                                                        }{" "}
                                                                        {
                                                                            last_name
                                                                        }
                                                                    </h6>
                                                                </div>
                                                                <div className="addr-wrapper">
                                                                    {clinic ? (
                                                                        <>
                                                                            <span
                                                                                className="card-text"
                                                                                title={
                                                                                    clinic.name
                                                                                }
                                                                            >
                                                                                {
                                                                                    clinic.name
                                                                                }
                                                                            </span>
                                                                            <span
                                                                                className="card-text"
                                                                                title={`${
                                                                                    clinic.address
                                                                                }, ${
                                                                                    clinic.city
                                                                                }, ${clinic.state.toUpperCase()}, ${
                                                                                    clinic.zip_code
                                                                                }, ${clinic.country.toUpperCase()}`}
                                                                            >
                                                                                {`${
                                                                                    clinic.address
                                                                                }, ${
                                                                                    clinic.city
                                                                                }, ${clinic.state.toUpperCase()}, ${
                                                                                    clinic.zip_code
                                                                                }, ${clinic.country.toUpperCase()}`}
                                                                            </span>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <span
                                                                                className="card-text"
                                                                                title="Independent"
                                                                            >
                                                                                Independent
                                                                            </span>
                                                                            <span
                                                                                className="card-text"
                                                                                title={`${address}, ${city}, ${state.toUpperCase()}, ${zip_code}, ${country.toUpperCase()}`}
                                                                            >
                                                                                {`${address}, ${city}, ${state.toUpperCase()}, ${zip_code}, ${country.toUpperCase()}`}
                                                                            </span>
                                                                        </>
                                                                    )}
                                                                </div>
                                                                <div className="id-gender-wrapper">
                                                                    <div
                                                                        className="card-text"
                                                                        title={
                                                                            npi
                                                                        }
                                                                    >
                                                                        ID#:{" "}
                                                                        {npi}
                                                                    </div>
                                                                    <div className="card-text">
                                                                        Gender:{" "}
                                                                        {gender}
                                                                    </div>
                                                                    <div
                                                                        className="card-text"
                                                                        title={
                                                                            preferred_eval_type.display_text
                                                                        }
                                                                    >
                                                                        Evaluation
                                                                        Type:{" "}
                                                                        {
                                                                            preferred_eval_type.display_text
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="schedule-btn-container">
                                                                    <button
                                                                        type="button"
                                                                        className="btn primary"
                                                                        onClick={() =>
                                                                            handleClick(
                                                                                {
                                                                                    id,
                                                                                    preferred_eval_type,
                                                                                }
                                                                            )
                                                                        }
                                                                    >
                                                                        Schedule
                                                                        Appointment
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        );
                                                    }
                                                )}
                                            </div>
                                        </>
                                    ) : (
                                        <div className="empty-list-container">
                                            <h6>No Active Doctor Found.</h6>
                                        </div>
                                    )}
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
                <EvaluationTypeModal
                    data={doctor}
                    setDoctor={setDoctor}
                    handleSelectDoctor={handleSelectDoctor}
                />
            </div>
        );
    }
};

export default DoctorList;
