import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Alert from "@mui/material/Alert";
import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import {
    getChecklist,
    deleteChecklistItem,
    resetMethod,
} from "../../features/admin/medHistAdminSlice";
import { toggleShow } from "../../features/modalSlice";
import CreateChecklistItemForm from "../../components/admin/CreateChecklistItemForm";
import AdminConfirmModal from "../../components/admin/AdminConfirmModal";
import AdminLoadingSpinner from "../../components/admin/AdminLoadingSpinner";
import AdminPagination from "../../components/admin/AdminPagination";

const AdminMedHistory = () => {
    const dispatch = useDispatch();
    const modalData = useSelector((state) => state.modal.data);
    const [modalName, SetModalName] = useState(null);

    const { checklist, status, error, method, pagination } = useSelector(
        (state) => state.checklist
    );

    const handleGetChecklist = (filters = {}) => {
        dispatch(getChecklist(filters));
    };

    useEffect(() => {
        handleGetChecklist();
    }, []);

    const openModal = (modalName, id = null, name = null) => {
        SetModalName(modalName);
        dispatch(toggleShow({ id: id, name: name }));
    };

    if (status === "failed") {
        return (
            <Alert severity="error" sx={{ marginBottom: 1 }}>
                {error}
            </Alert>
        );
    } else {
        return (
            <Container maxWidth="">
                <Typography
                    variant="h5"
                    component="div"
                    sx={{ flexGrow: 1, paddingTop: 2, paddingBottom: 2 }}
                >
                    Medical History Checklist
                </Typography>
                {method.status === "succeeded" && (
                    <Alert severity="success" sx={{ marginBottom: 1 }}>
                        {`Checklist Item has been ${
                            method.name === "create" ? "created" : "deleted"
                        } successfully.`}
                    </Alert>
                )}
                {status === "succeeded" ? (
                    <TableContainer component={Paper} sx={{ minHeight: 200 }}>
                        <Stack
                            direction="row"
                            justifyContent={"flex-end"}
                            sx={{ padding: 2 }}
                        >
                            <Button
                                variant="contained"
                                onClick={() => openModal("create")}
                            >
                                Create
                            </Button>
                        </Stack>
                        {checklist.length ? (
                            <Table
                                aria-label="simple table"
                                sx={{ minWidth: 650 }}
                            >
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Document Name</TableCell>

                                        <TableCell>Actions</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {checklist.map((item) => {
                                        const { id, name } = item;
                                        return (
                                            <TableRow
                                                key={id}
                                                sx={{
                                                    "&:last-child td, &:last-child th":
                                                        {
                                                            border: 0,
                                                        },
                                                }}
                                            >
                                                <TableCell
                                                    component="th"
                                                    scope="row"
                                                >
                                                    {name}
                                                </TableCell>
                                                <TableCell>
                                                    <Tooltip title="Delete">
                                                        <IconButton
                                                            onClick={() =>
                                                                openModal(
                                                                    "delete",
                                                                    id,
                                                                    name
                                                                )
                                                            }
                                                        >
                                                            <DeleteIcon color="error" />
                                                        </IconButton>
                                                    </Tooltip>
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        ) : (
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <Typography variant="h6" component="div">
                                    No Checklist item yet.
                                </Typography>
                            </Box>
                        )}
                        {pagination.count > pagination.page_size && (
                            <AdminPagination
                                pageCount={pagination.total_pages}
                                currentPage={pagination.current_page}
                                onPageChange={handleGetChecklist}
                            />
                        )}
                    </TableContainer>
                ) : (
                    <AdminLoadingSpinner />
                )}
                {modalData ? (
                    modalName === "delete" ? (
                        <AdminConfirmModal
                            slice="checklist"
                            title={"Delete Checklist Item"}
                            text={` Are you sure you want to delete "${modalData.name}"?`}
                            data={modalData}
                            clickHandler={deleteChecklistItem}
                            resetMethod={resetMethod}
                            action={"Delete"}
                        />
                    ) : (
                        <CreateChecklistItemForm />
                    )
                ) : null}
            </Container>
        );
    }
};

export default AdminMedHistory;
