import React, { useEffect } from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import FormField from "../../../common/components/form/fields/FormField";
import Alert from "../../../common/components/Alert";
import {
    emailPasswordReset,
    resetStatus,
} from "@/vetvantage/features/authSlice";
import { setCookie, getCookie } from "../../../common/utils/cookies";
import {
    updateSecuritySettings,
    resetStatus as resetSettingsStatus,
    getSecuritySettings,
} from "../../features/settingsSlice";
import useAlert from "../../hooks/useAlert";

const passwordValidationSchema = Yup.object({
    email: Yup.string()
        .email("Invalid email address")
        .required("Email is required"),
});

const securitySettingsValidationSchema = Yup.object({
    loginAlerts: Yup.boolean(),
    twoFactorAuth: Yup.boolean(),
});

const Security = () => {
    const dispatch = useDispatch();

    // Redux State
    const { status: authStatus } = useSelector((state) => state.auth);
    const { status: settingsStatus, securitySettings } = useSelector(
        (state) => state.settings
    );

    // Local State
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();

    useEffect(() => {
        dispatch(resetStatus());
        dispatch(resetSettingsStatus());
        dispatch(getSecuritySettings());
    }, [dispatch]);

    const handleEmailPasswordReset = (values, { setSubmitting }) => {
        dispatch(emailPasswordReset(values))
            .unwrap()
            .then(() => {
                showAlert({
                    alertType: "success",
                    alertMessage:
                        "We have sent you an e-mail. Please contact us if you do not receive it within a few minutes",
                });
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: error
                        ? error
                        : "Error requesting password reset",
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleSubmitSecurity = (values, { setSubmitting }) => {
        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
        formData.append("login_alerts", values.loginAlerts);
        formData.append("two_step", values.twoFactorAuth);
        dispatch(updateSecuritySettings(formData))
            .unwrap()
            .then(() => {
                showAlert({
                    alertType: "success",
                    alertMessage: "Security settings updated successfully",
                });
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: handleError(
                        error,
                        "Error updating security settings"
                    ),
                });
            })
            .finally(() => {
                setSubmitting(false);
            });
    };

    const handleLoginAlertsChange = (setFieldValue, currentValue) => {
        if (!currentValue) {
            const checkLocationPermission = () => {
                navigator.permissions
                    .query({ name: "geolocation" })
                    .then((permissionStatus) => {
                        if (permissionStatus.state === "granted") {
                            // Position permission granted, allow toggling immediately
                            setFieldValue("loginAlerts", true);
                            // Request location in the background
                            requestAndSetLocation(setFieldValue);
                        } else {
                            // Location access not granted, set alert and prompt for permission
                            setFieldValue("loginAlerts", true);
                            alert(
                                "We need your current location to send you login alerts for unrecognized logins. Please allow access to your location when prompted."
                            );
                            requestAndSetLocation(setFieldValue);
                        }
                        // Watch for changes in permission
                        permissionStatus.onchange = () => {
                            if (permissionStatus.state === "granted") {
                                // Location access has been granted
                                setFieldValue("loginAlerts", true);
                                requestAndSetLocation(setFieldValue);
                            } else {
                                // Location access has been revoked
                                setFieldValue("loginAlerts", false);
                                // Clear position cookie
                                setCookie("position", "", -1);
                            }
                        };
                    });
            };

            const positionCookie = getCookie("position");
            if (positionCookie) {
                // Position cookie exists, allow toggling immediately
                setFieldValue("loginAlerts", true);
            } else {
                // No position cookie, check permission and fetch location if allowed
                checkLocationPermission();
            }
        } else {
            // Allow disabling without prompting
            setFieldValue("loginAlerts", false);
            // Clear position cookie
            setCookie("position", "", -1);
        }
    };

    const requestAndSetLocation = (setFieldValue) => {
        navigator.geolocation.getCurrentPosition(
            (position) => {
                // Permission granted, set cookie
                const { latitude, longitude } = position.coords;
                const locationString = `${latitude},${longitude}`;
                setCookie("position", locationString, 30);
                const userTz = Intl.DateTimeFormat().resolvedOptions().timeZone;
                setCookie("user_tz", userTz, 30);
                setFieldValue("loginAlerts", true);
            },
            (error) => {
                // Permission denied or error
                setFieldValue("loginAlerts", false);
                setCookie("position", "", -1);
            },
            {
                enableHighAccuracy: true,
                timeout: 10000,
                maximumAge: 0,
            }
        );
    };

    return (
        <>
            <div className="page-top-container">
                <img src="/static/settings/img/bell-icon-blue.png" alt="bell" />
                <h6 className="page-top-header">Privacy & Security Settings</h6>
            </div>
            {alertVisible && (
                <div className="error-container">
                    <Alert type={alertType} message={alertMessage} />
                </div>
            )}
            <div className="page-middle">
                <div className="content-container">
                    <div className="page-content settings-column">
                        {/* Password Reset Section */}
                        <div className="security-section">
                            <h3 className="settings-title">Change Password</h3>
                            <Formik
                                initialValues={{
                                    email: "",
                                }}
                                validationSchema={passwordValidationSchema}
                                onSubmit={handleEmailPasswordReset}
                            >
                                {({ isSubmitting, dirty, isValid }) => (
                                    <Form id="pwd-reset-form">
                                        <div className="pwd-reset-form-row">
                                            <FormField
                                                label="Email"
                                                name="email"
                                                type="email"
                                                id="email"
                                                placeholder="Email Address"
                                                disabled={isSubmitting}
                                            />
                                        </div>
                                        <div className="page-btn-container">
                                            <button
                                                type="submit"
                                                className="btn primary pwd-reset-btn"
                                                disabled={
                                                    authStatus === "pending" ||
                                                    isSubmitting ||
                                                    !dirty ||
                                                    !isValid
                                                }
                                            >
                                                {isSubmitting ||
                                                authStatus === "pending" ? (
                                                    <div className="loader"></div>
                                                ) : (
                                                    "Request Password Reset"
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>

                        {/* Security Settings Section */}
                        <div className="security-section">
                            <h3 className="settings-title">
                                Security Settings
                            </h3>

                            <Formik
                                validationSchema={
                                    securitySettingsValidationSchema
                                }
                                initialValues={{
                                    loginAlerts:
                                        securitySettings?.login_alerts ?? false,
                                    twoFactorAuth:
                                        securitySettings?.two_step ?? false,
                                }}
                                enableReinitialize
                                validateOnMount={true}
                                onSubmit={handleSubmitSecurity}
                            >
                                {({
                                    values,
                                    setFieldValue,
                                    isSubmitting,
                                    dirty,
                                    isValid,
                                }) => (
                                    <Form id="security-form">
                                        <div className="switch-row">
                                            <label htmlFor="loginAlertsCheckbox">
                                                Login Alerts
                                            </label>
                                            <label className="switch">
                                                <input
                                                    id="loginAlertsCheckbox"
                                                    name="loginAlerts"
                                                    type="checkbox"
                                                    checked={values.loginAlerts}
                                                    onChange={() =>
                                                        handleLoginAlertsChange(
                                                            setFieldValue,
                                                            values.loginAlerts
                                                        )
                                                    }
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="switch-row">
                                            <label htmlFor="twoFactorAuthCheckbox">
                                                Two Factor Authentication
                                            </label>
                                            <label className="switch">
                                                <input
                                                    id="twoFactorAuthCheckbox"
                                                    name="twoFactorAuth"
                                                    type="checkbox"
                                                    checked={
                                                        values.twoFactorAuth
                                                    }
                                                    onChange={() =>
                                                        setFieldValue(
                                                            "twoFactorAuth",
                                                            !values.twoFactorAuth
                                                        )
                                                    }
                                                />
                                                <span className="slider round"></span>
                                            </label>
                                        </div>
                                        <div className="page-btn-container-settings my-2">
                                            <button
                                                type="submit"
                                                className="btn primary"
                                                disabled={
                                                    settingsStatus ===
                                                        "loading" ||
                                                    isSubmitting ||
                                                    !dirty ||
                                                    !isValid
                                                }
                                            >
                                                {isSubmitting ||
                                                settingsStatus === "loading" ? (
                                                    <div className="loader"></div>
                                                ) : (
                                                    <h6 className="btn-text">
                                                        Apply
                                                    </h6>
                                                )}
                                            </button>
                                        </div>
                                    </Form>
                                )}
                            </Formik>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Security;
