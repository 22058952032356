import { axiosInstance } from "@/common/utils/axios";

export const formatDateTime = (dateTimeString) => {
    const date = new Date(dateTimeString);

    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");

    let hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    const formattedTime = `${hours}:${minutes} ${ampm}`;

    const formattedDate = `${month}/${day}`;
    const formattedDateTime = `${formattedDate} at ${formattedTime}`;

    return formattedDateTime;
};

export const endTelehealthAppointment = async (appt_id, transcript) => {
    try {
        const response = await axiosInstance.post(
            `/patients/telehealth/${appt_id}/end`,
            {
                transcript,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        return await response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            throw new Error(`HTTP error Status: ${response.status}`);
        } else {
            console.error("Failed to end the telehealth meeting");
            throw error;
        }
    }
};

export const summarizeTelehealthAppointment = async (appt_id, transcript) => {
    try {
        const response = await axiosInstance.post(
            `/patients/telehealth/${appt_id}/summary`,
            {
                transcript,
            },
            {
                headers: {
                    "Content-Type": "application/json",
                },
            }
        );
        const data = await response.data;
    } catch (error) {
        if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            throw new Error(`HTTP error Status: ${response.status}`);
        } else {
            console.error("Failed to end the telehealth meeting");
            throw error;
        }
    }
};
