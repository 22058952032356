import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
    getRefundTickets,
    resetMethod,
    createRefund,
    REFUND_TICKET_STATUS_CHOICES,
} from "../../features/refundTicketsSlice";
import { toggleShow } from "../../features/modalSlice";
import { formatDate } from "../../utils";
import Alert from "../../../common/components/Alert";
import ConfirmModal from "../../components/confirm_modal/ConfirmModal";
import CustomNavLink from "../../components/custom_nav_link/CustomNavLink";
import "./RefundTickets.scss";
import Pagination from "@/common/components/Pagination/Pagination";

const Refunds = () => {
    const dispatch = useDispatch();
    const [statusFilter, setStatusFilter] = useState("");

    const { tickets, status, error, method, pagination } = useSelector(
        (state) => state.refundTickets
    );

    const modalData = useSelector((state) => state.modal.data);

    const isLoading = useMemo(
        () => status == "pending" || status == "idle",
        [status]
    );

    useEffect(() => {
        dispatch(getRefundTickets({ status: "" }));
    }, []);

    const openModal = (id, name) => {
        dispatch(toggleShow({ id: id, name: name }));
    };

    const handleStatusChange = (e) => {
        setStatusFilter(e.target.value);
        dispatch(getRefundTickets({ status: e.target.value }));
    };

    if (status === "failed") {
        return <Alert message={error} type={"error"} />;
    } else {
        return (
            <div id="refund-tickets">
                <div className="page-title-container">
                    <h3>Refund Tickets</h3>
                    <CustomNavLink
                        link={"/products/refunds/history/"}
                        text={"History"}
                    />
                </div>
                {method.status === "succeeded" && (
                    <Alert
                        message={
                            "Your refund request has been successfully submitted. We will notify you once the process is complete."
                        }
                        type={"success"}
                    />
                )}
                <div className="card table-container">
                    <div className="table-wrapper scrollable">
                        {isLoading ? (
                            <div className="loading-spinner-container">
                                <div
                                    style={{
                                        height: "80px",
                                        width: "80px",
                                        borderWidth: "8px",
                                    }}
                                    className="loader"
                                ></div>
                            </div>
                        ) : (
                            <>
                                {/* TODO: Add create refund ticket logic if needed, otherwise remove this div */}
                                <div className="create-refund-container">
                                    <div className="filter-container">
                                        <label htmlFor="statusFilter">
                                            Filter by Status:
                                        </label>
                                        <select
                                            id="statusFilter"
                                            value={statusFilter}
                                            onChange={handleStatusChange}
                                        >
                                            <option value="">All</option>
                                            {REFUND_TICKET_STATUS_CHOICES.map(
                                                (choice) => (
                                                    <option
                                                        key={choice.label}
                                                        value={choice.label}
                                                    >
                                                        {choice.label}
                                                    </option>
                                                )
                                            )}
                                        </select>
                                    </div>
                                    <button
                                        type="button"
                                        className="btn primary"
                                        onClick={() =>
                                            alert(
                                                "TODO: Create 'New Refund Ticket' modal."
                                            )
                                        }
                                    >
                                        New Ticket
                                    </button>
                                </div>
                                <table>
                                    <thead>
                                        <tr className="table-header">
                                            <th>ID</th>
                                            <th>Date</th>
                                            <th>Status</th>
                                            <th>Product</th>
                                            <th>Amount</th>
                                            <th>Actions</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tickets.length > 0 ? (
                                            tickets.map(
                                                ({
                                                    id,
                                                    order_item,
                                                    amount,
                                                    status,
                                                    timestamp,
                                                }) => {
                                                    return (
                                                        <tr key={id}>
                                                            <td className="text-el">
                                                                {id}
                                                            </td>
                                                            <td className="text-el">
                                                                {formatDate(
                                                                    timestamp
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="btn-wrapper">
                                                                    <div className="btn outline">
                                                                        {
                                                                            status.display_text
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </td>
                                                            <td className="text-el">
                                                                <div>
                                                                    {
                                                                        order_item
                                                                            .product
                                                                            .name
                                                                    }
                                                                </div>
                                                            </td>
                                                            <td className="text-el">
                                                                $
                                                                {parseFloat(
                                                                    amount
                                                                ).toFixed(2)}
                                                            </td>
                                                            <td>
                                                                <div className="btn-wrapper">
                                                                    {status.value ===
                                                                        1 && (
                                                                        <button
                                                                            type="button"
                                                                            className="btn primary"
                                                                            onClick={() =>
                                                                                openModal(
                                                                                    id,
                                                                                    order_item
                                                                                        .product
                                                                                        .name
                                                                                )
                                                                            }
                                                                        >
                                                                            Refund
                                                                        </button>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                }
                                            )
                                        ) : (
                                            <tr>
                                                <td
                                                    colSpan="6"
                                                    className="text-center"
                                                >
                                                    You do not have any Refund
                                                    Ticket yet.
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </>
                        )}
                    </div>

                    {pagination?.refund_tickets?.length > 0 &&
                        pagination?.current_page &&
                        pagination?.total_pages &&
                        pagination?.page_size && (
                            <Pagination
                                currentPage={pagination?.current_page ?? 1}
                                total_pages={pagination?.total_pages ?? 1}
                                itemsPerPage={pagination?.page_size ?? 10}
                                // change current page
                                onPageChange={(page) => {
                                    dispatch(
                                        getRefundTickets({
                                            status: statusFilter,
                                            page: page,
                                            page_size:
                                                pagination?.page_size ?? 10,
                                        })
                                    );
                                }}
                                // change page size
                                onItemsPerPageChange={(value) => {
                                    dispatch(
                                        getRefundTickets({
                                            status: statusFilter,
                                            page_size: value,
                                            page: 1, // reset page number
                                        })
                                    );
                                }}
                            />
                        )}
                </div>

                {modalData ? (
                    <ConfirmModal
                        slice="refundTickets"
                        title={"Refund Product Amount"}
                        text={`Are you sure you want to refund ${modalData.name} amount?`}
                        data={modalData}
                        clickHandler={createRefund}
                        resetMethod={resetMethod}
                        action={"Refund"}
                    />
                ) : null}
            </div>
        );
    }
};

export default Refunds;
