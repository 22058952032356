import React from "react";

const Exit = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
        <g fill="none" fillRule="evenodd">
            <path d="M0 0h24v24H0z" />
            <path
                stroke={props.color ?? "#fff"}
                strokeLinecap="round"
                strokeWidth={2}
                d="M9 12h10M16 8l2.586 2.586a2 2 0 0 1 0 2.828L16 16"
            />
            <path
                stroke={props.color ?? "#fff"}
                strokeLinecap="round"
                strokeWidth={2}
                d="M16 4H6a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h10"
            />
        </g>
    </svg>
);

export default Exit;
