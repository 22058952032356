import React, { useState, useEffect } from "react";
import AppointmentContext from "@/telehealth/context/appointment-context";
import TelehealthContent from "@/telehealth/components/TelehealthContent";
import Spinner from "@/vetvantage/components/Spinner";

const TelehealthAppointment = () => {
    const [telehealthData, setTelehealthData] = useState(null);

    useEffect(() => {
        const summaryContext = window.contextData?.telehealthSummary;
        const scriptTag = document.querySelector("script[data-zoomjwt]");

        if (scriptTag) {
            setTelehealthData({
                zoom_jwt: scriptTag.getAttribute("data-zoomJwt"),
                username: scriptTag.getAttribute("data-username"),
                appointment: JSON.parse(
                    scriptTag.getAttribute("data-appointment")
                ),
                tekmed_url: scriptTag.getAttribute("data-tekmed_url"),
            });
        } else if (summaryContext) {
            setTelehealthData({
                is_summary: !!summaryContext,
                platform: summaryContext?.platform,
                itfComplete: !!summaryContext?.itfComplete,
                appointment: JSON.parse(summaryContext?.appointment),
            });
        }
    }, []);

    if (!telehealthData) {
        return <Spinner />;
    }

    const {
        zoom_jwt,
        username,
        appointment,
        tekmed_url,
        is_summary,
        platform,
        itfComplete,
    } = telehealthData;
    return (
        <AppointmentContext.Provider value={appointment}>
            <TelehealthContent
                zoomjwt={zoom_jwt}
                username={username}
                is_summary={is_summary}
                platform={platform || ""}
                itfComplete={itfComplete || false}
                tekmedlink={tekmed_url || ""}
            />
        </AppointmentContext.Provider>
    );
};

export default TelehealthAppointment;
