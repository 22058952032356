import React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";

const AdminPagination = ({
    pageCount,
    currentPage,
    onPageChange,
    filters = {},
}) => {
    const handleChange = (event, value) => {
        onPageChange({ ...filters, page: value });
    };
    return (
        <Stack
            spacing={2}
            direction={"row"}
            justifyContent={"center"}
            sx={{ padding: 2 }}
        >
            <Pagination
                count={pageCount}
                color="primary"
                onChange={handleChange}
                page={currentPage}
            />
        </Stack>
    );
};

export default AdminPagination;
