import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@/common/components/Alert";
import { toggleShow } from "../../features/modalSlice";
import Modal from "../Modal";
import CloseModalBtn from "../CloseModalBtn";
import "./ConfirmModal.scss";

const ConfirmModal = ({
    slice,
    title,
    text,
    data,
    clickHandler,
    resetMethod,
    action,
}) => {
    const dispatch = useDispatch();

    const { status, error } = useSelector((state) => state[slice].method);

    useEffect(() => {
        dispatch(resetMethod());
    }, []);

    const handleClick = () => {
        return new Promise((resolve, _) => {
            dispatch(clickHandler(data.id)).then((res) => {
                if (!res.error) {
                    closeModal();
                }
                resolve();
            });
        });
    };

    const closeModal = () => {
        dispatch(toggleShow());
    };

    return (
        <Modal
            renderContent={({ ref }) => (
                <div id="confirm-modal" className="modal-content" ref={ref}>
                    <CloseModalBtn onClose={closeModal} />
                    {status === "pending" ? (
                        <div className="loading-spinner-container">
                            <div
                                style={{
                                    height: "80px",
                                    width: "80px",
                                    borderWidth: "8px",
                                }}
                                className="loader"
                            ></div>
                        </div>
                    ) : (
                        <>
                            {status === "failed" && (
                                <Alert message={error} type={"danger"} />
                            )}
                            <div className="title-container">
                                <h5>{title}</h5>
                            </div>
                            <div className="modal-body">
                                <h6>{text}</h6>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn outline"
                                    onClick={() => dispatch(toggleShow())}
                                >
                                    Cancel
                                </button>
                                <button
                                    type="button"
                                    className="btn primary"
                                    onClick={handleClick}
                                    disabled={status === "pending"}
                                >
                                    {action}
                                </button>
                            </div>
                        </>
                    )}
                </div>
            )}
        />
    );
};

export default ConfirmModal;
