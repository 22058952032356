import React, { useState } from "react";
import { Link, NavLink, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

import LogoWhite from "../images/logo_white.svg";
import LogoAlt from "../images/logo_alt.svg";
import Gear from "@/common/images/icons/gear.svg";
import Bell from "@/common/images/icons/bell.svg";
import Dashboard from "@/common/images/icons/dashboard.svg";
import Profile from "@/common/images/icons/profile.svg";
import Cart from "@/common/images/icons/cart.svg";
import Clipboard from "@/common/images/icons/clipboard.svg";
import Exit from "@/common/images/icons/exit.svg";
import Menu from "@/common/images/icons/menu.svg";
import DropdownButton from "@/common/components/DropdownButton";
import Download from "@/common/images/icons/download.svg";
import Chevron from "@/common/images/icons/chevron.svg";
import Help from "@/common/images/icons/help.svg";
import ProgressCircle from "./progress_circle/ProgressCircle";
import { settingsSidebarItems } from "../pages/settings/SettingsSidebar";
import { getPageTitle } from "../utils/navbarTitle";
import IconComponent from "./IconComponent";
import "./navbar.scss";

const Navbar = () => {
    const user = useSelector((state) => state.user.user);
    const { progress, steps } = useSelector((state) => state.progress);
    const location = useLocation();
    const [isNestedMenuOpen, setIsNestedMenuOpen] = useState(false);

    const toggleNestedMenu = () => {
        setIsNestedMenuOpen(!isNestedMenuOpen);
    };

    return (
        <nav id="navbar">
            <DropdownButton
                buttonElement={
                    <button id="mobile-menu-btn" type="button">
                        <Menu height={32} width={32} />
                    </button>
                }
                dropdownContent={
                    <div className="mobile-menu">
                        <div id="progress-circle-mobile-menu">
                            <ProgressCircle
                                valueStart={0}
                                valueEnd={progress}
                            />
                        </div>
                        <div>
                            <button
                                className={`mobile-menu-item nested-menu-btn ${
                                    isNestedMenuOpen ? "open" : ""
                                }`}
                                type="button"
                                onClick={toggleNestedMenu}
                            >
                                <div className="mobile-menu-item-content">
                                    <Dashboard height={24} width={24} />
                                    <span>Dashboard</span>
                                    <Chevron
                                        height={24}
                                        width={24}
                                        className="chevron"
                                    />
                                </div>
                            </button>
                            {isNestedMenuOpen && (
                                <div className="nested-menu">
                                    {steps?.length > 0 &&
                                        steps.map((step, i) =>
                                            step.react ? (
                                                <Link
                                                    key={step.id}
                                                    to={step.path}
                                                    className={`nested-item ${step.status}`}
                                                >
                                                    <IconComponent
                                                        iconKey={step.id}
                                                        iconSize={18}
                                                    />
                                                    <span>{step.name}</span>
                                                </Link>
                                            ) : (
                                                <a
                                                    key={step.id}
                                                    href={step.path}
                                                    className={`nested-item ${step.status}`}
                                                    onClick={(e) =>
                                                        step.status ===
                                                            "locked" &&
                                                        e.preventDefault()
                                                    }
                                                >
                                                    <IconComponent
                                                        iconKey={step.id}
                                                        iconSize={18}
                                                    />
                                                    <span>{step.name}</span>
                                                </a>
                                            )
                                        )}
                                </div>
                            )}
                        </div>
                        <Link
                            to="/settings/manage/account/"
                            className="mobile-menu-item"
                        >
                            <div className="mobile-menu-item-content">
                                <Gear height={24} width={24} />
                                <span>Settings</span>
                                <Chevron height={24} width={24} />
                            </div>
                        </Link>
                        <Link to="/notifications/" className="mobile-menu-item">
                            <div className="mobile-menu-item-content">
                                <Bell height={24} width={24} />
                                <span>Notifications</span>
                                <Chevron height={24} width={24} />
                            </div>
                        </Link>
                        <Link
                            to="/products/checkout/"
                            className="mobile-menu-item"
                        >
                            <div className="mobile-menu-item-content">
                                <Cart height={24} width={24} />
                                <span>Cart</span>
                                <Chevron height={24} width={24} />
                            </div>
                        </Link>
                        <Link
                            to="/products/orders/"
                            className="mobile-menu-item"
                        >
                            <div className="mobile-menu-item-content">
                                <Clipboard height={24} width={24} />
                                <span>Orders</span>
                                <Chevron height={24} width={24} />
                            </div>
                        </Link>
                        <Link to="/downloads/" className="mobile-menu-item">
                            <div className="mobile-menu-item-content">
                                <Download height={24} width={24} />
                                <span>Downloads</span>
                                <Chevron height={24} width={24} />
                            </div>
                        </Link>
                        <Link
                            to="/settings/manage/support"
                            className="mobile-menu-item"
                        >
                            <div className="mobile-menu-item-content">
                                <Help height={24} width={24} />
                                <span>FAQ</span>
                                <Chevron height={24} width={24} />
                            </div>
                        </Link>
                        <Link
                            to="/accounts/logout/"
                            className="mobile-menu-item"
                        >
                            <div className="mobile-menu-item-content">
                                <Exit height={24} width={24} />
                                <span>Logout</span>
                                <Chevron height={24} width={24} />
                            </div>
                        </Link>
                    </div>
                }
            />
            <div className="navbar-brand">
                <Link to="/dashboard/">
                    <LogoWhite className="logo-full" />
                    <LogoAlt className="logo-alt" />
                </Link>
            </div>
            {getPageTitle(location.pathname) && (
                <h2 className="navbar-title">
                    {getPageTitle(location.pathname)}
                </h2>
            )}

            <div className="navbar-menu">
                <NavLink to="/dashboard/" className="navbar-menu-item">
                    <Dashboard height={24} width={24} />
                    <span>Dashboard</span>
                </NavLink>
                <NavLink to="/products/orders" className="navbar-menu-item">
                    <Clipboard height={24} width={24} />
                    <span>Orders</span>
                </NavLink>
                <NavLink to="/downloads/" className="navbar-menu-item">
                    <Download height={24} width={24} />
                    <span>Downloads</span>
                </NavLink>
                <NavLink
                    to="/settings/manage/account/"
                    className="navbar-menu-item"
                >
                    <Gear height={24} width={24} />
                    <span>Settings</span>
                </NavLink>
            </div>
            <div className="navbar-end">
                {/* TODO: Move to seperate component and add cart context */}
                <button id="cart-btn" type="button">
                    <Link to="/products/checkout/" className="navbar-menu-item">
                        <Cart height={24} width={24} />
                    </Link>
                    {user?.cart_items_count ? (
                        <div className="small-badge">
                            <span id="notif-counter-el">
                                {user.cart_items_count}
                            </span>
                        </div>
                    ) : null}
                </button>
                <button id="notifications-btn" type="button">
                    <Link to="/notifications/" className="navbar-menu-item">
                        <Bell height={24} width={24}></Bell>
                    </Link>
                    {user?.notification_count ? (
                        <div className="small-badge">
                            <span id="notif-counter-el">
                                {user.notification_count}
                            </span>
                        </div>
                    ) : null}
                </button>
                <DropdownButton
                    buttonElement={
                        <button
                            id={
                                // Hide profile menu button in settings page
                                location.pathname.includes("settings")
                                    ? "hide-profile-menu-btn"
                                    : "profile-menu-btn"
                            }
                            className="navbar-user"
                            type="button"
                        >
                            <div className="profile-pic-wrapper">
                                {user?.profile_pic_url ? (
                                    <img
                                        src={user.profile_pic_url}
                                        alt={`${user.full_name} profile picture`}
                                    />
                                ) : (
                                    <Profile
                                        width={18}
                                        height={18}
                                        className="default-profile-pic"
                                    />
                                )}
                            </div>
                        </button>
                    }
                    dropdownContent={
                        <div className="profile-menu">
                            <div className="profile-header">
                                <Profile
                                    width={48}
                                    height={48}
                                    className="profile-icon"
                                />
                                <div className="profile-info">
                                    <span className="profile-name">
                                        {user?.full_name}
                                    </span>
                                    <span className="profile-email">
                                        {user?.email}
                                    </span>
                                </div>
                            </div>
                            <Link
                                to="/settings/manage/account"
                                className="profile-menu-item"
                            >
                                <Gear height={24} width={24} />
                                <span>Settings</span>
                            </Link>
                            <Link
                                to="/notifications/"
                                className="profile-menu-item"
                            >
                                <Bell height={24} width={24} />
                                <span>Notifications</span>
                            </Link>
                            <Link
                                to="/products/checkout/"
                                className="profile-menu-item"
                            >
                                <Cart height={24} width={24} />
                                <span>Cart</span>
                            </Link>
                            <Link
                                to="/products/orders"
                                className="profile-menu-item"
                            >
                                <Clipboard height={24} width={24} />
                                <span>Orders</span>
                            </Link>
                            <Link
                                to="/accounts/logout"
                                className="profile-menu-item"
                            >
                                <Exit height={24} width={24} />
                                <span>Logout</span>
                            </Link>
                        </div>
                    }
                />

                {location.pathname.includes("settings") && (
                    <DropdownButton
                        buttonElement={
                            <button id="mobile-menu-btn" type="button">
                                <Gear height={24} width={24} />
                            </button>
                        }
                        dropdownContent={
                            <div className="mobile-menu">
                                {/* Reusing SettingsSidebar items */}
                                {settingsSidebarItems.map((item) => (
                                    <NavLink
                                        to={
                                            item.isSetting
                                                ? `/settings/manage/${item.path}`
                                                : item.path
                                        }
                                        key={item.path}
                                        className={({ isActive }) =>
                                            `mobile-menu-item ${
                                                isActive ? "active" : ""
                                            }`
                                        }
                                    >
                                        {({ isActive }) => (
                                            <div className="mobile-menu-item-content">
                                                {item.svg ? (
                                                    item.svg(isActive)
                                                ) : (
                                                    <img
                                                        src={`/static/settings/img/${
                                                            item.icon
                                                        }-${
                                                            isActive
                                                                ? "red"
                                                                : "grey"
                                                        }.png`}
                                                        alt={item.icon}
                                                    />
                                                )}
                                                <span>{item.label}</span>
                                                {item.showArrow && (
                                                    <Chevron
                                                        height={24}
                                                        width={24}
                                                        style={
                                                            isActive
                                                                ? {
                                                                      color: "#9a1f23",
                                                                  }
                                                                : {
                                                                      color: "#6c6c6c",
                                                                  }
                                                        }
                                                    />
                                                )}
                                            </div>
                                        )}
                                    </NavLink>
                                ))}
                            </div>
                        }
                    />
                )}
            </div>
        </nav>
    );
};

export default Navbar;
