// TODO: Delete this file when testing is done.
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Alert from "@/common/components/Alert";
import SummarizationTestTableRow from "../../components/SummarizationTestTableRow";
import UploadTestFileModal from "../../components/UploadTestFileModal";
import ConfirmModal from "../../components/confirm_modal/ConfirmModal";
import "../../common/styles/table_container.scss";
import { toggleShow } from "../../features/modalSlice";
import {
    getMedicalHistoryTest,
    resetMethod,
    deleteMedicalHistoryTest,
} from "../../features/medicalHistoryTestSlice";
import "./SummarizationTest.scss";

const SummarizationTest = () => {
    const modalData = useSelector((state) => state.modal.data);
    const [modalName, setModalName] = useState(null);
    const { medical_history_test, status, error, method } = useSelector(
        (state) => state.medicalHistoryTest
    );

    const dispatch = useDispatch();

    const openModal = (modalName, id, name) => {
        setModalName(modalName);
        dispatch(toggleShow({ id: id, name: name }));
    };

    useEffect(() => {
        dispatch(getMedicalHistoryTest());
    }, []);

    return (
        <div id="summarizationTest">
            {status === "failed" ? (
                <Alert message={error} type={"error"} />
            ) : (
                <>
                    <div className="page-container">
                        <div className="page-title-container">
                            <h3>Summarization Test App</h3>
                        </div>
                        {method.status === "succeeded" && (
                            <Alert
                                message={`Document has been ${
                                    method.name === "upload"
                                        ? "uploaded"
                                        : "deleted"
                                } successfully.`}
                                type={"success"}
                            />
                        )}
                        <div className="card table-container">
                            <div className="table-wrapper scrollable">
                                {status === "succeeded" ? (
                                    <table>
                                        <thead>
                                            <tr className="table-header">
                                                <th>ID</th>
                                                <th>Name</th>
                                                <th>Document</th>
                                                <th>Summary</th>
                                                <th>Date Uploaded</th>
                                                <th>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {medical_history_test.length
                                                ? medical_history_test.map(
                                                      ({
                                                          id,
                                                          file_name,
                                                          file_url,
                                                          summarization_status,
                                                          summary_file_url,
                                                          timestamp,
                                                      }) => {
                                                          return (
                                                              <SummarizationTestTableRow
                                                                  key={id}
                                                                  id={id}
                                                                  fileName={
                                                                      file_name
                                                                  }
                                                                  fileUrl={
                                                                      file_url
                                                                  }
                                                                  SummarizationStatus={
                                                                      summarization_status
                                                                  }
                                                                  SummaryFileUrl={
                                                                      summary_file_url
                                                                  }
                                                                  timestamp={
                                                                      timestamp
                                                                  }
                                                                  modalHandler={
                                                                      openModal
                                                                  }
                                                              />
                                                          );
                                                      }
                                                  )
                                                : null}
                                            {medical_history_test.length <
                                                5 && (
                                                <SummarizationTestTableRow
                                                    key={1}
                                                    id={null}
                                                    fileName={null}
                                                    SummarizationStatus={null}
                                                    SummaryFileUrl={null}
                                                    timestamp={null}
                                                    modalHandler={openModal}
                                                />
                                            )}
                                        </tbody>
                                    </table>
                                ) : (
                                    <div className="loading-spinner-container">
                                        <div
                                            style={{
                                                height: "80px",
                                                width: "80px",
                                                borderWidth: "8px",
                                            }}
                                            className="loader"
                                        ></div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    {modalData ? (
                        modalName === "upload" ? (
                            <UploadTestFileModal />
                        ) : (
                            <ConfirmModal
                                slice="medicalHistoryTest"
                                title={"Delete Document"}
                                text={`Are you sure you want to delete "${modalData.name}" ?`}
                                data={modalData}
                                clickHandler={deleteMedicalHistoryTest}
                                resetMethod={resetMethod}
                                action={"Delete"}
                            />
                        )
                    ) : null}
                </>
            )}
        </div>
    );
};

export default SummarizationTest;
