import React from "react";
import GoBack from "@/common/components/GoBack";
import "./HowToSubmitITF.scss";

const HowToSubmitITF = ({ onClose }) => {
    return (
        <div id="how-to-submit-itf">
            <GoBack clickHandler={onClose} />
            <div className="content-container">
                <h2 className="text-center">How to Submit Intent To File</h2>
                <h6 className="bold">
                    Please note: you must submit your Intent to File to receive
                    backpay.
                </h6>
                <p>
                    We recommend submitting your Intent To File as soon as
                    possible. You can do so either online or by calling 1 (800)
                    827-1000.
                </p>
                <p>
                    By submitting an Intent To File, you will have the
                    opportunity to receive back pay starting from today’s date
                    until the VA awards you a favorable decision.
                    <span className="red-text">
                        Once you see the expiration date for your Intent to
                        File, please STOP and DO NOT continue.
                    </span>
                </p>
                <ul>
                    <li>
                        On the VA.gov homepage, navigate to the "Disability"
                        square. Click the link that says "File a claim for
                        Compensation.”
                    </li>
                    <li>
                        Answer the questions on the page, then click the “File a
                        claim online” button.
                    </li>
                    <li>
                        On the next page, click the “Start Compensation
                        application” button.
                    </li>
                    <li>
                        After completing the application, you will see a
                        confirmation of your Intent to File. Take a screenshot
                        and send it to firsttimefiler@gmail.com
                    </li>
                </ul>
                <button className="btn primary" onClick={onClose}>
                    Continue
                </button>
            </div>
        </div>
    );
};

export default HowToSubmitITF;
