import React from "react";

const CompletePay = ({ totalAmount, handlePayClick }) => {
    return (
        <div className="content-container">
            <div className="page-title-container">
                <h3 id="total-amount-el" className="page-title">
                    ${totalAmount.toFixed(2)}
                </h3>
            </div>
            <div className="payment-subtitle-container">
                <h5>Your Projected Payment Amount</h5>
            </div>
            <div className="cart-info-container">
                <span>Based on products you’ve selected.</span>
            </div>

            <div className="btn-container">
                <button
                    type="button"
                    className="btn primary"
                    onClick={() => handlePayClick({ card_payment: "" })}
                >
                    Make Payment
                </button>
                {/* Might bring this back in the future if we wish to take a check
                    {totalAmount > 0 && (
                    <button
                        type="button"
                        className="btn primary"
                        onClick={() => handlePayClick({ check_payment: "" })}
                    >
                        Pay by Check
                    </button>
                )} */}
            </div>
        </div>
    );
};

export default CompletePay;
