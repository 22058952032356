import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { getDownloads, getDBQData } from "../../features/downloadSlice";
import Alert from "../../../common/components/Alert";
import "@/vetvantage/common/styles/table_container.scss";
import "./Downloads.scss";
import useAlert from "../../hooks/useAlert";
import { downloadDBQs } from "../../utils/generate_itf";
import { replaceSpecialChars } from "../../../common/utils/helpers";

const Downloads = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { downloads, status, error } = useSelector(
        (state) => state.downloads
    );
    const { alertType, alertMessage, alertVisible, showAlert } = useAlert();
    const [downloadLoading, setDownloadLoading] = useState(null);
    const [tooltipPosition, setTooltipPosition] = useState({ top: 0, left: 0 });
    const [activeTooltip, setActiveTooltip] = useState(null);
    const tooltipRef = useRef(null);

    useEffect(() => {
        dispatch(getDownloads());
    }, [dispatch]);

    const handleDownload = async (id) => {
        try {
            setDownloadLoading(id);
            const response = await dispatch(getDBQData({ id })).unwrap();
            if (response.data) {
                const paqName = response.data.info.paq.name;
                const fileUrl = response.data.info.paq.fileUrl;
                const dataJSON =
                    response.data.results_data &&
                    replaceSpecialChars(
                        JSON.stringify(response.data.results_data)
                    );

                // Reuse downloadPDF function from claims
                const success = await downloadDBQs({
                    pdfUrl: fileUrl,
                    data: dataJSON,
                    paqName,
                });

                if (success) {
                    showAlert({
                        alertType: "info",
                        alertMessage:
                            "DBQ Document has been downloaded successfully.",
                    });
                }
            }
        } catch (error) {
            if (error.status === 403) {
                // Redirect to checkout page if product is not paid
                navigate("/products/checkout");
            } else {
                showAlert(
                    "error",
                    error.message || "Something went wrong during the download."
                );
            }
            setDownloadLoading(null);
        } finally {
            setDownloadLoading(null);
        }
    };

    const handleTooltipMouseEnter = (event, id) => {
        const rect = event.currentTarget.getBoundingClientRect();
        setTooltipPosition({
            top: rect.top + window.scrollY - 10, // 10px above the icon
            left: rect.left + window.scrollX + rect.width / 2,
        });
        setActiveTooltip(id);
    };

    const handleTooltipMouseLeave = () => {
        setActiveTooltip(null);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (activeTooltip !== null) {
                setActiveTooltip(null);
            }
        };

        window.addEventListener("scroll", handleScroll);
        return () => window.removeEventListener("scroll", handleScroll);
    }, [activeTooltip]);
    return (
        <div id="downloads">
            <Link to="/downloads/transcripts" className="transcripts-link">
                Transcripts
            </Link>
            <div className="page-title-container">
                {alertVisible && (
                    <Alert type={alertType} message={alertMessage} />
                )}
                <h3>Download DBQs</h3>
                {status === "failed" && (
                    <Alert message={error} type={"error"} />
                )}
            </div>
            <div className="card table-container">
                <div className="table-wrapper scrollable">
                    <h5>List of DBQs</h5>
                    <table>
                        <thead>
                            <tr className="table-header">
                                <th>Name</th>
                                <th>Timestamp</th>
                                <th>Review Status</th>
                                <th>Download DBQ</th>
                            </tr>
                        </thead>
                        <tbody>
                            {status === "pending" ? (
                                <tr>
                                    <td
                                        className="border-bottom-none"
                                        colSpan="4"
                                    >
                                        <div className="loading-wrapper">
                                            <div className="loader"></div>
                                        </div>
                                    </td>
                                </tr>
                            ) : downloads?.dbqs?.length > 0 ? (
                                downloads.dbqs.map((dbq) => (
                                    <tr key={dbq.id}>
                                        <td>{dbq.name}</td>
                                        <td>{dbq.timestamp}</td>
                                        <td>{dbq.review_status}</td>
                                        <td>
                                            <div className="btn-wrapper download-btn-wrapper">
                                                {dbq.is_paid ? (
                                                    <button
                                                        type="button"
                                                        className={`download-btn`}
                                                        onClick={() =>
                                                            handleDownload(
                                                                dbq.id
                                                            )
                                                        }
                                                        disabled={
                                                            dbq.review_status !==
                                                            "reviewed"
                                                        }
                                                    >
                                                        {downloadLoading ===
                                                        dbq.id ? (
                                                            <div className="loader"></div>
                                                        ) : (
                                                            <img
                                                                src="/static/downloads/img/download.svg"
                                                                alt="Download"
                                                            />
                                                        )}
                                                    </button>
                                                ) : (
                                                    <div className="tooltip-container">
                                                        <a
                                                            href="/products/checkout"
                                                            className="cart-btn"
                                                            onMouseEnter={(e) =>
                                                                handleTooltipMouseEnter(
                                                                    e,
                                                                    dbq.id
                                                                )
                                                            }
                                                            onMouseLeave={
                                                                handleTooltipMouseLeave
                                                            }
                                                        >
                                                            <img
                                                                src="/static/downloads/img/cart-red.svg"
                                                                alt="Cart"
                                                            />
                                                        </a>
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr className="no-available-dbqs">
                                    <td
                                        colSpan="4"
                                        className="border-bottom-none text-gray"
                                    >
                                        No DBQs available
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
            {activeTooltip !== null && (
                <div
                    ref={tooltipRef}
                    className="custom-tooltip"
                    style={{
                        top: `${tooltipPosition.top}px`,
                        left: `${tooltipPosition.left}px`,
                    }}
                >
                    Available for Purchase
                </div>
            )}
        </div>
    );
};

export default Downloads;
