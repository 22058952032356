import React, { useEffect, useContext, useCallback, useState } from "react";
import ZoomContext from "../context/zoom-context";
import ZoomMediaContext from "../context/zoom-media-context";
import "./screen-share.scss";

const ScreenShare = ({ isStartedShare, setIsStartedShare }) => {
    const { mediaStream } = useContext(ZoomMediaContext);
    const zmClient = useContext(ZoomContext);

    const onActiveShareChange = useCallback(
        ({ state, userId }) => {
            if (state === "Active") {
                mediaStream.startShareView(
                    document.querySelector("#share-view"),
                    userId
                );
            } else if (state === "Inactive") {
                mediaStream.stopShareView();
            }
            setIsStartedShare(state === "Active");
        },
        [mediaStream]
    );

    useEffect(() => {
        zmClient.on("active-share-change", onActiveShareChange);
        return () => {
            zmClient.off("active-share-change", onActiveShareChange);
        };
    }, [zmClient]);

    return (
        <div className={`share-view ${isStartedShare ? "sharing" : ""}`}>
            <canvas id="share-view" width={1920} height={1080}></canvas>
            {/* TODO: Improve UI with a self-screen share view */}
            <div className="self-share-view">
                {mediaStream?.isStartShareScreenWithVideoElement() ? (
                    <video
                        id="self-share-view"
                        height="1080"
                        width="1920"
                    ></video>
                ) : (
                    <canvas
                        id="self-share-view"
                        height="1080"
                        width="1920"
                    ></canvas>
                )}
            </div>
        </div>
    );
};

export default ScreenShare;
