import React, { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import AppointmentContext from "../context/appointment-context";
import { formatDateTime } from "../utils";
import "./summary.scss";

const Summary = ({ platform, itfComplete }) => {
    const appointment = useContext(AppointmentContext);

    const name =
        platform == "vetvantage"
            ? appointment.extendedProps.doctor_name
            : appointment.patient_name;
    const redirect =
        platform == "vetvantage"
            ? !!itfComplete
                ? "/dashboard/"
                : "/itf/unlocked/"
            : "/dashboard/";
    const btnText = platform == "vetvantage" ? "Next" : "Go To Dashboard";
    return (
        <div className="telehealth-summary">
            <div className="card">
                <h2>Post-exam Summary</h2>
                <p>
                    You met with {name} on{" "}
                    {/* TODO: Use a consistent datetime format across all pages/platforms */}
                    {formatDateTime(appointment.start_date)}
                </p>
                <h2>Summary</h2>
                <p>
                    {appointment?.summary ??
                        "Your summary is being processed please check back again later."}
                </p>
                <h2>Full Transcript</h2>
                {appointment?.transcript?.length > 0 ? (
                    <div className="transcript">
                        {appointment?.transcript.map((entry) => (
                            <p className="transcript-entry" key={entry.msgId}>
                                <b>{entry.displayName}:</b> {entry.text}
                            </p>
                        ))}
                    </div>
                ) : (
                    <p>
                        Your transcript is being processed please check back
                        again later.
                    </p>
                )}
            </div>
            <a href={redirect} className="btn-primary btn primary">
                <h6 className="btn-text">{btnText}</h6>
            </a>
        </div>
    );
};

export default Summary;
