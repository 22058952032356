import React from "react";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Stack } from "@mui/material";
import { Typography } from "@mui/material";
import DescriptionOutlinedIcon from "@mui/icons-material/DescriptionOutlined";
import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";

import AdminModal from "./AdminModal";

const AdminCheckPaymentModal = ({ orderDetails }) => {
    const boxStyle = {
        minWidth: 723,
        height: 291,
        position: "absolute",
        top: "50%",
        left: "50%",
        display: "flex",
        flexDirection: "column",
        transform: "translate(-50%, -50%)",
        border: "2px solid #000",
        boxShadow: 24,
        p: 4,
    };
    return (
        <AdminModal>
            <Box sx={boxStyle} component={Paper}>
                <>
                    <Typography
                        id="modal-modal-title"
                        variant="h5"
                        component="h5"
                        fontWeight={600}
                        sx={{ marginBottom: 2, color: "#464646" }}
                    >
                        Products
                    </Typography>
                    <Stack>
                        {orderDetails.order_items.map(
                            ({ product, price, quantity }, index) => {
                                return (
                                    <Stack
                                        key={index}
                                        sx={{
                                            flexGrow: 1,
                                            display: "flex",
                                            flexDirection: "row",
                                            justifyContent: "space-between",
                                            padding: 1,
                                            marginBottom: 1,
                                            borderBottom: "1px solid #464646",
                                        }}
                                    >
                                        <Stack
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginBottom: 1,
                                            }}
                                        >
                                            {product.type == 2 ? (
                                                <DescriptionOutlinedIcon
                                                    sx={{ marginRight: 2 }}
                                                />
                                            ) : (
                                                <AssignmentOutlinedIcon
                                                    sx={{ marginRight: 2 }}
                                                />
                                            )}

                                            <Typography fontWeight={600}>
                                                {product.name}
                                            </Typography>
                                        </Stack>
                                        <Stack
                                            sx={{
                                                display: "flex",
                                                flexDirection: "row",
                                                marginBottom: 1,
                                            }}
                                        >
                                            <Typography fontWeight={600}>
                                                ${parseFloat(price).toFixed(2)}
                                            </Typography>
                                        </Stack>
                                    </Stack>
                                );
                            }
                        )}
                        <Stack
                            sx={{
                                flexGrow: 1,
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                padding: 1,
                            }}
                        >
                            <Typography fontWeight={600}>Total:</Typography>
                            <Typography fontWeight={600}>
                                ${parseFloat(orderDetails.total).toFixed(2)}
                            </Typography>
                        </Stack>
                    </Stack>
                </>
            </Box>
        </AdminModal>
    );
};

export default AdminCheckPaymentModal;
