import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
    getClaimsData,
    saveClaimsProgress,
    completeClaimsForm,
    createClaim,
} from "../../features/claimsSlice";
import MainUnlock from "../../components/MainUnlock";
import { getCookie } from "@/common/utils/cookies";
import SurveyComponent from "../../components/Survey/Survey";
import Alert from "../../../common/components/Alert";
import {
    processSavingSurveyData,
    processSurveyCompletion,
} from "../../utils/survey";
import useAlert from "../../hooks/useAlert";
import "./Claims.scss";
import Spinner from "../../components/Spinner";
import { useNavigate } from "react-router-dom";

const Claims = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const { alertType, alertMessage, alertVisible, showAlert, hideAlert } =
        useAlert();

    const { surveyData, status, error, context, redirect } = useSelector(
        (state) => state.claims
    );

    useEffect(() => {
        hideAlert();
        dispatch(getClaimsData());
    }, [dispatch]);

    useEffect(() => {
        if (redirect) {
            navigate(redirect);
        }
    }, [redirect]);

    // Function to save survey progress
    const onCurrentPageChanging = async (survey) => {
        if (survey.isCompleted) return;
        hideAlert();

        const currDataJSON = processSavingSurveyData(survey);
        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
        formData.append("document_id", surveyData.document_id);
        formData.append("surveyData", currDataJSON);
        dispatch(saveClaimsProgress(formData))
            .then((res) => {
                if (res.error) {
                    showAlert({
                        alertType: "error",
                        alertMessage: "Failed to save survey progress",
                    });
                    survey.currentPageNo = survey.currentPageNo - 1;
                }
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: "Failed to save survey progress",
                });
                survey.currentPageNo = survey.currentPageNo - 1;
            });
    };

    // Function to complete the survey
    const onComplete = async (survey) => {
        const dataJSON = processSurveyCompletion(survey);
        const formData = new FormData();
        formData.append("csrfmiddlewaretoken", getCookie("csrftoken"));
        formData.append("document_id", surveyData.document_id);
        formData.append("resultData", dataJSON);
        dispatch(completeClaimsForm(formData))
            .then(() => {
                dispatch(getClaimsData());
            })
            .catch((error) => {
                showAlert({
                    alertType: "error",
                    alertMessage: "Failed to complete survey",
                });
            });
    };

    const onCurrentPageChanged = (survey, options) => {
        // handle ebenefit validation next button func
        if (
            options.isNextPage &&
            options.oldCurrentPage.name === "EBenefitAccountValidationPage"
        ) {
            // get upload screenshot page
            const screenshotPage = survey.getPageByName("UploadScreenShotPage");
            // navigate back to the upload screenshot page
            survey.currentPage = screenshotPage;
        }
    };

    if (status === "loading" || !surveyData) {
        return <Spinner />;
    }

    if (error && status === "failed") {
        return <Alert type={"error"} message={error} />;
    }

    if (status === "completed") {
        return (
            <MainUnlock
                step_title={"Great News!"}
                step_btn_text={"Next"}
                btn_url={context.next_url}
            >
                <div className="instruction-container">
                    <p className="text-half-width">{context.success_msg}</p>
                </div>
            </MainUnlock>
        );
    }

    if (status == "claims-done" && context) {
        return (
            <MainUnlock
                step_title={"You’ve Already Submitted your claim"}
                step_btn_text={"Go to Dashboard"}
                btn_url={"/dashboard/"}
                // go_back_url={"/benefit-summary/"}
                outlinedBtn
                btnSize="large"
            >
                {context.claim.creation_date && (
                    <p className="itf-done-label">
                        Submitted on {context.claim.creation_date}
                    </p>
                )}
                {context.claim.received_date && (
                    <p className="itf-done-label">
                        Received on {context.claim.received_date}
                    </p>
                )}
            </MainUnlock>
        );
    }

    if (surveyData.status === "unlocked") {
        return (
            <MainUnlock
                step_title={"Submit A Claim (526EZ)"}
                step_btn_text={"Start 526EZ"}
                // hideGoBack
                onClick={() => {
                    dispatch(createClaim()).then(() => {
                        dispatch(getClaimsData());
                    });
                }}
                loading={status === "pendingCreateClaim"}
            >
                <div className="instruction-container">
                    <p className="text-half-width">
                        Submitting your 526EZ form is a crucial step towards
                        securing your well-deserved benefits. Ensure your
                        information is accurate to avoid delays.
                    </p>
                </div>
            </MainUnlock>
        );
    }

    return (
        <div id="claims" className="claims-container">
            {alertVisible && <Alert type={alertType} message={alertMessage} />}
            {surveyData &&
                surveyData.document_id &&
                surveyData.curr_data_json &&
                surveyData.form_data_json &&
                surveyData.all_data_json && (
                    <SurveyComponent
                        currentDataJSON={surveyData.curr_data_json}
                        formDataJSON={surveyData.form_data_json}
                        allData={surveyData.all_data_json}
                        onComplete={onComplete}
                        onCurrentPageChanging={onCurrentPageChanging}
                        onCurrentPageChanged={onCurrentPageChanged}
                    />
                )}
        </div>
    );
};

export default Claims;
