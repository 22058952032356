// TODO: Delete this file when testing is done.
import React from "react";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import { setCookie } from "@/common/utils/cookies";
import FormField from "@/common/components/form/fields/FormField";

const validationSchema = Yup.object({
    email: Yup.string().email("Invalid email address").required("Required"),
    password: Yup.string().required("Required"),
});

const PasswordInput = () => {
    return (
        <div id="summarizationPwd">
            <Formik
                initialValues={{
                    email: "",
                    password: "",
                }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    setCookie("test_email", values.email);
                    setCookie("test_password", values.password);
                    setSubmitting(false);
                    location.replace("/medical-history/summarization-test/");
                }}
            >
                {({ isSubmitting }) => (
                    <Form className="pwd-form">
                        <h5>Please enter your credentials.</h5>
                        <FormField
                            name="email"
                            type="email"
                            placeholder="Email"
                        />
                        <FormField
                            name="password"
                            type="password"
                            placeholder="Password"
                        />
                        <button
                            className="btn primary"
                            type="submit"
                            disabled={isSubmitting}
                        >
                            {isSubmitting ? (
                                <div className="loader"></div>
                            ) : (
                                "Login"
                            )}
                        </button>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default PasswordInput;
