import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { axiosInstance, cachedAxiosInstance } from "@/common/utils/axios";

export const checkIAQ = createAsyncThunk(
    "iaq/checkIAQ",
    async (_, thunkAPI) => {
        try {
            const response = await cachedAxiosInstance.get(
                "/iaqs/api/iaq-survey/"
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;

                if (error.response.status === 302) {
                    return thunkAPI.rejectWithValue(errorData);
                }

                return thunkAPI.rejectWithValue(
                    errorData?.detail || "Failed to get user IAQ."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

export const submitIAQ = createAsyncThunk(
    "iaq/submitIAQ",
    async (surveyData, thunkAPI) => {
        try {
            const response = await axiosInstance.post(
                "/iaqs/api/iaq-survey/",
                {
                    action: surveyData.action,
                    data: surveyData.data,
                    currentPageNo: surveyData.currentPageNo,
                },
                {
                    headers: {
                        "Content-Type": "application/json",
                    },
                    timeout: 180000, // 3 min (for conditions extraction time)
                }
            );
            return await response.data;
        } catch (error) {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const errorData = error.response.data;
                return thunkAPI.rejectWithValue(
                    errorData || "IAQ Submission failed."
                );
            } else if (error.request) {
                // The request was made but no response was received
                return thunkAPI.rejectWithValue(error.message);
            } else {
                return thunkAPI.rejectWithValue(error.message);
            }
        }
    }
);

const iaqSlice = createSlice({
    name: "iaq",
    initialState: {
        status: null,
        currentData: null,
        formData: null,
        error: null,
        loading: true,
        currentPageNo: 0,
    },
    reducers: {
        setFormData: (state, action) => {
            state.currentData = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(checkIAQ.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(checkIAQ.fulfilled, (state, action) => {
                state.status = action.payload.status;
                state.currentData = action.payload.curr_data;
                state.formData = action.payload.form_data;
                state.currentPageNo = action.payload.curr_page_no;
                state.error = null;
                state.loading = false;
            })
            .addCase(checkIAQ.rejected, (state, action) => {
                state.error = action.payload.error;
                state.loading = false;
            })
            .addCase(submitIAQ.pending, (state) => {
                state.error = null;
                state.loading = true;
            })
            .addCase(submitIAQ.fulfilled, (state, action) => {
                state.status = action.payload.status;
                state.currentPageNo = action.payload.curr_page_no;
                state.error = null;
                state.loading = false;
            })
            .addCase(submitIAQ.rejected, (state, action) => {
                state.status = action.payload.status;
                state.error = action.payload.error;
                state.currentPageNo = action.payload.curr_page_no;
                state.loading = false;
            });
    },
});

export const { setFormData } = iaqSlice.actions;
export default iaqSlice.reducer;
