import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./main_unlock.scss";
import arrowLeft from "../images/arrow-left.png";
import SuccessStarLogo from "./SuccessStarLogo";

// export function GoBack({ go_back_url = -1 }) {
//     const navigate = useNavigate();

//     return (
//         <div className="go-back-container lg-btn">
//             <Link
//                 onClick={() => {
//                     navigate(go_back_url);
//                 }}
//                 className="go-back-btn"
//             >
//                 <div className="go-back-arrow-container">
//                     <img src={arrowLeft} alt="Arrow left" />
//                 </div>
//                 <div className="go-back-text-container">
//                     <p className="go-back-text">go back</p>
//                 </div>
//             </Link>
//         </div>
//     );
// }

const MainUnlock = ({
    is_optional = false,
    btn_url,
    step_title,
    message,
    sub_message,
    step_btn_text,
    onDoLater,
    children,
    outlinedBtn = false,
    btnSize = "default",
    contentStyle = {},
    buttonContainerStyle = {},
    // go_back_url = -1,
    // hideGoBack = false,
    onClick = null,
    loading = false,
    href_url = "",
}) => {
    const location = useLocation();
    const currentPath = location.pathname;

    const getBtnSizeClass = (size) => {
        switch (size) {
            case "large":
                return "btn-275";
            case "medium":
                return "btn-md";
            case "small":
                return "btn-small";
            default:
                return "";
        }
    };

    return (
        <div id="main-unlock">
            <div className="content-container" style={contentStyle}>
                <SuccessStarLogo />
                <h3>{step_title}</h3>
                {children}
                <div
                    className="btn-container"
                    style={{
                        ...buttonContainerStyle,
                    }}
                >
                    {currentPath.includes("itf") && is_optional && (
                        <button
                            className={`btn outline ${getBtnSizeClass(
                                btnSize
                            )}`}
                            id="openModalBtn"
                            onClick={onDoLater}
                        >
                            I'll Do It later
                        </button>
                    )}
                    {onClick && (
                        <button
                            disabled={loading}
                            className={`${
                                outlinedBtn ? "btn outline" : "btn primary"
                            } ${getBtnSizeClass(btnSize)}`}
                            onClick={onClick}
                        >
                            {loading ? (
                                <div className="loader"></div>
                            ) : (
                                step_btn_text
                            )}
                        </button>
                    )}
                    {href_url && (
                        <a
                            href={href_url}
                            className={`${
                                outlinedBtn ? "btn outline" : "btn primary"
                            } ${getBtnSizeClass(btnSize)}`}
                        >
                            {step_btn_text}
                        </a>
                    )}
                    {btn_url && (
                        <Link
                            to={btn_url}
                            className={`${
                                outlinedBtn ? "btn outline" : "btn primary"
                            } ${getBtnSizeClass(btnSize)}`}
                        >
                            {step_btn_text}
                        </Link>
                    )}
                </div>
                {message && <p>{message}</p>}
                <br />
                {sub_message && <p>{sub_message}</p>}
            </div>
        </div>
    );
};

export default MainUnlock;
