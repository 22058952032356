import React from "react";
import FormField from "./fields/FormField";

const ProfileForm = ({title, buttonText}) => {
    return (
        <>
            <h3>{title}</h3>
            <div className="form-row">
                <div className="form-group">
                    <FormField
                        label="First Name"
                        name="firstName"
                        type="text"
                    />
                </div>
                <div className="form-group">
                    <FormField
                        label="Last Name"
                        name="lastName"
                        type="text"
                    />
                </div>
            </div>
            <div className="form-row">
                <div className="form-group">
                    <FormField
                        label="Email"
                        name="email"
                        type="email"
                    />
                </div>
                <div className="form-group">
                    <FormField
                        label="Phone Number"
                        name="phoneNumber"
                        type="text"
                        maxLength={10}
                    />
                </div>
            </div>
            <div className="button-row">
                <button
                    type="submit"
                    className="btn primary"
                >
                    {buttonText}
                </button>
            </div>
        </>
    );
};

export default ProfileForm;