import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import NotificationBase from "./NotificationBase";
import Notification from "./Notification";
import {
    fetchNotifications,
    markAllPaginatedNotificationsViewed,
    markAllArchived,
    archiveNotification,
} from "../../features/notificationsSlice";
import Pagination from "@/common/components/Pagination/Pagination";

const NotificationCenter = () => {
    const dispatch = useDispatch();
    const { newNotifications, loading, pagination } =
        useSelector((state) => state.notifications);

    useEffect(() => {
        dispatch(fetchNotifications({ type: "new" }));
    }, [dispatch]);

    const markVisibleNotificationsViewed = (notifications) => {
        const visibleNotificationIds = notifications
            .filter(notification => !notification.viewed && !notification.archived)
            .map(notification => notification.id);
        if (visibleNotificationIds.length > 0) {
            dispatch(markAllPaginatedNotificationsViewed(visibleNotificationIds));
        }
    };

    const handleMarkAllArchived = () => {
        dispatch(markAllArchived());
    };

    const handleArchiveNotification = (notificationId) => {
        dispatch(archiveNotification(notificationId));
    };

    // Trigger markVisibleNotificationsViewed when new notifications are rendered
    useEffect(() => {
        if (newNotifications.length > 0) {
            markVisibleNotificationsViewed(newNotifications);
        }
    }, [newNotifications]);

    if (loading) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }
    return (
        <NotificationBase isArchived={false}>
            <div className="notifications-header">
                <div className="notification-title-container">
                    <h1>New Notifications</h1>
                </div>

                <div className="mark-btn-wrapper flex-end">
                    <button
                        type="button"
                        className={
                            newNotifications.length === 0 
                                ? "disabled"
                                : ""
                        }
                        onClick={handleMarkAllArchived}
                        disabled={
                            newNotifications.length === 0
                        }
                    >
                        <span className="span-el">Mark all archived</span>
                    </button>
                </div>
            </div>

            {newNotifications.length ? (
                newNotifications.map((notification) => (
                    <Notification
                        key={notification.id}
                        notification={notification}
                        archiveNotification={handleArchiveNotification}
                    />
                ))
            ) : (
                <div className="no-notifications">No New Notifications!</div>
            )}

            {newNotifications.length > 0 &&
                pagination?.current_page &&
                pagination?.total_pages &&
                pagination?.page_size && (
                    <Pagination
                        currentPage={pagination?.current_page ?? 1}
                        total_pages={pagination?.total_pages ?? 1}
                        itemsPerPage={pagination?.page_size ?? 5}
                        // change current page
                        onPageChange={(page) => {
                            dispatch(
                                fetchNotifications({
                                    page: page,
                                    page_size: pagination?.page_size ?? 5,
                                    type: "new",
                                })
                            );
                        }}
                        // change page size
                        onItemsPerPageChange={(value) => {
                            dispatch(
                                fetchNotifications({
                                    page_size: value,
                                    type: "new",
                                    page: 1,
                                })
                            );
                        }}
                    />
                )}

        </NotificationBase>
    );
};

export default NotificationCenter;
