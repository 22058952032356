import React from "react";
import Mic from "../icons/mic";
import MicOff from "../icons/mic-off";
import VolumeOff from "../icons/volume-off";
import "./namebadge.scss";

const NameBadge = ({ displayName, audioStarted, muted }) => {
    let icon = null;

    if (!audioStarted) {
        icon = <VolumeOff />;
    } else if (muted) {
        icon = <MicOff />;
    } else if (!muted) {
        icon = <Mic />;
    }

    return (
        <div className="namebadge-wrapper">
            <div className="namebadge">
                {displayName ? (
                    <>
                        <div className="namebadge-icon-wrapper">{icon}</div>
                        <span>{displayName}</span>
                    </>
                ) : (
                    <span>No one present</span>
                )}
            </div>
        </div>
    );
};

export default NameBadge;
