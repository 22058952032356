import React, { useState, useEffect } from "react";
import { Formik, Form } from "formik";
import { Link, useLocation } from "react-router-dom";
import * as Yup from "yup";
import { useSelector, useDispatch } from "react-redux";
import GoBack from "@/common/components/GoBack";
import {
    checkUserExists,
    createUser,
    createPassword,
    clearError,
} from "@/vetvantage/features/signupSlice";
import Alert from "../../common/components/Alert";
import Logo from "../images/logo_white.svg";
import "./SignupForm.scss";
import ProfileForm from "../../common/components/form/ProfileForm";
import PasswordForm from "../../common/components/form/PasswordForm";

const validationSchemaStep1 = Yup.object({
    firstName: Yup.string().required("First Name is required"),
    lastName: Yup.string().required("Last Name is required"),
    phoneNumber: Yup.string()
        .required("Phone Number is required")
        .matches(/^[0-9]{10}$/, "Phone number must be exactly 10 digits"),
        email: Yup.string()
        .required("Email is required")
        .matches(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/i, "Invalid email format"),
});

const validationSchemaStep2 = Yup.object({
    password: Yup.string()
        .min(8, "Password must be at least 8 characters")
        .matches(/[a-z]/, "Password must contain at least one lowercase letter")
        .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
        .matches(/[0-9]/, "Password must contain at least one number")
        .matches(
            /[!@#$%^&*(),.?":{}|<>]/,
            "Password must contain at least one special character"
        )
        .required("Password is required"),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
});

const SignupForm = () => {
    const [step, setStep] = useState(1);
    const dispatch = useDispatch();
    const error = useSelector((state) => state.signup.error);
    // Use useLocation to access the current URL
    const location = useLocation();

    // Parse the query parameters using URLSearchParams
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const [serverError, setServerError] = useState("");

    useEffect(() => {
        if (window.contextData?.errorMessage) {
            setServerError(window.contextData.errorMessage);
        }

        dispatch(clearError());

        // Check if token is present, and move to step 2 if so
        if (token) {
            setStep(2);
        }
    }, [dispatch, token]);

    const handleSubmit = async (values) => {
        if (step === 1) {
            const result = await dispatch(
                checkUserExists({
                    email: values.email,
                    phoneNumber: values.phoneNumber,
                })
            );
            if (!result.error) {
                setStep(2);
            }
        } else if (step === 2) {
            const action = token ? createPassword : createUser;
            const result = await dispatch(action(values));
            if (!result.error) {
                setStep(3);
            }
        }
    };

    

    if (serverError) {
        return (
            <div className="signup-form-container">
                <header className="signup-header">
                    <div className="back-to-landing">
                        <Link to={"/"}>
                            <Logo />
                        </Link>
                    </div>
                </header>
                <div className="signup-form">
                    <h3>Create a Password</h3>
                    <p>
                        The create password link was invalid, possibly because
                        it has already been used. Please request{" "}
                        <Link to={"/accounts/password/reset/"}>
                            a password reset.
                        </Link>
                    </p>
                </div>
                <footer className="signup-footer"></footer>
            </div>
        );
    }

    return (
        <div className="signup-form-container">
            <header className="signup-header">
                <div className="back-to-landing">
                    <Link to={"/"}>
                        <Logo />
                    </Link>
                </div>
                <div className="progress-bar-wrapper">
                    <div className="go-back-wrapper">
                        {step === 2 && !token && (
                            <GoBack clickHandler={() => setStep(1)} />
                        )}
                    </div>
                    <div className="progress-bar-container">
                        <div
                            className="progress-label"
                            style={{
                                left: `${step === 1 ? 5 : step === 2 ? 55 : 100
                                    }%`,
                            }}
                        >
                            Profile - {step === 1 ? 5 : step === 2 ? 55 : 100}%
                        </div>
                        <div className="progress-bar">
                            <div
                                className="progress"
                                style={{
                                    width: `${step === 1 ? 5 : step === 2 ? 55 : 100
                                        }%`,
                                }}
                            ></div>
                        </div>
                    </div>
                </div>
            </header>

            {error && <Alert type="error" message={error} />}

            <Formik
                initialValues={{
                    firstName: "",
                    lastName: "",
                    phoneNumber: "",
                    email: "",
                    password: "",
                    confirmPassword: "",
                    token: token,
                }}
                validationSchema={
                    step === 1 ? validationSchemaStep1 : validationSchemaStep2
                }
                onSubmit={(values, { setSubmitting }) => {
                    handleSubmit(values);
                    setSubmitting(false);
                }}
            >
                <Form className="signup-form">
                    {step === 1 && (
                        <ProfileForm title={"Create Your Profile"} buttonText={"Next"} />
                    )}

                    {step === 2 && (
                        <PasswordForm title={"Create a Password"} buttonText={"Next"} />
                    )}

                    {step === 3 && (
                        <>
                            <h3>Check Your Email</h3>
                            <p>Click the link to verify your account.</p>
                            <div className="button-row">
                                <Link
                                    to={"/accounts/login"}
                                    className="btn primary"
                                >
                                    Return to Login
                                </Link>
                            </div>
                        </>
                    )}
                </Form>
            </Formik>
            <footer className="signup-footer">
                {step === 1 && (
                    <h6>
                        Start for free and pay only for the medical exams. Fees
                        will vary based on your DBQs and other options.
                    </h6>
                )}
            </footer>
        </div>
    );
};

export default SignupForm;
