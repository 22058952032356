import React, { useEffect, useRef } from "react";
import { Model } from "survey-core";
import { Survey, ReactElementFactory } from "survey-react-ui";
import { useParams } from "react-router-dom";
import {
    getPAQSurvey,
    submitPAQSurvey,
    setFormData,
} from "../../features/paqSlice";
import { useSelector, useDispatch } from "react-redux";
import Alert from "../../../common/components/Alert";
import PercentageProgressBar from "../../../common/components/form/PercentageProgressBar";
import { themeJson } from "../../../common/utils/survey_theme";
import {
    processSavingSurveyData,
    processSurveyCompletion,
} from "../../utils/survey";
import MainUnlock from "../../components/MainUnlock";

ReactElementFactory.Instance.registerElement(
    "sv-progressbar-percentage",
    (props) => {
        return React.createElement(PercentageProgressBar, props);
    }
);

const PAQSurvey = () => {
    const dispatch = useDispatch();
    const { disabilityName, isConditionWorse } = useParams();
    const {
        status,
        currentData,
        formData,
        allData,
        error,
        loading,
        currentPageNo,
    } = useSelector((state) => state.paq);

    const isPageInitialized = useRef(false)
    
    useEffect(() => {
        if(surveyModel){
            if (isConditionWorse === "1" && !isPageInitialized.current) {
                surveyModel.currentPageNo = 0;
                isPageInitialized.current = true;
            }
        }
    }, [isConditionWorse, surveyModel, currentPageNo]);

    useEffect(() => {
        dispatch(getPAQSurvey(disabilityName));
    }, [dispatch, disabilityName]);

    const handleSurveySubmit = async (survey, action, currentPageNo = null) => {
        const surveyData = {
            disabilityName: disabilityName,
            data: survey,
            currentPageNo: currentPageNo,
            action: action,
        };
        dispatch(submitPAQSurvey(surveyData));
        dispatch(setFormData(surveyData.data));
    };

    if (!formData || loading || !currentData) {
        return (
            <div className="loading-spinner-container">
                <div
                    style={{
                        height: "80px",
                        width: "80px",
                        borderWidth: "8px",
                    }}
                    className="loader"
                ></div>
            </div>
        );
    }

    if (status === "completed") {
        return (
            <MainUnlock
                step_title={"Great News!"}
                step_btn_text={"Next"}
                btn_url={"/paqs/"}
            >
                <div className="instruction-container">
                    <p className="text-half-width">
                        Congratulations on successfully completing your PAQ™!
                    </p>
                </div>
            </MainUnlock>
        );
    }

    const surveyModel = new Model(formData);
    surveyModel.applyTheme(themeJson);
    surveyModel.css = {
        navigationButton: "btn primary"
    };
    surveyModel.data = JSON.parse(currentData);
    surveyModel.allData = allData;
    surveyModel.addLayoutElement({
        id: "progressbar-percentage",
        component: "sv-progressbar-percentage",
        container: "contentTop",
        data: surveyModel,
    });

    surveyModel.currentPageNo = currentPageNo;

    return (
        <>
            {error && <Alert type="error" message={error} />}

            <div className="page-container-iaq-survey">
                <Survey
                    model={surveyModel}
                    onComplete={(survey) => {
                        handleSurveySubmit(
                            processSurveyCompletion(survey),
                            "complete",
                            survey.currentPageNo
                        );
                    }}
                    onCurrentPageChanged={(survey) => {
                        if (survey.isCompleted) return;
                        handleSurveySubmit(
                            processSavingSurveyData(survey),
                            "proceed",
                            survey.currentPageNo
                        );
                    }}
                />
            </div>
        </>
    );
};

export default PAQSurvey;
