import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Formik, Form } from "formik";
import * as Yup from "yup";

import FormField from "@/common/components/form/fields/FormField";
import { toggleShow } from "../../features/modalSlice";
import Modal from "../Modal";
import CloseModalBtn from "../CloseModalBtn";
import "./EvaluationTypeModal.scss";

const validationSchema = Yup.object().shape({
    select: Yup.string()
        .required("Required")
        .oneOf(["in_person", "telehealth"]),
});

const EvaluationTypeModal = ({ data, setDoctor, handleSelectDoctor }) => {
    const dispatch = useDispatch();

    const [isSelected, setIsSelected] = useState(false);

    const closeModal = (resetForm) => {
        setIsSelected(false);
        resetForm();
        setDoctor(null);
        dispatch(toggleShow());
    };

    const handleChange = (event, setFieldValue) => {
        const value = event.currentTarget.value;
        setIsSelected(value ? true : false);
        setFieldValue("select", value);
    };

    return (
        <Modal
            renderContent={({ ref }) => (
                <div
                    id="EvaluationTypeModal"
                    className="modal-content"
                    ref={ref}
                >
                    <Formik
                        initialValues={{
                            select: "",
                        }}
                        validationSchema={validationSchema}
                        onSubmit={(values, { resetForm }) => {
                            closeModal(resetForm);
                            handleSelectDoctor({
                                ...data,
                                type: values.select,
                            });
                        }}
                    >
                        {({ setFieldValue, resetForm }) => (
                            <Form>
                                <CloseModalBtn
                                    onClose={() => closeModal(resetForm)}
                                />
                                <p>
                                    Select the type of appointment you want to
                                    schedule:
                                </p>
                                <div className="select-wrapper">
                                    <FormField
                                        name="select"
                                        type="select"
                                        onChange={(e) =>
                                            handleChange(e, setFieldValue)
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="in_person">
                                            In-Person
                                        </option>
                                        <option value="telehealth">
                                            Telehealth
                                        </option>
                                    </FormField>
                                </div>
                                <div className="btns-container">
                                    <button
                                        type="button"
                                        className="btn outline"
                                        onClick={() => closeModal(resetForm)}
                                    >
                                        Cancel
                                    </button>
                                    <button
                                        type="submit"
                                        className="btn primary"
                                        disabled={!isSelected}
                                    >
                                        Schedule
                                    </button>
                                </div>
                            </Form>
                        )}
                    </Formik>
                </div>
            )}
        />
    );
};

export default EvaluationTypeModal;
